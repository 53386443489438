import React from "react";
import I18n from "i18n-js";

import { getLocaleTitle } from "../../services/locale";

import RadioGroup from "./RadioGroup";

const LanguageForm = ({ availableLanguages, language, onLanguageSelected }) => {

  const languages = availableLanguages.map(lang => ({
    label: `${getLocaleTitle(lang)} (${lang})`,
    value: lang
  }));

  const handleOnLanguageSelected = (item) => {
    const language = item.value;
    onLanguageSelected && onLanguageSelected(language);
  }

  return (
    <div className="language-form">
      <RadioGroup
        label={I18n.t("settings.pages.language.language")}
        items={languages}
        initialValue={language}
        onItemSelected={handleOnLanguageSelected}
      />
    </div>
  );
}

export default LanguageForm;
