import React, { Component } from "react";
import Moment from "react-moment";
import I18n from "i18n-js";
import _ from "lodash";

import { RolesOrder, NotCoreLogicRoles, FullAccessRoles } from "../../config/roles";

import UserStatus from "./UserStatus";
import UserActions from "./UserActions";

export default class UsersTable extends Component {

  render() {
    const { currentTeam, loggedUser, openUserEdit } = this.props;

    let members = [];
    
    if (currentTeam && currentTeam.users) { 
      members = _.sortBy(currentTeam.users, (user) => {
        const role = user.role_id;
        const inactive = !user.active;
        const onboarded = _.includes(NotCoreLogicRoles, role) || user.onboarded ? 1 : 2;
        const invited = user.invitation_accepted_at === null && user.invitation_sent_at !== null;
        return [RolesOrder[role], onboarded, inactive, invited, user.first_name, user.last_name];
      });
    }

    const loggedUserHasAccess = FullAccessRoles.includes(loggedUser.role_id);

    return (
      <table className="table table-striped user-table">
        <thead className="user-table-head">
          <tr>
            <th className="align-middle pl-5">
              {I18n.t("common.member_name")}
            </th>
            <th className="align-middle">{I18n.t("common.email")}</th>
            <th className="align-middle">{I18n.t("user.role")}</th>
            <th className="align-middle">{I18n.t("user.registration")}</th>
            <th className="align-middle">{I18n.t("user.status")}</th>
            <th className="align-middle">{I18n.t("common.integrations")}</th>
            {loggedUserHasAccess ? (<th></th>) : null}
          </tr>
        </thead>
        <tbody className="user-table-body">
          {members.map((user) => {
            return (
              <tr key={user.uuid}>
                <td className="font-weight-normal align-middle pl-5">
                  {user.first_name} {user.last_name}
                </td>
                <td className="font-weight-normal align-middle">
                  {user.email}
                </td>
                <td className="font-weight-normal align-middle">{user.role}</td>
                <td className="font-weight-normal align-middle">
                  <Moment format="MM/DD/YYYY">{user.registrated_at}</Moment>
                </td>
                <UserStatus user={user} />
                <td className="font-weight-normal align-middle">
                  {user.providers.join(", ")}
                </td>
                <UserActions
                  user={user}
                  currentTeam={currentTeam}
                  loggedUser={loggedUser}
                  openUserEdit={openUserEdit}
                />
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
