import React, { Component, Fragment } from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import I18n from 'i18n-js';

export default class TermsConditions extends Component {
  showModal() {
    const { isOpen, hideTerms } = this.props;

    if (isOpen) {
      return (
        <Modal
          size="lg"
          show={isOpen}
          onHide={hideTerms}
          backdrop="static"
          keyboard={false}
          className="terms-and-conditions-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center">
              {I18n.t('onboarding.wp21_welcome')}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Container>
              <Row className="d-flex flex-column">
                <Col>
                  <p>
                    {I18n.t('onboarding.info_text')}
                  </p>
                </Col>

                <Col>
                  <h5>{I18n.t('onboarding.privacy_policy')}</h5>
                  <p>
                    {I18n.t('onboarding.privacy_text')} &nbsp;
                    <a href="https://heyvicky.com/privacy" target="_blank">
                      {I18n.t('onboarding.click_more')}
                    </a>
                  </p>
                </Col>

                <Col>
                  <h5>{I18n.t('onboarding.terms_of_service')}</h5>
                  <p>
                    {I18n.t('onboarding.agreement_text_one')}
                    <br />
                    {I18n.t('onboarding.agreement_text_two')} &nbsp;
                    <a href="https://heyvicky.com/terms" target="_blank">
                      {I18n.t('onboarding.click_more')}
                    </a>
                  </p>
                </Col>

                <Col>
                  <h5>{I18n.t('onboarding.faq')}</h5>
                  <p>
                    {I18n.t('onboarding.secure_info_one')}
                    <br />
                    {I18n.t('onboarding.secure_info_two')} &nbsp;
                    <a href="https://heyvicky.com/terms" target="_blank">
                      {I18n.t('onboarding.click_more')}
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              className="submit-btn"
              onClick={hideTerms}
            >
              {I18n.t('onboarding.understood')}
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return null;
    }
  }

  render() {
    return <Fragment>{this.showModal()}</Fragment>;
  }
}
