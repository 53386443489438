import React, { useEffect, useRef } from "react";

const ProgressBar = ({ value, larger }) => {

  const formattedValue = Math.min(value, 100);

  const progressRef = useRef();

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.width = formattedValue + "%";
      progressRef.current.style.borderRadius = "50px";
    }
  }, [formattedValue]);

  return (
    <div className={`progress-bar ${larger ? "larger" : ""}`} title={`${formattedValue}%`}>
      <div ref={progressRef} className="progress" />
    </div>
  );
}

export default ProgressBar;
