import React from "react";

import backIcon from "../../assets/icons/back.svg";

const PageTitle = ({ title, showBack }) => {

  const returnToPreviousPage = () => {
    window.history.back();
  }

  return (
    <div className="page-title">
      {showBack ? (
        <button className="back-btn" onClick={returnToPreviousPage}>
          <img src={backIcon} />
        </button>
      ) : null}
      <span className="title">{title}</span>
    </div>
  );
}

export default PageTitle;
