import React, { Fragment, useState } from "react";
import I18n from "i18n-js";

import Avatar from "../../../commons/Avatar";
import ActionButton from "../../../commons/ActionButton";

import NoteDeleteConfirmation from "./NoteDeleteConfirmation";
import ShareModal from "./ShareModal";

import saveIcon from "../../../assets/icons/edit-confirm.svg";
import shareIcon from "../../../assets/icons/share.svg";
import deleteIcon from "../../../assets/icons/delete.svg";

const Header = ({
  note,
  showSave,
  canSave,
  loading,
  onTitleChange,
  onSaveClick,
  onDeleteClick
}) => {
  const { title: initialTitle, vicky } = note || { title: "" };

  const [title, setTitle] = useState(initialTitle);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [disableDeleteConfirmation, setDisableDeleteConfirmation] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const handleOnTitleChange = (event) => {
    event.preventDefault();
    
    const value = event.target.value;

    setTitle(value);
    onTitleChange && onTitleChange(value);
  }

  const handleOnSaveClick = () => {
    onSaveClick && onSaveClick();
  }

  const handleOnDeleteClick = () => {
    setShowDeleteConfirmation(true);
  }

  const handleOnShareClick = () => {
    setShowShareModal(true);
  }

  const handleDeleteConfirmationCancel = () => {
    setShowDeleteConfirmation(false);
  }

  const handleDeleteConfirmationConfirm = () => {
    setDisableDeleteConfirmation(true);
    onDeleteClick && onDeleteClick();
  }

  const handleShareModalCancel = () => {
    setShowShareModal(false);
  }

  const renderButtons = () => (
    <div className="buttons">
      {showSave ? (
        <ActionButton
          title={I18n.t("notebook.actions.save")}
          icon={saveIcon}
          loading={loading}
          disabled={!canSave}
          onClick={handleOnSaveClick}
        />
      ) : note ? (
        <Fragment>
          <ActionButton
            title={I18n.t("notebook.actions.share")}
            icon={shareIcon}
            onClick={handleOnShareClick}
          />
          <ActionButton
            title={I18n.t("notebook.actions.delete")}
            icon={deleteIcon}
            onClick={handleOnDeleteClick}
          />
        </Fragment>
      ) : null}
    </div>
  );

  return (
    <Fragment>
      <div className="editor-header">
        {vicky ? (
          <Avatar size="mini" />
        ) : null}
        <input
          type="text"
          placeholder={I18n.t("notebook.inputs.title.placeholder")}
          value={title}
          readOnly={Boolean(vicky)}
          onChange={handleOnTitleChange}
        />
        {renderButtons()}
      </div>
      <NoteDeleteConfirmation
        show={showDeleteConfirmation}
        disabled={disableDeleteConfirmation}
        onConfirm={handleDeleteConfirmationConfirm}
        onCancel={handleDeleteConfirmationCancel}
      />
      <ShareModal
        note={note}
        show={showShareModal}
        onShared={handleShareModalCancel}
        onCancel={handleShareModalCancel}
      />
    </Fragment>
  );
}

export default Header;
