import React from "react";

import CommonSlider from "../../commons/Slider";

const Slider = ({ title, minValueLabel, maxValueLabel, ...props }) => (
  <div className="slider-answer">
    <div className="answer-title">{title}</div>
    <div className="slider-section">
      <p>{minValueLabel || ""}</p>
      <CommonSlider minValue={1} {...props} />
      <p>{maxValueLabel || ""}</p>
    </div>
  </div>
);

export default Slider;