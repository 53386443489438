import React, { forwardRef } from "react";
import _ from "lodash";

const Textarea = forwardRef(({
  className,
  inputRef,
  id,
  label,
  placeholder,
  value,
  slim,
  error,
  disabled,
  rows,
  onChange,
  ...props
}, ref) => {

  const finalId = id || _.uniqueId();
  const finalValue = value || "";

  const handleChange = (event) => {
    if (disabled) return;
    event.preventDefault();
    onChange && onChange(event);
  }

  return (
    <div ref={ref} className={`textarea ${className}`}>
      {label ? <label htmlFor={finalId}>{label}</label> : null}
      <div className={`internal-textarea ${disabled ? "disabled" : ""} ${error ? "input-error" : ""}`}>
        <textarea
          ref={inputRef}
          id={finalId}
          value={finalValue}
          placeholder={placeholder}
          rows={rows || "1"}
          disabled={disabled}
          onChange={handleChange}
          {...props}
        />
      </div>
      {!slim ? (
        <div className="error">
          {error}
        </div>
      ) : null}
    </div>
  );
});

export default Textarea;
