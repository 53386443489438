import React from "react";

import ProgressBar from "../../../commons/ProgressBar";

const Objective = ({ objective, selected, onClick }) => {

  const totalTasksCount = objective?.tasks?.length;
  const completedTasksCount = objective?.tasks?.filter(t => t.status === "completed")?.length;
  const progress = (completedTasksCount / totalTasksCount * 100).toFixed(2);

  const handleClick = (event) => {
    event.preventDefault();
    onClick && onClick(objective);
  }

  return (
    <div className={`summary-objective ${selected ? "selected" : ""}`} onClick={handleClick}>
      <span className="title">{`${objective.index}. ${objective.title}`}</span>
      <div className="tasks-progress">
        <ProgressBar value={progress} />
        <span className="indicator">
          {`${completedTasksCount} of ${totalTasksCount} tasks completed`}
        </span>
      </div>
    </div>
  );
}

export default Objective;
