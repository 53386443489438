import React from "react";
import I18n from "i18n-js";

import plusIcon from "../../assets/icons/plus.svg";

const NewNoteButton = () => (
  <a className="new-note-btn" href={`/notebook/new?locale=${I18n.locale}`}>
    <span className="text">
      {I18n.t("notebook.actions.new_note")}
    </span>
    <img
      className="icon"
      src={plusIcon}
      alt={I18n.t("notebook.actions.new_note")}
    />
  </a>
);

export default NewNoteButton;
