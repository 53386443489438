import React from "react";
import { ReactSVG } from "react-svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const IconButton = ({ className, icon, title, tooltipPlacement, disabled, onClick }) => {
  const placement = tooltipPlacement || "left";

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id="button-tooltip">{title}</Tooltip>
      }
    >
      <button
        className={`${className} icon-button`}
        disabled={disabled}
        onClick={onClick}
      >
        <ReactSVG className="icon" src={icon} />
      </button>
    </OverlayTrigger>
  );
}

export default IconButton;
