import React from "react";
import I18n from "i18n-js";

import HealthSection from "./HealthSection";

import selfReportedMetricsIcon from "../../assets/icons/self-reported-team-metrics.svg";

import confidenceLevelIcon from "../../assets/images/onboarding/confidence-level.svg";
import resourcesIcon from "../../assets/images/onboarding/resources.svg";
import clarityOfGoalsIcon from "../../assets/images/onboarding/clarity-of-goals.svg";

const getKPIs = (team, confidenceLevel, resources, clarityOfGoals) => [
  {
    title: I18n.t("dashboard.confidence_level"),
    score: confidenceLevel,
    icon: confidenceLevelIcon,
    updateLink: team ? `/confidence_levels/confirm_answer?team_uuid=${team.uuid}` : null
  },
  {
    title: I18n.t("dashboard.resources"),
    score: resources,
    icon: resourcesIcon,
    updateLink: team ? `/resources/confirm_answer?team_uuid=${team.uuid}` : null
  },
  {
    title: I18n.t("dashboard.clarity_of_goals"),
    score: clarityOfGoals,
    icon: clarityOfGoalsIcon,
    updateLink: team ? `/clarity_goals_roles/confirm_answer?team_uuid=${team.uuid}` : null
  }
];

const SelfReportedTeamMetrics = ({ team, confidenceLevel, resources, clarityOfGoals, ofuscate }) => {
  return (
    <div className="self-reported-team-metrics-section">
      <HealthSection
        icon={selfReportedMetricsIcon}
        title={I18n.t("dashboard.self_reported_team_metrics")}
        kpis={getKPIs(team, confidenceLevel, resources, clarityOfGoals)}
        ofuscate={ofuscate}
      />
    </div>
  );
}

export default SelfReportedTeamMetrics;
