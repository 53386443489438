import React, { useState } from "react";
import toast from "react-hot-toast";
import Progress from "react-svg-progress";

import api from "../../api";

import FormItem from "./FormItem";

import editIcon from "../../assets/icons/edit-unaccented.svg";
import confirmIcon from "../../assets/icons/edit-confirm.svg";

const PersonalInfoForm = ({ user }) => {

  const [actionMode, setActionMode] = useState("default");
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);

  const inEditMode = actionMode === "editting";

  const updateUserInfo = () => {
    setActionMode("loading");

    const data = { first_name: firstName, last_name: lastName };

    api.put(`/users/${user.uuid}.json`, data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
          setActionMode("default");
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setActionMode("editting");
        console.error(err);
      });
  }

  const handleEnterEditMode = () => {
    setActionMode("editting");
  }

  const handleConfirmEdit = () => {
    updateUserInfo();
  }

  const renderActionIcon = () => (
    <div className="icon-container">
      {actionMode === "default" ? (
        <img
          className="icon"
          src={editIcon}
          alt="edit icon"
          onClick={handleEnterEditMode}
        />
      ) : null}

      {actionMode === "editting" ? (
        <img
          className="icon"
          src={confirmIcon}
          alt="confirm icon"
          onClick={handleConfirmEdit}
        />
      ) : null}

      {actionMode === "loading" ? (
        <Progress color="#2E6159" size="16px" />
      ) : null}
    </div>
  );

  return (
    <div className="personal-info-form">
      <FormItem
        label={I18n.t("settings.pages.personal_info.first_name")}
        value={firstName}
        editMode={inEditMode}
        editable
        onChange={(value) => setFirstName(value)}
      />
      <FormItem
        label={I18n.t("settings.pages.personal_info.last_name")}
        value={lastName}
        editMode={inEditMode}
        editable
        onChange={(value) => setLastName(value)}
      />
      <FormItem
        label={I18n.t("settings.pages.personal_info.email")}
        value={user.email}
      />
      {renderActionIcon()}
    </div>
  );
}

export default PersonalInfoForm;
