import React, { useEffect, useRef, useState } from "react";

import Item from "./Item";

const ItemList = ({ title, items, expandable, loading, disabled, onItemSelected }) => {

  const [selected, setSelected] = useState(null);

  const ref = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.classList.add("show");
      }
    }, 15);
  }, [ref]);

  const handleItemSelected = (item) => {
    setSelected(item);
    onItemSelected && onItemSelected(item);
  }

  return (
    <div ref={ref} className="item-list">
      <span className="title">{title}</span>
      <div className="items">
        {loading ? (
          [...Array(5).keys()].map((_, index) => (
            <Item key={index} loading />
          ))
        ) : (
          items.map((item, index) => (
            <Item
              key={index}
              item={item}
              selected={selected === item}
              expandable={expandable}
              disabled={disabled}
              onSelected={handleItemSelected}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ItemList;
