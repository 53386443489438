import React, { useRef } from "react";
import { ReactSVG } from "react-svg";
import moment from "moment";
import _ from "lodash";

import calendarIcon from "../assets/icons/calendar.svg";
import { forwardRef } from "react";

const DateInput = forwardRef(({
  className, 
  inputRef,
  id,
  label,
  placeholder,
  value,
  slim,
  error,
  disabled,
  icon,
  onChange,
  ...props
}, ref) => {
  const finalId = id || _.uniqueId();
  const finalValue = value || "";
  const finalIcon = icon || calendarIcon;

  const dateRef = useRef();

  const handleClick = (event) => {
    event.preventDefault();
    if (dateRef.current) {
      dateRef.current.showPicker();
    }
  }

  const handleChange = (event) => {
    if (disabled) return;
    event.preventDefault();
    onChange && onChange(event);
  }

  const getFormattedDate = () => {
    if (finalValue === "") return finalValue;
    return moment(finalValue, "YYYY-MM-DD").format("L");
  }

  return (
    <div ref={ref} className={`${className} date-input`}>
      {label ? <label htmlFor={finalId}>{label}</label> : null}
      <div
        className={`internal-input ${disabled ? "disabled" : ""} ${error ? "input-error" : ""}`}
        onClick={handleClick}
      >
        <input
          {...props}
          ref={el => {
            inputRef && inputRef(el);
            dateRef.current = el;
          }}
          type="date"
          value={finalValue}
          disabled={disabled}
          onChange={handleChange}
        />
        <input
          type="text"
          id={finalId}
          placeholder={placeholder}
          value={getFormattedDate()}
          disabled={disabled}
        />
        <ReactSVG className="icon" src={finalIcon} />
      </div>
      {!slim ? (
        <div className="error">
          {error}
        </div>
      ) : null}
    </div>
  );
});

export default DateInput;
