import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactSVG } from "react-svg";

import closeIcon from "../../../assets/icons/pdp/remove.svg"

const RemoveButton = ({ show, onClick }) => {

  const handleClick = (event) => {
    if (!show) return;
    event.preventDefault();
    onClick && onClick();
  }

  return (
    <OverlayTrigger
      show={show ? undefined : false}
      placement="left"
      overlay={
        <Tooltip id="tooltip-remove">
          Remove
        </Tooltip>
      }
    >
      <button className={`remove-button ${show ? "show" : ""}`} onClick={handleClick}>
        <ReactSVG className="icon" src={closeIcon} />
      </button>
    </OverlayTrigger>
  );
}

export default RemoveButton;
