import React, { useState } from "react";
import I18n from "i18n-js";

import UserAdd from "../../Users/components/UserAdd";

import addIcon from "../../assets/icons/add.svg";

const AddMembersButton = ({ loggedUser, team }) => {
  const canAddNewMembers = team.team_members_count < team.max_members;

  const [showModal, setShowModal] = useState(false);

  const openModal = (event) => {
    event.preventDefault();

    if (canAddNewMembers) {
      setShowModal(true);
      return;
    }

    window.location.assign(`/plans?plan=larger-teams&locale=${I18n.locale}`)
  };

  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  return (
    <>
      <button className="add-members-button" onClick={openModal}>
        <span className="text">
          {I18n.t("dashboard.actions.add_members")}
        </span>
        <img
          className="icon"
          src={addIcon}
          alt={I18n.t("dashboard.actions.add_members")}
        />
      </button>
      <UserAdd
        isModalOpen={showModal}
        hideModal={closeModal}
        currentTeam={team}
        loggedUser={loggedUser}
      />
    </>
  );
}

export default AddMembersButton;
