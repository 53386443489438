import React, { forwardRef } from "react";
import { ReactSVG } from "react-svg";
import _ from "lodash";

const Input = forwardRef(({ className, inputRef, id, label, value, slim, error, disabled, icon, onChange, ...props }, ref) => {
  const finalId = id || _.uniqueId();
  const finalValue = value || "";

  const handleChange = (event) => {
    if (disabled) return;
    event.preventDefault();
    onChange && onChange(event);
  }

  return (
    <div ref={ref} className={`${className} input`}>
      {label ? <label htmlFor={finalId}>{label}</label> : null}
      <div className={`internal-input ${disabled ? "disabled" : ""} ${error ? "input-error" : ""}`}>
        <input
          {...props}
          ref={inputRef}
          id={finalId}
          value={finalValue}
          disabled={disabled}
          onChange={handleChange}
        />
        {icon ? <ReactSVG className="icon" src={icon} /> : null}
      </div>
      {!slim ? (
        <div className="error">
          {error}
        </div>
      ) : null}
    </div>
  );
});

export default Input;
