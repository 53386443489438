import React, { useEffect, useRef, useState } from "react";
import I18n from "i18n-js";

import Sugestions from "./Sugestions";

import Textarea from "./components/Textarea";

import sendIcon from "../assets/icons/send.svg";

const MessageBar = ({ loading, expanded, onMessageSent, onSugestionClick }) => {
  
  const [message, setMessage] = useState("");

  const inputRef = useRef();
  const [inputHeight, setInputHeight] = useState(0);

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.offsetHeight);
    }
  }, [inputRef]);

  const resetInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = inputHeight + "px";
    }
  }

  const handleMessageSent = () => {
    if (message.trim() !== "") {
      onMessageSent && onMessageSent(message);
      handleMessageClear();
    }
  }

  const handelSugestionClick = (sugestion) => {
    onSugestionClick && onSugestionClick(sugestion);
    handleMessageClear();
  }

  const handleMessageChange = (value) => {
    setMessage(value);
  }

  const handleMessageClear = () => {
    setMessage("");
    resetInputHeight();
  }

  return (
    <div className="message-bar">
      <div className="message-bar-inner">
        <div className="message-bar-padding">
          <div className="message-bar-input">
            <Textarea
              ref={inputRef}
              transparent
              rows="1"
              value={message}
              placeholder={I18n.t("chat.user_input")}
              disabled={loading}
              onChange={handleMessageChange}
              onSubmit={handleMessageSent}
            />
            <div className="actions">
              <img
                className="icon"
                src={sendIcon}
                alt="Send message"
                onClick={handleMessageSent}
              />
            </div>
          </div>
        </div>
        {expanded ? null : <Sugestions loading={loading} onSugestionClick={handelSugestionClick} />}
        <div className="disclaimer">
          <span>{I18n.t("chat.disclaimer")}</span>
          <span>{I18n.t("chat.powered_by")}</span>
        </div>
      </div>
    </div>
  );
}

export default MessageBar;