import React from "react";
import I18n from "i18n-js";

import lockIcon from "../assets/icons/lock.svg";

const InlineAlert = ({ fill }) => {
  const className = fill || false ? "inline-alert fill-space" : "inline-alert";
  return (
    <a className={className} href={`/plans?locale=${I18n.locale}`}>
      <img className="icon" src={lockIcon} alt="Upgrade account" />
      <div dangerouslySetInnerHTML={{
        __html: I18n.t("common.upgrade_account")
      }} />
    </a>
  );
}

export default InlineAlert;