import api from "../../api";

export function submitData(data) {
  const { answers, team } = data;

  const kind = team === null ? "personal" : "team";

  api.post("/onboardings", { answers, kind, team: team ? team.uuid : null })
    .then(res => {
      if (res.data.status !== "success") {
        return;
      }

      window.location.assign(res.data.redirectTo);
    })
    .catch(error => {
      console.log(error);
    });
}
