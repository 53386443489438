import React, { useEffect, useState, useRef } from "react";
import I18n from "i18n-js";

import api from "../api";

import useStore from "../store";

import Loading from "./components/Loading";
import ScrollView from "./components/ScrollView";

import MessageHeader from "./MessageHeader";
import MessageContainer from "./MessageContainer";
import MessageBar from "./MessageBar";

const ChatMessage = ({ user, team, expand, onChatExpandClick }) => {
  const { uuid: teamId } = team || {};

  const { previousRoute } = useStore(state => state.routing);

  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const requestCounter = useRef(0);

  useEffect(() => {
    async function fetchMessages() {
      setLoading(true);
      let messages = await getMessagesFromApi();

      if (messages.length === 0) {
        messages = [
          {
            content: I18n.t("chat.initial_message"),
            datetime: new Date().getTime(),
            role: "assistant"
          }
        ];
      }

      setMessages(messages);

      if (!_.includes(["/notebook", "/writer"], previousRoute)) {
        const msgs = await getWelcomeMessages();
        setMessages(messages => [...messages, ...msgs]);
      }

      setLoading(false);
    }

    fetchMessages();
  }, []);

  const getMessagesFromApi = async () => {
    const res = await api.get(`/chat?team_uuid=${teamId}`);

    if (res.data && res.data.status === "success") {
      const messages = res.data.data.map(message => ({
        id: message.id,
        content: message.content,
        datetime: Date.parse(message.created_at),
        role: message.role,
        user: message.role === "user" ? {
          name: user.full_name
        } : null
      }));

      return messages;
    }
  }

  const getWelcomeMessages = async () => {
    try {
      requestCounter.current += 1;
      const res = await api.get(`/chat/welcome?team_uuid=${teamId}`);

      if (res.data && res.data.status === "success" && res.data.answers) {
        const answers = res.data.answers.map(answer => ({
          id: answer.id,
          content: answer.content,
          datetime: Date.parse(answer.created_at),
          role: "assistant"
        }));

        requestCounter.current = 0;
        setLoading(false);
        return answers;
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        const { status, ...rest } = error.response.data;

        if (rest.code && rest.code == "scheduled_thread_recreation" || rest.code == "some_run_is_active_in_thread") {
          if (requestCounter.current < 5) {
            return new Promise(resolve =>
              setTimeout(async () => {
                const result = await getWelcomeMessages();
                resolve(result);
              }, 10000)
            );
          } else {
            setError({ message: I18n.t("common.errors.unexpected_error") });
            setLoading(false);
          }
        } else {
          setError(rest);
        }
      }

      setLoading(false);
    }

    return [];
  }

  const sendMessageToApi = async (message) => {
    try {
      requestCounter.current += 1;
      const res = await api.post("/chat", { team_uuid: teamId, locale: I18n.locale, ...message });

      if (res.data && res.data.status === "success" && res.data.answers) {
        const answers = res.data.answers.map(answer => ({
          id: answer.id,
          content: answer.content,
          datetime: new Date().getTime(),
          role: "assistant"
        }));
        setMessages(messages => [...messages, ...answers]);
        setLoading(false);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        const { status, ...rest } = error.response.data;

        if (rest.code && rest.code == "scheduled_thread_recreation" || rest.code == "some_run_is_active_in_thread") {
          if (requestCounter.current < 5) {
            return new Promise(resolve =>
              setTimeout(async () => {
                const result = await sendMessageToApi(message);
                resolve(result);
              }, 10000)
            );
          } else {
            setError({ message: I18n.t("common.errors.unexpected_error") });
            setLoading(false);
          }
        } else {
          setError(rest);
        }
      }

      setLoading(false);
    }
  }

  const onMessageSent = async (content) => {
    setLoading(true);
    setError(null);
    setMessages(messages => [
      ...messages,
      {
        content,
        datetime: new Date().getTime(),
        role: "user",
        user: {
          name: user.full_name
        }
      }
    ]);
    requestCounter.current = 0;
    await sendMessageToApi({ question: content });
  }

  const onSugestionClick = async (sugestion) => {
    setLoading(true);
    setError(null);
    setMessages(messages => [
      ...messages,
      {
        content: sugestion.command,
        datetime: new Date().getTime(),
        role: "user",
        user: {
          name: user.full_name
        }
      }
    ]);
    requestCounter.current = 0;
    await sendMessageToApi({ sugestion });
  }

  const handleChatClearClick = () => {
    setLoading(true);
    setError(null);
    setMessages([]);
    requestCounter.current = 0;

    api.delete(`/chat?team_uuid=${teamId}`)
      .then(_ => {
        setTimeout(() => {
          setMessages([
            {
              content: I18n.t("chat.initial_message"),
              datetime: new Date().getTime(),
              role: "assistant"
            }
          ]);
          setLoading(false);
        }, 500);
      })
      .catch(error => {
        if (error && error.response && error.response.data && error.response.data.message) {
          setError({ message: error.response.data.message });
        } else {
          setError({ message: I18n.t("common.errors.unexpected_error") });
        }
        setLoading(false);
      });
  }

  const handleChatExpandClick = () => {
    setExpanded(!expanded);
    onChatExpandClick && onChatExpandClick();
  }

  return (
    <div className="chat chat-message">
      <MessageHeader
        expand={expand}
        onClearClick={handleChatClearClick}
        onChatExpandClick={handleChatExpandClick}
      />
      <ScrollView messages={messages} error={error} loading={loading}>
        <MessageContainer messages={messages} error={error} />
        {loading ? <Loading /> : null}
      </ScrollView>
      <MessageBar
        loading={loading}
        expanded={expanded}
        onMessageSent={onMessageSent}
        onSugestionClick={onSugestionClick}
      />
    </div>
  );
}

export default ChatMessage;