import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import I18n from 'i18n-js';

import GoodIcon from "../assets/images/dashboard/good.svg";

export default class AllSet extends Component {

  redirectToHomePage = () => {
    const { team } = this.props;
    window.location = `/?team_uuid=${team.uuid}`;
  }

  render() {
    return (
      <div className="allset-section d-flex flex-column align-items-center">
        <img
          className="allset-icon"
          src={GoodIcon}
          alt="all set icon"
        />
        <h2 className="allset-text pt-3">{I18n.t('common.all_set')}</h2>
        <div className="dashboard-btn pt-4">
          <Button
            className="submit-btn dashboard-btn"
            onClick={() => this.redirectToHomePage()}>
            {I18n.t('common.go_dashboard')}</Button>
        </div>
      </div>
    );
  }
}

