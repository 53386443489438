import React, { useEffect, useState } from "react";

import RadioButton from "./RadioButton";

const RadioGroup = ({ label, items, initialValue, error, slim, onItemSelected }) => {

  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleOnItemSelected = (item) => {
    setSelectedValue(item.value);
    onItemSelected && onItemSelected(item); 
  }

  return (
    <div className="radio-group">
      {label ? <label>{label}</label> : null}
      {items.map((item, index) => (
        <RadioButton
          key={index}
          label={item.label}
          value={item.value}
          checked={selectedValue === item.value}
          onChange={() => handleOnItemSelected(item)}
        >
          {item.view}
        </RadioButton>
      ))}
      {!slim ? (
        <div className="error">
          {error}
        </div>
      ) : null}
    </div>
  );
}

export default RadioGroup;
