import React from "react";
import { Button, Alert, Collapse } from "react-bootstrap";
import I18n from "i18n-js";

import InternalModal from "../../commons/InternalModal";

const ResetStateConfirmation = ({ show, disabled, onCancel, onConfirm }) => {

  const renderConfirmation = () => {
    return (
      <Alert
        show
        variant="danger"
        transition={Collapse}
        className="mb-0"
      >
        <Alert.Heading>
          {I18n.t("writer.confirmations.reset_state.title")}
        </Alert.Heading>
        <br/>
        <p>{I18n.t("writer.confirmations.reset_state.content")}</p>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-secondary"
            disabled={disabled}
            onClick={() => onCancel && onCancel()}
          >
            {I18n.t("common.cancel")}
          </Button>
          <Button
            variant="outline-danger"
            disabled={disabled}
            onClick={() => onConfirm && onConfirm()}
          >
            {I18n.t("common.continue")}
          </Button>
        </div>
      </Alert>
    );
  }

  return (
    <InternalModal
      type="reset-state-confirmation"
      size="md"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderConfirmation()}
      showModalBody={false}
    />
  );
}

export default ResetStateConfirmation;
