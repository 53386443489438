import React, { Component } from "react";
import I18n from "i18n-js";

import useRoutingState from "../hooks/useRoutingState";

import BackArrowIcon from "../assets/images/arrow_forward_24px.svg";

const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY
const STRIPE_EN_TABLE_ID = process.env.STRIPE_EN_TABLE_ID
const STRIPE_PT_BR_TABLE_ID = process.env.STRIPE_PT_BR_TABLE_ID

const getPricingTableId = () => {
  switch (I18n.locale) {
    case "pt-BR":
      return STRIPE_PT_BR_TABLE_ID;
    default:
      return STRIPE_EN_TABLE_ID;
  }
}

const Plans = ({ user }) => {
  useRoutingState();

  const returnToPreviousPage = () => {
    window.history.back();
  }

  return (
    <div className="plans-page">
      <div className="back-btn-row">
        <div className="content">
          <button className="back-btn" onClick={returnToPreviousPage}>
            <img className="icon" src={BackArrowIcon} />
            <span className="text">{I18n.t("common.actions.back")}</span>
          </button>
        </div>
      </div>
      <stripe-pricing-table
        pricing-table-id={getPricingTableId()}
        publishable-key={STRIPE_PUBLIC_KEY}
        client-reference-id={user.uuid}
        customer-email={user.email}
      >
      </stripe-pricing-table>
      <div className="disclaimer">
        *
        <div className="content" dangerouslySetInnerHTML={{
          __html: I18n.t("plans.team_coach_pro.disclaimer")
        }} />
      </div>
    </div>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class PlansComponent extends Component {
  render() {
    return <Plans {...this.props} />;
  }
}
