import React, { Component } from "react";
import { Chip as MaterialChip } from "@material-ui/core";

export default class Chip extends Component {

  onClick = () => {
    const { onClick } = this.props;
    onClick && onClick();
  }

  render() {
    const { label, selected, clickable, style } = this.props;
    const variant = selected ? "default" : "outlined";
    return (
      <MaterialChip
        label={label}
        variant={variant}
        clickable={clickable && !selected}
        onClick={selected ? null : this.onClick}
        style={style}
      />
    );
  }
}