import CollaborationIcon from "../assets/images/onboarding/collaboration.svg";
import CommunicationIcon from "../assets/images/onboarding/communication.svg";
import EngagementIcon from "../assets/images/onboarding/engagement.svg";
import AdaptabilityIcon from "../assets/images/onboarding/adaptability.svg";
import ConfidenceIcon from "../assets/images/onboarding/confidence-level.svg";
import ResourcesIcon from "../assets/images/onboarding/resources.svg";
import ClarityIcon from "../assets/images/onboarding/clarity-of-goals.svg";
import OverallHealthIcon from "../assets/images/overall-health.svg";

import TrustIcon from "../assets/images/trust-environment.svg";
import ManagementIcon from "../assets/images/management-practice.svg";

import NeedWorksIcon from "../assets/images/dashboard/needs-work.svg";
import FairIcon from "../assets/images/dashboard/fair.svg";
import GoodIcon from "../assets/images/dashboard/good.svg";
import ExcellentIcon from "../assets/images/dashboard/excellent.svg";

import I18n from "i18n-js";

export const displayIcon = (iconTitle) => {
  const Icons = {
    collaboration: CollaborationIcon,
    communication: CommunicationIcon,
    engagement: EngagementIcon,
    adaptability: AdaptabilityIcon,
    confidenceLevel: ConfidenceIcon,
    resources: ResourcesIcon,
    clarity_of_goals: ClarityIcon,
    trust_environment: TrustIcon,
    management_practice: ManagementIcon,
    overall_health: OverallHealthIcon,
  };

  return {
    icon: Icons[iconTitle],
  };
};

export const assignCardVariables = (title, score, iconTitle = null) => {
  let icon = null;

  if (iconTitle) {
    icon = displayIcon(iconTitle).icon;
  }

  const poor = {
    cssColor: "#D22F3A",
    message: `${I18n.t("dashboard.poor_msg")}`,
    status: title,
    icon: icon,
  };

  const fair = {
    cssColor: "#FABF38",
    message: `${I18n.t("dashboard.fair_msg")}`,
    status: title,
    icon: icon,
  };

  const good = {
    cssColor: "#FABF38",
    message: `${I18n.t("dashboard.good_msg")}`,
    status: title,
    icon: icon,
  };

  const excellent = {
    cssColor: "#78C0B6",
    message: `${I18n.t("dashboard.excellent_msg")}`,
    status: title,
    icon: icon,
  };

  const overallHealthPoor = {
    cssColor: "#D22F3A",
    status: `${I18n.t("dashboard.needs_work")}`,
    message: `${I18n.t("dashboard.health_needs_work_msg")}`,
    icon: NeedWorksIcon,
  };

  const overallHealthFair = {
    cssColor: "#FABF38",
    status: `${I18n.t("dashboard.fair")}`,
    message: `${I18n.t("dashboard.health_fair_msg")}`,
    icon: FairIcon,
  };

  const overallHealthGood = {
    cssColor: "#409EC3",
    status: `${I18n.t("dashboard.good")}`,
    message: `${I18n.t("dashboard.health_good_msg")}`,
    icon: GoodIcon,
  };

  const overallHealthExcellent = {
    cssColor: "#78C0B6",
    status: `${I18n.t("dashboard.excellent")}`,
    message: `${I18n.t("dashboard.health_excellent_msg")}`,
    icon: ExcellentIcon,
  };

  const isOverall = title == "Overall Health";

  if (score < 0.41) {
    return isOverall ? overallHealthPoor : poor;
  } else if (score >= 0.41 && score < 0.61) {
    return isOverall ? overallHealthFair : fair;
  } else if (score >= 0.61 && score < 0.75) {
    return isOverall ? overallHealthGood : good;
  } else if (score >= 0.75) {
    return isOverall ? overallHealthExcellent : excellent;
  }
};

export const showScore = (title, score) => {
  if (score <= 0) {
    return {
      finalScore: 0,
      value: 0,
    };
  } else {
    return {
      finalScore: (score * 100).toFixed(0),
      value: score
    };
  }
};
