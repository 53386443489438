import React, { forwardRef } from "react";
import Progress from "react-svg-progress";
import { ReactSVG } from "react-svg";

const LoadingButton = forwardRef(({
  className, 
  title, 
  icon, 
  loading,
  disabled,
  filled, 
  onClick, 
  ...props
}, ref) => {

  const handleClick = (event) => {
    event.preventDefault();
    if (!loading) { onClick && onClick(); }
  }

  return (
    <button
      ref={ref}
      className={`${className} loading-button ${loading ? "loading" : ""} ${filled ? "filled" : ""}`}
      disabled={disabled}
      onClick={onClick && handleClick}
      {...props}
    >
      <span className="content">
        {title}
        {icon ? <ReactSVG className="icon" src={icon} /> : null}
      </span>
      <Progress color={filled ? "#FFFFFF" : "#265F78"} size="15px" />
    </button>
  );
});

export default LoadingButton;
