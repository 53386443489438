import React from "react";
import { useSlate } from "slate-react";

import Button from "./Button";
import Icon from "./Icon";

const RedoButton = () => {
  const editor = useSlate();

  const handleClick = (event) => {
    event.preventDefault();
    editor.redo();
  }

  return (
    <Button onClick={handleClick}>
      <Icon icon="redo" />
    </Button>
  );
}

export default RedoButton;
