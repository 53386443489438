import React from "react";
import _ from "lodash";

const Input = ({ id, label, value, error, ...props }) => {
  const finalId = id || _.uniqueId();
  const finalValue = value || "";

  return (
    <div className="input">
      <label htmlFor={finalId}>{label}</label>
      <div className={`internal-input ${error ? "input-error" : ""}`}>
        <input {...props} id={finalId} value={finalValue} />
      </div>
      <div className="error">
        {error}
      </div>
    </div>
  );
}

export default Input;
