import React, { useEffect, useState } from "react";

import ChipGroup from "../ChipGroup";

const Tabs = ({ selectedObjective, objectives, onTabSelect }) => {

  const mapObjectiveToOption = (objective) => ({
    label: `${objective?.index}. ${objective?.title}`,
    value: objective?.index
  });

  const handleTabSelect = (values) => {
    if (onTabSelect) {
      const objective = objectives.find(obj => obj.index === values[0]);
      onTabSelect(objective);
    }
  }

  return (
    <div className="tabs">
      <ChipGroup
        slim
        value={[selectedObjective?.index]}
        options={objectives.map(mapObjectiveToOption)}
        onValueChange={handleTabSelect}
      />
    </div>
  );
}

export default Tabs;
