import React from "react";

import UpdateButton from "./UpdateButton";

import InlineAlert from "../../commons/InlineAlert";

const Card = ({ icon, title, content, updateLink, ofuscate }) => {

  return (
    <div className="dashboard-card">
      <div className="header">
        {title ? (
          <div className="title">
            {icon ? <img className="icon" src={icon} /> : null}
            <h2>{title}</h2>
          </div>
        ) : null}
        {updateLink ? (
          <UpdateButton href={updateLink} />
        ) : null}
      </div>
      {content}
      {ofuscate ? (
        <InlineAlert fill />
      ) : null}
    </div>
  );
}

export default Card;
