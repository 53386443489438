import React from "react";
import I18n from "i18n-js";

import updateIcon from "../../assets/icons/update.svg"

const UpdateButton = ({ href }) => {
  return (
    <a className="update-button" href={href} target="_self">
      <img className="icon" src={updateIcon} alt="Update" />
      {I18n.t("dashboard.actions.update_kpi")}
    </a>
  );
}

export default UpdateButton;
