import React, { Component, Fragment } from "react";
import { Toaster } from "react-hot-toast";

import useRoutingState from "../hooks/useRoutingState";

import NotebookSelector from "./components/NotebookSelector";
import CancelButton from "./components/CancelButton";
import NoteEditor from "./components/NoteEditor";

const New = ({ user, teams, team, personal }) => {
  useRoutingState();

  const renderSelector = () => (
    <div className="notebook-row selector">
      <NotebookSelector
        user={user}
        teams={teams}
        selectedTeam={team}
        personal={personal}
        isNewPage
      />
      <CancelButton />
    </div>
  );

  const renderPrimaryPanel = () => (
    <div className="primary-panel">
      <NoteEditor />
    </div>
  );

  return (
    <Fragment>
      <div className="notebook notebook-new-editor">
        <div className="container-fluid">
          {renderSelector()}
          <div className="notebook-row content">
            {renderPrimaryPanel()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class NewComponent extends Component {
  render() {
    return <New {...this.props} />;
  }
}
