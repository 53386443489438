import React, { forwardRef } from "react";

const Toolbar = forwardRef(({ className, children, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={`${className} toolbar`}
    data-test-id="toolbar"
  >
    {children}
  </div>
));

export default Toolbar;
