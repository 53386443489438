import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import _, { uniqueId } from "lodash";

import { EmptyState } from "../../commons/Editor";

const MAX_HISTORY_ENTRIES = 15;

const initialState = {
  formality: null,
  useCase: null,
  tones: [],
  wordCount: null,
  language: null,
  inputContent: JSON.stringify(EmptyState),
  outputContent: JSON.stringify(EmptyState),
  outputHistory: []
};

const useStore = create()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        
        setFormality: (formality) => set({ formality }),
        setUseCase: (useCase) => set({ useCase }),
        
        addTone: (tone) => set({ tones: [...get().tones, tone] }),
        removeTone: (index) => {
          const newTones = [...get().tones];
          newTones.splice(index, 1);
          set({ tones: newTones });
        },
        resetTones: () => set({ tones: [] }),
        
        setWordCount: (count) => set({ wordCount: count }),
        setLanguage: (language) => set({ language }),
        resetLanguage: () => set({ language: initialState.language }),
        
        setInputContent: (content) => set({ inputContent: content }),
        setOutputContent: (content) => set({ outputContent: content }),
        resetOutputContent: () => set({
          outputContent: initialState.outputContent,
          outputHistory: initialState.outputHistory
        }),

        addHistoryEntry: (entry) => {
          const history = get().outputHistory.slice(0, MAX_HISTORY_ENTRIES - 2);
          const language = get().language;
          const newHistory = history.map(entry => ({ ...entry, selected: false }));
          set({ outputHistory: [{
            id: uniqueId(),
            content: entry,
            language: language ? language.label : null,
            timestamp: new Date().toISOString(),
            selected: true
          }, ...newHistory] });
        },
        removeHistoryEntry: (entry) => {
          const history = get().outputHistory;
          const newHistory = history.filter(e => e.id != entry.id);

          const selectedEntry = newHistory.shift();
          if (selectedEntry) {
            selectedEntry.selected = true;
            set({ outputHistory: { selectedEntry, ...newHistory }});
            setOutputContent(selectedEntry.content);
            return;
          }
          
          resetOutputContent();
        },
        selectHistoryEntry: (entry) => {
          const history = get().outputHistory;
          const newHistory = history.map(e => {
            if (entry.id == e.id) {
              return { ...e, selected: true };
            }

            return { ...e, selected: false };
          });

          set({ outputHistory: newHistory, outputContent: entry.content });
        },

        reset: () => {
          set({ ...get(), ...initialState }, true);
          localStorage.removeItem("writer-storage");
        }
      }),
      {
        name: "writer-storage",
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => {
          return _.pickBy(state, (_, key) => key !== "language");
        },
        onRehydrateStorage: (state) => {
          console.log("Starting hydration...");
          return (_, error) => {
            if (error) {
              console.log("Error occurred during hydration: ", error);
            } else {
              console.log("Finished hydration...");
            }
          }
        }
      }
    )
  )
);

if (global.window && global.window.Cypress) {
  const initialState = global.window.initialState;
  if (initialState) {
    useStore.setState(initialState);
  }
  window.store = useStore;
}

export default useStore;