import React, { useState } from "react";

import Objective from "./Objective";

const ObjectiveSelector = ({ objectives, onSelect }) => {

  const [selected, setSelected] = useState(objectives[0]);

  const isSelected = (objective) => {
    return selected?.index === objective?.index;
  }

  const handleSelect = (objective) => {
    setSelected(objective);
    onSelect && onSelect(objective);
  }

  return (
    <div className="objective-selector">
      <div className="items">
        {objectives?.map((objective, index) => (
          <Objective
            key={index}
            objective={objective}
            selected={isSelected(objective)}
            onClick={handleSelect}
          />
        ))}
      </div>
    </div>
  );
}

export default ObjectiveSelector;
