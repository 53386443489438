import React, { useState, useRef, useEffect } from "react";
import I18n from "i18n-js";

import Dropdown from "../../commons/Dropdown";

import dropdownIcon from "../../assets/icons/dropdown-arrow.svg";

const DashboardSelector = ({ selectedTeam, teams, isTeamLeadOrAbove, personal }) => {
  const team = selectedTeam || teams[0];
  const locale = I18n.locale;

  const [showDropdown, setShowDropdown] = useState(false);
  const [coachList, setCoachList] = useState([]);
  const [coachProList, setCoachProList] = useState([]);

  const ref = useRef();
  
  useEffect(() => {
    const handler = (event) => {
      if (showDropdown && ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showDropdown]);

  useEffect(() => {
    const coachList = teams.filter(team => !team.pro).map(team => ({
      title: team.name,
      url: getTeamDashboardUrl(team),
    }));

    const coachProList = teams.filter(team => team.pro).map(team => ({
      title: team.name,
      url: getTeamDashboardUrl(team),
    }));

    [coachList, coachProList].forEach(list => {
      list.push({
        title: I18n.t("dashboard.selector.add_new_team"),
        url: `/plans?locale=${locale}`,
        className: "add-new-team"
      });
    });
    
    if (isTeamLeadOrAbove) {
      [coachList, coachProList].forEach(list => {
          list.push({
            title: I18n.t("dashboard.selector.manage_teams"),
            url: `/teams?locale=${locale}`,
            className: "manage-teams"
          });
      });
    }

    setCoachList(coachList);
    setCoachProList(coachProList);
  }, [teams]);

  const getTeamDashboardUrl = (team) => `/teams/${team.uuid}/dashboard?locale=${locale}`;

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  }

  return (
    <div className="dashboard-selector show" ref={ref}>
      <button
        className="button"
        aria-haspopup="true"
        aria-expanded={showDropdown ? "true" : "false"}
        onClick={toggleDropdown}
      >
        <img className="icon" src={dropdownIcon} alt="dropdown icon" />
        {personal ? I18n.t("dashboard.selector.personal_dashboard") : team.name}
      </button>
      <Dropdown
        show={showDropdown}
        submenu={[
          {
            title: I18n.t("dashboard.selector.personal_dashboard"),
            url: `/dashboard/personal?locale=${locale}`
          },
          {
            title: I18n.t("dashboard.selector.team_coach"),
            submenu: coachList
          },
          {
            title: I18n.t("dashboard.selector.team_coach_pro"),
            submenu: coachProList
          }
        ]}
      />
    </div>
  );
}

export default DashboardSelector;
