import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import I18n from "i18n-js";

import useStore from "../../store";

import Slider from "./Slider";
    
const Question = () => {

  const {
    step,
    currentQuestion,
    currentAnswer,
    setAnswer,
    nextStep
  } = useStore(state => state.onboarding);

  const question = currentQuestion();
  const answer = currentAnswer();

  const anwserButtonsRefs = Array(useRef(), useRef(), useRef(), useRef());

  const showAnswers = () => {
    if (step >= 1 && step <= 9) {
      return showButtonsAnswer();
    } else if (step >= 10 && step <= 15) {
      return showSliderAnswer();
    }
  }

  const showButtonsAnswer = () => {
    const answers = question.answers.map(a => I18n.t(`onboarding.${a}`));
    return (
      <div className="list-of-answers">
        {answers.map((answerText, index) => {
          const score = index + 1;
          const className = answer.score && answer.score === score ? "answer-btn selected" : "answer-btn";

          return (
            <Button
              key={index}
              ref={anwserButtonsRefs[index]}
              value={score}
              className={className}
              onClick={(event) => setAnswerOnQuestion(event, step)}
            >
              {answerText}
            </Button>
          );
        })}
      </div>
    );
  };

  const showSliderAnswer = () => {
    const options = question.options;
    return options.map(({ title, answers }, index) => {
      return (
        <Slider
          key={index}
          name={title}
          title={I18n.t(`onboarding.${title}`)}
          value={answer.scores[title]}
          minValueLabel={I18n.t(`onboarding.${answers[0]}`)}
          maxValueLabel={I18n.t(`onboarding.${answers[1]}`)}
          onChange={onSliderAnswerChange}
        />
      );
    });
  };

  const setAnswerOnQuestion = (event) => {
    event.preventDefault();

    nextStep();

    anwserButtonsRefs.forEach(ref => ref.current && ref.current.blur());
    
    const score = parseInt(event.target.value);
    setAnswer(question.index - 1, {
      score: score
    });
  };
  
  const onSliderAnswerChange = (name, value) => {
    const score = parseInt(value);
    setAnswer(question.index - 1, {
      scores: {
        ...answer.scores,
        [name]: score
      }
    });
  };

  return (
    <div className="questions-section">
      <div className="question" data-question-id={question.id}>
        <h6 className="step-counter">
          {`${I18n.t('onboarding.question')} ${question.index} ${I18n.t('onboarding.of_15')}`}
        </h6>
        <p className="question-text">{question.text}</p>
        { showAnswers() }
      </div>
    </div>
  );
}

export default Question;