import React from "react";

const Slider = (props) => {
  const { isOpen, onToggled, children } = props;
  
  const sliderClass = isOpen ? "slider open" : "slider";
  const toggleClass = isOpen ? "slider-toggle open" : "slider-toggle";

  return (
    <div className={sliderClass}>
      {children}
      <button className={toggleClass} onClick={onToggled}>
        Vicky
      </button>
    </div>
  );
}

export default Slider;