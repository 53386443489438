import React, { Component } from "react";
import I18n from "i18n-js";

import logo from "../assets/icons/logo/hey-vicky.svg";

import { handleSubmit, showErrorStyles } from "../commons/formActions";

export default class New extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company_name: "",
      team_name: "",
      errors: {
        name: "",
      },
    };
  }

  handleInputChange = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  setErrorMessages = (errors) => {
    this.setState({ errors });
  };

  showCompanyForm(formFields) {
    const { errors, name } = formFields;

    return (
      <form
        onSubmit={(event) =>
          handleSubmit(
            event,
            ["companies", "company"],
            this.state,
            this.setErrorMessages
          )
        }
      >
        <div className={"form-group name"}>
          <input
            className={showErrorStyles(errors.name)}
            name="company_name"
            type="company_name"
            placeholder={I18n.t("onboarding.name")}
            onChange={this.handleInputChange}
          />
          {errors.name.length > 0 && (
            <span className="error-msg">{errors.name}</span>
          )}
        </div>
        <div className={"form-group name"}>
          <input
            className={showErrorStyles(errors.name)}
            name="team_name"
            type="team_name"
            placeholder={I18n.t("onboarding.first_team_name")}
            onChange={this.handleInputChange}
          />
        </div>
        <input
          className="btn btn-primary btn-block submit-btn"
          type="submit"
          value={I18n.t("onboarding.create_company")}
        />
      </form>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="registration-section vh-100">
          <div className="row position-relative h-100">
            <div className="col col-5">
              <div className="registration-background d-flex flex-column justify-content-center align-items-start">
                <img className="app-logo" src={logo} />
                <div className="background-content">
                  <h1>{I18n.t("onboarding.ready_msg")}</h1>
                </div>
              </div>
            </div>
            <div className="col col-7 d-flex flex-column justify-content-center align-items-center">
              <div className="registration-form">
                <div className="registration-content">
                  <h2>{I18n.t("onboarding.company_name")}</h2>
                  {this.showCompanyForm(this.state)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
