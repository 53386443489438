import React from "react";

const Color = ({ color, onClick }) => (
  <div
    className="color-button-color"
    style={{ backgroundColor: color }}
    onClick={onClick}
  />
);

export default Color;
