import React, { Component, useState } from "react";
import { Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import I18n from "i18n-js";

import api from "../api";

import AllSet from "./AllSet";

import ConfidenceLevelIcon from "../assets/images/onboarding/confidence-level.svg";
import ResourcesIcon from "../assets/images/onboarding/resources.svg";
import ClarityGoalsRolesIcon from "../assets/images/onboarding/clarity-of-goals.svg";

const getSurveyKpiIcon = (kpi) => {
  switch (kpi) {
    case "confidence_level": return ConfidenceLevelIcon;
    case "resource": return ResourcesIcon;
    case "clarity_goals_roles": return ClarityGoalsRolesIcon;
  }
}

const getUpdateUrl = (kpi) => {
  switch (kpi) {
    case "confidence_level": return "/confidence_levels/update_answer.json";
    case "resource": return "/resources/update_answer.json";
    case "clarity_goals_roles": return "/clarity_goals_roles/update_answer.json";
  }
}

const renderI18nBasedOnSurveyKpi = (kpi, scope) => {
  return I18n.t(`${kpi}.${scope}`);
}

const Confirmation = (props) => {
  const {
    team,
    team_assignment_id: teamAssignmentId,
    survey_kpi: surveyKpi,
    current_user: currentUser
  } = props;

  const [score, setScore] = useState(props.score);
  const [submitted, setSubmitted] = useState(false);

  const onScoreChange = (score) => {
    setScore(score);
  }

  const onSubmit = () => {
    setSubmitted(true);
    api.post(getUpdateUrl(surveyKpi), {
      score: score,
      team_assignment_id: teamAssignmentId
    })
    .catch(error => {
      console.log(error);
    });
  }

  const renderAnswerButtons = () => {
    const defaultAnswer = parseInt(score);
    return (
      <ToggleButtonGroup
        type="radio"
        name="options"
        defaultValue={defaultAnswer}
        onChange={onScoreChange}
        className="list-of-answers"
      >
        <ToggleButton
          className={defaultAnswer === 1 ? "answer-btn selected" : "answer-btn"}
          value={1}
        >
          {renderI18nBasedOnSurveyKpi(surveyKpi, "not_confident")}
        </ToggleButton>
        <ToggleButton
          className={defaultAnswer === 2 ? "answer-btn selected" : "answer-btn"}
          value={2}
        >
          {renderI18nBasedOnSurveyKpi(surveyKpi, "not_very_confident")}
        </ToggleButton>
        <ToggleButton
          className={defaultAnswer === 3 ? "answer-btn selected" : "answer-btn"}
          value={3}
        >
          {renderI18nBasedOnSurveyKpi(surveyKpi, "somewhat_confident")}
        </ToggleButton>
        <ToggleButton
          className={defaultAnswer === 4 ? "answer-btn selected" : "answer-btn"}
          value={4}
        >
          {renderI18nBasedOnSurveyKpi(surveyKpi, "very_confident")}
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  const renderQuestionsOrAllset = () => {
    if (!submitted) {
      return (
        <div className="confirmation-form">
          <div className="thanks-section">
            <h2>
              {I18n.t("common.checking_in")}, {currentUser.first_name}!{"\n"}
              {I18n.t("common.change_answer")}
            </h2>
          </div>
          <div className="question-section">
            <div className="icon-container">
              <img
                className="icon"
                src={getSurveyKpiIcon(surveyKpi)}
                alt="icon of the question"
              />
            </div>
            <p className="question-text">
              {renderI18nBasedOnSurveyKpi(surveyKpi, "email_question")}
            </p>
            {renderAnswerButtons()}
            <div className="section-buttons">
              <Button
                className="submit-btn"
                onClick={() => onSubmit()}
              >
                {I18n.t("common.submit")}
              </Button>
            </div>
          </div>
        </div>
      )
    } else {
      return <AllSet team={team} />
    }
  }

  return (
    <div className="confirmation-section" >
      <div className="confirmation-container">
        <div className="content">
          {renderQuestionsOrAllset()}
        </div>
      </div>
    </div>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class ConfirmationComponent extends Component {
  render() {
    return <Confirmation {...this.props} />;
  }
}
