import React, { Component, useEffect } from "react";

import useStore from "../store";

import Progress from "./components/Progress";
import Status from "./components/Status";
import WelcomeMessage from "./components/WelcomeMessage";
import Form from "./components/Form";
import AllSet from "./components/AllSet";

const New = (props) => {
  const { firstName, current_team: team, questions } = props;

  const { step, setQuestions } = useStore(state => state.teamStage);

  useEffect(() => {
    setQuestions(questions);
  }, [questions]);

  const renderStepView = (step) => {
    if (step == 0) {
      return <WelcomeMessage firstName={firstName} />;
    } else if (step >= 1 && step <= 8) {
      return <Form />;
    } else if (step === 9) {
      return <AllSet team={team} />;
    }
  }

  return (
    <div className="team-stage">
      <div className="team-stage-container">
        <div className="sidebar">
          <Progress currentStep={step} />
          <Status currentStep={step} />
        </div>
        <div className="content">
          {renderStepView(step)}
        </div>
      </div>
    </div>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class NewComponent extends Component {
  render() {
    return <New {...this.props} />;
  }
}