import React, { Component, Fragment } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../api";

import { RolesChipGroupItems } from "../../config/roles";

import { AddMemberPermission } from "../../services/permission";

import InternalModal from "../../commons/InternalModal";
import ChipGroup from "../../commons/ChipGroup";

export default class UserAdd extends Component {

  constructor(props) {
    super(props);

    this.state = {
      emails: "",
      role: "user",
      canSubmit: false
    };
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { emails, role } = this.state;
    const { currentTeam } = this.props;

    const data = { team: currentTeam.uuid, emails, role };

    api.post("/users/invitation.json", data)
      .then(res => {
        toast.success(<b>{I18n.t("user.invite_sent")}</b>);
        console.log(res);
        if (res.data && res.data.redirectTo) {
          setTimeout(() => {
            window.location.assign(res.data.redirectTo);
          }, 400);
        }
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(<b>{error.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(error);
      });
  };

  onFormChange = (event, checkbox = false) => {
    const { value, checked, name } = event.target;
    
    if (!checkbox) {
      if (event.preventDefault) event.preventDefault();
    }

    let attributes = {
      [name]: checkbox ? checked : value
    };
    
    if (name === "emails" && value.length > 0) {
      attributes["canSubmit"] = true;
    } else if (name === "emails" && value.length <= 0) {
      attributes["canSubmit"] = false;
    }

    this.setState(attributes);
  };

  renderForm() {
    const { hideModal, loggedUser } = this.props;

    return (
      <Fragment>
        <h4 className="mb-4">{I18n.t("user.add_member")}</h4>
        <Form>
          <p className="d-flex flex-column">
            {I18n.t("user.email_invite")}
            <small
              dangerouslySetInnerHTML={{
                __html: I18n.t("user.disclaimers.invite_members.content", { interpolation: { escapeValue: false }})
              }}
            />
          </p>
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  size="lg"
                  type="text"
                  name="emails"
                  placeholder={I18n.t("user.email_address")}
                  onChange={this.onFormChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <p className="d-flex flex-column">
            {I18n.t("user.role")} 
            <small
              dangerouslySetInnerHTML={{
                __html: I18n.t("user.disclaimers.update_role.content", { interpolation: { escapeValue: false }})
              }}
            />
          </p>
          <Row className="mb-2">
            <Col>
              <ChipGroup
                values={RolesChipGroupItems()}
                disabledValues={AddMemberPermission.disabledRolesForUser(loggedUser)}
                onChipSelected={(value) =>
                  this.onFormChange({ target: { name: "role", value: value }})
                }
              />
            </Col>
          </Row>
        </Form>
        <Col className="d-flex edit-actions">
          <Row className="pl-3">
            <Button
              variant="secondary"
              className="d-flex align-items-center mr-3"
              onClick={hideModal}
            >
              {I18n.t("user.cancel")} 
            </Button>

            <Button
              disabled={!this.state.canSubmit}
              variant="primary"
              type="submit"
              onClick={this.onSubmit}
            >
              {I18n.t('user.send_invite')}
            </Button>
          </Row>
        </Col>
      </Fragment>
    );
  }

  render() {
    const { isModalOpen, hideModal } = this.props;

    return (
      <InternalModal
        type={"user-add"}
        isModalOpen={isModalOpen}
        hideModal={hideModal}
        component={this.renderForm()}
      />
    );
  }
}
