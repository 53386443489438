import React from "react";

import MessageItem from "./MessageItem";
import Error from "./Error";

const MessageContainer = (props) => {
  const { messages, error } = props;

  return (
    <div className="message-container">
      {messages.map((msg, index) => (
        <MessageItem key={index} {...msg} />
      ))}
      {error ? <Error error={error} /> : null}
    </div>
  );
};

export default MessageContainer;
