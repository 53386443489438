const Regex = {
  email: new RegExp(/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/, "m")
};

export const validateEmail = (email) => {
  return Regex.email.test(email);
}

export const validateUniqueness = (values, value) => {
  return !values.includes(value);
}