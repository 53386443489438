import React from "react";
import { useSlate } from "slate-react";

import EditorService from "../../services/EditorService";

import Button from "./Button";
import Icon from "./Icon";

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();

  const blockType = EditorService.TEXT_ALIGN_OPTS.includes(format)
    ? "align"
    : "type";

  const isBlockActive = () =>
    EditorService.isBlockActive(editor, format, blockType);

  const toggleBlock = () =>
    EditorService.toggleBlock(editor, format, blockType);

  const handleClick = (event) => {
    event.preventDefault();
    toggleBlock();
  }

  return (
    <Button
      active={isBlockActive()}
      onClick={handleClick}
    >
      <Icon icon={icon} />
    </Button>
  );
};

export default BlockButton;
