import React, { useEffect, useState, useRef } from "react";
import Progress from "react-svg-progress";
import { ReactSVG } from "react-svg";
import _ from "lodash";

import dropdownIcon from "../assets/icons/dropdown-arrow.svg";

const SingleValueInput = ({
  id,
  label,
  placeholder,
  options,
  value,
  error,
  deselectable,
  loading,
  onValueSelected,
  onValueRemoved,
}) => {
  const finalId = id || _.uniqueId();
  const finalDeselectable = deselectable === undefined || deselectable === null ? true : deselectable;

  const [showItems, setShowItems] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showItems &&
          ref.current &&
          !ref.current.contains(event.target)) {
        setShowItems(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showItems, setShowItems]);

  const handleInputClick = (event) => {
    event.preventDefault();
    if (loading) { return; }

    setShowItems(show => !show);
  }

  const handleItemClick = (option, selected) => {
    if (selected) {
      if (finalDeselectable) {
        onValueRemoved && onValueRemoved(option);
        setShowItems(show => !show);
      }

      return;
    }

    onValueSelected && onValueSelected(option);
    setShowItems(show => !show);
  }

  return (
    <div ref={ref} className="single-value-input">
      <label htmlFor={finalId}>{label}</label>
      <div
        id={finalId}
        className={`input ${showItems ? "open" : ""} ${error ? "input-error" : ""}`}
        onClick={handleInputClick}
      >
        <div className="title">{value && value.label ? value.label : placeholder}</div>
        {loading ? (
          <Progress color="#265F78" size="15px" margin="0 5px 0 0" />
        ) : (
          <ReactSVG className="icon" src={dropdownIcon} />
        )}
      </div>
      <ul className="dropdown-items">
        {options.map((option, index) => {
          const selected = value && value.value && value.value === option.value;
          return (
            <li
              key={index}
              className={`item ${selected ? "selected" : ""}`}
              onClick={() => handleItemClick(option, selected)}
            >
              <span className="title">{option.label}</span>
            </li>
          );
        })}
      </ul>
      <div className="error">
        {error}
      </div>
    </div>
  );
}

export default SingleValueInput;
