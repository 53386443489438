import React, { Component } from "react";

export default class Loading extends Component {
  
  render() {
    const { text, type, color } = this.props;

    return (
      <div className="loading">
        <div className={`n ${type}`}>
          <div className="line">
            {[1, 2, 3, 4].map(item => (
              <div
                key={item}
                className="bar"
                style={{
                  backgroundColor: color
                }}
              />
            ))}
          </div>
          {text ? (
            <div className="text">{text}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

Loading.defaultProps = {
  type: "circle",
  text: null
};