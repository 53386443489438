import React from "react";
import I18n from "i18n-js";

import useStore from "../../store";

import TeamStageIcon from "../../assets/images/onboarding/team-stage.svg";
import BackArrow from "../../assets/images/onboarding/back-arrow.svg";

import Question from "./Question";

const Form = () => {

  const { step } = useStore(state => state.teamStage);

  const showTitle = () => {
    if (step >= 1 && step <= 8) {
      return (
        <div className="section-title">
          <img src={TeamStageIcon} className="section-icon" />
          <p>{I18n.t("team_stage.team_stage")}</p>
        </div>
      );
    }
  }

  const back = (event) => {
    event.preventDefault();
    previousStep();
  };

  return (
    <div className="team-stage-form">
      {showTitle()}

      <Question />

      <div className="section-buttons">
        <div className="previous-btn" onClick={back}>
          <img src={BackArrow} alt="back arrow icon" />
          {I18n.t("team_stage.previous")}
        </div>
      </div>
    </div>
  );
}

export default Form;