import { useEffect, useMemo, useState } from "react";

const useTaskState = (tasks) => {

  const params = useMemo(() =>
    new URLSearchParams(window.location.search),
  [window.location.search]);
  
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState(null);

  useEffect(() => {
    if (params.has("task")) {
      const index = params.get("task");
      console.log(`Task param: ${index}`);

      const task = tasks?.find(t => `${t.index}` === index);

      if (task) {
        setTask(task);
      } else if (tasks?.length) {
        setDefaultTask();
      }
    } else if (tasks?.length) {
      setDefaultTask();
    }

    setLoading(false);
  }, [params, tasks]);

  const setDefaultTask = () => {
    const task = tasks?.find(t => t?.status === "in_progress") || tasks[0];
    setTask(task);
  }

  return { task, setTask, loading };
}

export default useTaskState;
