import React, { useEffect, useState } from "react";

import RadioButton from "./RadioButton";

const RadioGroup = ({ label, items, initialValue, onItemSelected }) => {

  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  const handleOnItemSelected = (item) => {
    setSelectedValue(item.value);
    onItemSelected && onItemSelected(item); 
  }

  return (
    <div className="radio-group">
      <label>{label}</label>
      {items.map((item, index) => (
        <RadioButton
          key={index}
          label={item.label}
          value={item.value}
          checked={selectedValue === item.value}
          onChange={() => handleOnItemSelected(item)}
        />
      ))}
    </div>
  );
}

export default RadioGroup;
