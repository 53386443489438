import React, { Component } from "react";
import { Button, Alert, Collapse } from "react-bootstrap";
import I18n from "i18n-js";

import InternalModal from "../../commons/InternalModal";

export default class UserRemovalConfirmation extends Component {

  renderConfirmation() {
    const { onCancel, onConfirm, disableConfirmButton } = this.props;
    return (
      <Alert
        show
        variant="danger"
        transition={Collapse}
        className="mb-0"
      >
        <Alert.Heading>{I18n.t("user.confirmations.revoke_user_invite.title")}</Alert.Heading>
        <br/>
        <p>{I18n.t("user.confirmations.revoke_user_invite.content")}</p>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-secondary"
            onClick={() => onCancel && onCancel()}
          >
            {I18n.t("common.cancel")}
          </Button>
          <Button
            variant="outline-danger"
            disable={disableConfirmButton.toString()}
            onClick={() => onConfirm && onConfirm()}
          >
            {I18n.t("common.continue")}
          </Button>
        </div>
      </Alert>
    );
  }

  render() {
    const { show, onCancel } = this.props;

    return (
      <InternalModal
        type="user-removal-confirmation"
        isModalOpen={show}
        hideModal={() => onCancel && onCancel()}
        component={this.renderConfirmation()}
        showModalBody={false}
      />
    );
  }
}
