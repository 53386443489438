import React, { Component, Fragment, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Progress from "react-svg-progress";
import I18n from "i18n-js";

import api from "../api";

import useGeniusWriterStore from "../GeniusWriter/store";

import Page from "./components/Page";
import LanguageForm from "./components/LanguageForm";

const Language = ({ availableLanguages, language, user }) => {

  const { reset } = useGeniusWriterStore();

  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const updateUserInfo = () => {
    setLoading(true);
    
    const data = { locale: selectedLanguage };

    api.put(`/users/${user.uuid}.json`, data)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
          setLoading(false);
          reset();
          updateUrlAndReload();
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const updateUrlAndReload = () => {
    const params = new URLSearchParams(location.search);
    params.set("locale", selectedLanguage);
    window.location.search = params.toString();
  }

  const handleSave = () => {
    updateUserInfo();
  }

  const handleCancel = () => {
    setSelectedLanguage(language);
  }

  return (
    <Fragment>
      <Page
        className="language-page"
        title={I18n.t("settings.pages.language.title")}
        subtitle={I18n.t("settings.pages.language.subtitle")}
        user={user}
      >
        <LanguageForm
          availableLanguages={availableLanguages}
          language={selectedLanguage}
          onLanguageSelected={(language) => setSelectedLanguage(language)}
        />
        <div className="separator" />
        <div className="buttons-section">
          <button
            className="cancel-btn"
            disabled={loading}
            onClick={handleCancel}
          >
            {I18n.t("settings.actions.cancel")}
          </button>
          <button
            className="save-btn"
            disabled={loading}
            onClick={handleSave}
          >
            <span className={loading ? "loading" : ""}>
              {I18n.t("settings.actions.save")}
            </span>
            <div className={loading ? "progress loading" : "progress"}>
              <Progress color="#FFFFFF" size="16px" />
            </div>
          </button>
        </div>
      </Page>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class LanguageComponent extends Component {
  render() {
    return <Language {...this.props} />;
  }
}
