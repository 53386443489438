import React, { forwardRef } from "react";
import Progress from "react-svg-progress";
import { ReactSVG } from "react-svg";

const ActionButton = forwardRef(({
  className,
  title,
  icon,
  loading,
  disabled,
  filled,
  href,
  onClick
}, ref) => {

  const handleOnClick = (event) => {
    event.preventDefault();
    if (!loading) { onClick && onClick(); }
  }

  if (href) {
    return (
      <a
        ref={ref}
        href={href}
        className={`${className} action-button ${filled ? "filled" : ""}`}
        disabled={disabled}
      >
        {title}
        {icon ? <ReactSVG className="icon" src={icon} />  : null}
      </a>
    );
  }

  return (
    <button
      ref={ref}
      className={`${className} action-button ${loading ? "loading" : ""} ${filled ? "filled" : ""}`}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {title}
      {loading ? (
        <Progress color={filled ? "#FFFFFF" : "#265F78"} size="15px" />
      ) : icon ? (
        <ReactSVG className="icon" src={icon} />
      ) : null}
    </button>
  );
});

export default ActionButton;
