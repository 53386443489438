import React from "react";
import Progress from "react-svg-progress";
import _ from "lodash";

const Button = ({ id, title, loading, onClick, ...props }) => {
  const finalId = id || _.uniqueId();

  const handleClick = (event) => {
    event.preventDefault();
    if (!loading) { onClick && onClick(); }
  }

  return (
    <button
      {...props}
      id={finalId}
      className={loading ? "common-button loading" : "common-button"}
      onClick={handleClick}
    >
      {loading ? (
        <Progress color="#FFFFFF" size="15px" />
      ) : title}
    </button>
  );
}

export default Button;
