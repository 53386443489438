import { useEffect, useMemo, useState } from "react";

const useObjectiveState = (objectives) => {

  const params = useMemo(() =>
    new URLSearchParams(window.location.search),
  [window.location]);

  const [loading, setLoading] = useState(true);
  const [objective, setObjectiveInternal] = useState(null);

  useEffect(() => {
    if (params.has("objective")) {
      const index = params.get("objective");
      console.log(`Objective param: ${index}`);

      let objective = objectives.find(o => `${o.index}` === index);

      if (!objective && objectives.length) {
        objective = objectives[0];
      }

      setObjectiveInternal(objective);
    } else if (objectives.length) {
      setObjectiveInternal(objectives[0]);
    }

    setLoading(false);
  }, [params, objectives]);

  const setObjective = (objective) => {
    removeObjectiveAndTaskParams();
    setObjectiveInternal(objective);
  }

  const removeObjectiveAndTaskParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("objective");
    url.searchParams.delete("task");
    window.history.replaceState(null, "", url);
  }

  return { objective, setObjective, loading };
}

export default useObjectiveState;
