import React from "react";
import I18n from "i18n-js";

import Progress from "../Onboarding/components/Progress";
import Status from "../Onboarding/components/Status";

import googleLogo from "../assets/images/integrations/google.png";
import microsoftLogo from "../assets/images/integrations/microsoft.png";
import slackLogo from "../assets/images/integrations/slack.svg";
import integrationIcon from "../assets/images/onboarding/integration.svg";
import finishFlagIcon from "../assets/images/onboarding/finish-flag.svg";
import infoIcon from "../assets/icons/info.svg";

const New = ({ plan, oauths, team }) => {

  const teamUuid = team ? team.uuid : null;

  const redirectToHomepage = (event) => {
    event.preventDefault();
    window.location.assign(`/?current_team=${teamUuid}&locale=${I18n.locale}`);
  };

  const isConnected = (provider = undefined) => {
    if (Array.isArray(oauths) && oauths.length) {
      if (!provider) return true
      const oauth = oauths.find(oauth => oauth.provider == provider);
      return Boolean(oauth);
    }
    
    return false;
  };

  const renderConnected = (provider) => {
    if (isConnected(provider)) {
      return (
        <p className="connected">
          &#10003;&nbsp;{I18n.t("onboarding.connected")}
        </p>
      );
    }
  };

  const renderIntegrationsButtons = (disabled = false) => (
    <div className="integrations">
      <form action={`/users/auth/google?team_uuid=${teamUuid}`} method="POST">
        <button type="submit" className="google-btn" disabled={disabled}>
          <img
            className="google-img"
            src={googleLogo}
            alt="google logo"
          />
        </button>
        {disabled ? null : renderConnected("google")}
      </form>
      <form action={`/users/auth/ms_graph?team_uuid=${teamUuid}`} method="POST">
        <button type="submit" className="microsoft-btn" disabled={disabled}>
          <img
            className="microsoft-img"
            src={microsoftLogo}
            alt="microsoft logo"
          />
        </button>
        {disabled ? null : renderConnected("ms_graph")}
      </form>
      <form action={`/users/auth/slack?team_uuid=${teamUuid}`} method="POST">
        <button type="submit" className="slack-btn" disabled={disabled}>
          <img
            className="slack-img"
            src={slackLogo}
            alt="slack logo"
          />
        </button>
        {disabled ? null : renderConnected("slack")}
      </form>
    </div>
  );

  const renderTeamProContent = () => (
    <div className="integration-content">
      <div className="section-title">
        <img src={integrationIcon} className="section-icon" />
        <p>{I18n.t("onboarding.integrations")}</p>
      </div>
      <div className="section-content">
        <h2 className="title">
          {I18n.t("onboarding.last_step")}
        </h2>
        <p className="description">
          {I18n.t("onboarding.select_service")}
        </p>
        {renderIntegrationsButtons()}
        <div className="disclaimer">
          <img className="icon" src={infoIcon} alt="info" />
          {I18n.t("onboarding.disclaimer")}
        </div>
      </div>
      <div className="section-buttons">
        <button
          className="finish-btn"
          onClick={redirectToHomepage}
        >
          {I18n.t("onboarding.finish")}
          <img
            className="finish-flag" 
            src={finishFlagIcon}
            alt="finish flag"
          />
        </button>
      </div>
    </div>
  );

  const renderCoachProContent = () => (
    <div className="integration-content">
      <div className="section-title">
        <img src={integrationIcon} className="section-icon" />
        <p>{I18n.t("onboarding.integrations")}</p>
      </div>
      <div className="section-content">
        <h2 className="title">
          {I18n.t("onboarding.upgrade_to_teams")}
        </h2>
        <p className="description">
          {I18n.t("onboarding.upgrade_to_teams_description")}
        </p>
        {renderIntegrationsButtons(true)}
        <a className="upgrade-link" href={`/plans?locale=${I18n.locale}`}>
          {I18n.t("onboarding.upgrade")}
        </a>
        <a className="trial-link" href={`/?locale=${I18n.locale}`}>
          {I18n.t("onboarding.trial")}
        </a>
      </div>
    </div>
  );

  return (
    <div className="integration">
      <div className="integration-container">
        <div className="sidebar">
          <Progress currentStep={16} team={team} />
          <Status currentStep={16} />
        </div>
        <div className="content">
          {plan === "personal"
            ? renderCoachProContent()
            : renderTeamProContent()
          }
        </div>
      </div>
    </div>
  );
}

export default New;