import React, { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import _ from "lodash";

import Chip from "./Chip";

import dropdownIcon from "../../assets/icons/dropdown-arrow.svg";
import checkboxIcon from "../../assets/icons/checkbox.svg";
import checkboxBlankIcon from "../../assets/icons/checkbox-blank.svg";

const MultiValueInput = ({
  id,
  label,
  placeholder,
  options,
  values,
  error,
  maxValues,
  onValueSelected,
  onValueRemoved
}) => {
  const finalId = id || _.uniqueId();

  const [showItems, setShowItems] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showItems &&
          ref.current &&
          !ref.current.contains(event.target)) {
        setShowItems(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showItems, setShowItems]);

  const handleInputClick = (event) => {
    event.preventDefault();
    setShowItems(show => !show);
  }

  const handleItemClick = (option, selected) => {
    if (selected) {
      const index = _.indexOf(values, option);
      onValueRemoved && onValueRemoved(index);
      return;
    }

    if (maxValues && values.length >= maxValues) {
      return;
    }

    onValueSelected && onValueSelected(option);
  }

  const handleOnValueDelete = (index) => {
    onValueRemoved && onValueRemoved(index);
  }

  return (
    <div ref={ref} className="multi-value-input">
      <label htmlFor={finalId}>{label}</label>
      <div
        id={finalId}
        className={`input ${showItems ? "open" : ""} ${error ? "input-error" : ""}`}
        onClick={handleInputClick}
      >
        <div className="title">{placeholder}</div>
        <ReactSVG className="icon" src={dropdownIcon} />
      </div>
      <ul className="dropdown-items">
        {options.map((option, index) => {
          const selected = values.find(v => v.value === option.value);
          const icon = selected ? checkboxIcon : checkboxBlankIcon;
          return (
            <li
              key={index}
              className={`item ${selected ? "selected" : ""}`}
              onClick={() => handleItemClick(option, selected)}
            >
              <span className="checkbox">
                <img src={icon} alt="checkbox" />
              </span>
              <span className="title">{option.label}</span>
            </li>
          );
        })}
      </ul>
      <div className="error">
        {error}
      </div>
      <div className="selected-values">
        {values.map((value, index) => (
          <Chip
            key={index}
            value={value.label}
            onDelete={() => handleOnValueDelete(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default MultiValueInput;
