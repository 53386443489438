import React from "react";

import completedIcon from "../../../assets/icons/pdp/completed.svg";

const Indicator = ({ task }) => (
  <div className={`indicator ${task.status}`}>
    {task.status === "completed" ? (
      <img className="icon" src={completedIcon} alt="completed" />
    ) : null}
  </div>
);

export default Indicator;
