import React, { Component, Fragment } from "react";
import { Toaster } from "react-hot-toast";
import I18n from "i18n-js";

import { FullAccessRoles, RolesThatCanLeaveTeam } from "../config/roles";

import TeamSettings from "./components/TeamSettings";
import UsersTable from "./components/UsersTable";
import MemberCount from "../commons/MemberCount";
import MaxMemberCount from "../commons/MaxMemberCount";
import UserEdit from "./components/UserEdit";
import UserAdd from "./components/UserAdd";
import LeaveTeamConfirmation from "./components/LeaveTeamConfirmation";

import BackArrowIcon from "../assets/images/arrow_forward_24px.svg";
import AddIcon from "../assets/images/add-icon.svg";

export default class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showTeamSettings: false,
      isEditModalOpen: false,
      isAddModalOpen: false,
      selectedUser: {},

      showLeaveTeamConfirmation: false,
    };
  }

  onLeaveTeamButtonClick = (event) => {
    event.preventDefault();
    this.setState({ showLeaveTeamConfirmation: true });
  }

  onLeaveTeamConfirmationCancel = () => {
    this.setState({ showLeaveTeamConfirmation: false });
  }

  showTeamSettings = () => {
    this.setState({ showTeamSettings: true });
  }

  onTeamSettingsCancel = () => {
    this.setState({ showTeamSettings: false });
  }

  openUserEdit = (user) => {
    this.setState({ isEditModalOpen: true, selectedUser: user });
  };

  hideUserEdit = () => {
    this.setState({ isEditModalOpen: false, selectedUser: {} });
  };

  openUserAdd = () => {
    const { team } = this.props;
    const canAddNewMembers = team.team_members_count < team.max_members;

    if (canAddNewMembers) {
      this.setState({ isAddModalOpen: true });
      return;
    }

    window.location.assign(`/plans?plan=larger-teams&locale=${I18n.locale}`)
  };

  hideUserAdd = () => {
    this.setState({ isAddModalOpen: false });
  };

  render() {
    const { team, list_of_roles, logged_user: loggedUser } = this.props;
    
    const loggedUserHasAccess = FullAccessRoles.includes(loggedUser.role_id);
    const showLeaveButton = RolesThatCanLeaveTeam.includes(loggedUser.role_id);

    return (
      <Fragment>
        <div className="container user-index">
          <div className="row mt-5 mb-4">
            <div className="col d-flex align-items-center team-name">
              <h4 className="mb-0">
                <span className="pr-4">
                  <a href="/teams">
                    <img src={BackArrowIcon}></img>
                  </a>
                </span>
                <span>
                  {team.name}
                </span>
              </h4>
            </div>
            <div className="team-plan">
              {team.pro ? "Team Coach Pro" : "Team Coach"}
            </div>
            <div className="member-counters">
              <MemberCount memberCount={team.team_members_count} />
              <MaxMemberCount maxMembers={team.max_members} />
            </div>
            {loggedUserHasAccess ? (
              <Fragment>
                <div className="col-auto">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => this.showTeamSettings()}
                  >
                    {I18n.t("team.settings.title")}
                  </button>
                </div>
                <div className="col-auto">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => this.openUserAdd()}
                  >
                    {I18n.t("team.add_members")}
                    <img src={AddIcon} className="score-icon" />
                  </button>
                </div>
              </Fragment>
            ) : null}
            {showLeaveButton ? (
              <div className="col-auto">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={this.onLeaveTeamButtonClick}
                >
                  {I18n.t("team.leave")}
                </button>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col">
              <UsersTable
                currentTeam={team}
                listOfRoles={list_of_roles}
                loggedUser={loggedUser}
                openUserEdit={this.openUserEdit}
              />
            </div>
          </div>
        </div>

        <LeaveTeamConfirmation
          show={this.state.showLeaveTeamConfirmation}
          team={team}
          onCancel={this.onLeaveTeamConfirmationCancel}
        />

        <TeamSettings
          show={this.state.showTeamSettings}
          onCancel={this.onTeamSettingsCancel}
          team={team}
          loggedUser={loggedUser}
        />

        <UserEdit
          isModalOpen={this.state.isEditModalOpen}
          hideUserEdit={this.hideUserEdit}
          selectedUser={this.state.selectedUser}
          currentTeam={team}
          loggedUser={loggedUser}
        />

        <UserAdd
          isModalOpen={this.state.isAddModalOpen}
          hideModal={this.hideUserAdd}
          currentTeam={team}
          loggedUser={loggedUser}
        />

        <Toaster
          position="top-right"
          reverseOrder
        />
      </Fragment>
    );
  }
}
