import React, { Fragment } from "react";
import { Container, Row } from "react-bootstrap";
import I18n from "i18n-js";

import Teams from "./components/Teams";
import NewTeamButton from "./components/NewTeamButton";

const Index = ({ activeTeams, archivedTeams }) => {
  const showArchivedTeams = () => {
    return (
      <Fragment>
        <hr />
          <div className="header">
            <div className="title">
              <h4>{I18n.t("team.archived_teams")}</h4>
            </div>
          </div>
          <Row className="team-archive">
            <Teams teams={archivedTeams} />
          </Row>
      </Fragment>
    );
  }

  return (
    <Container className="team-index">
      <div className="header">
        <div className="title">
          <h4>{I18n.t("team.my_teams")}</h4>
        </div>
        <NewTeamButton />
      </div>

      <Row>
        <Teams teams={activeTeams} />
      </Row>

      {archivedTeams.length ? showArchivedTeams() : null}
    </Container>
  );
}

export default Index;
