import React, { Component, Fragment } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../api";

import { RolesThatCanChangeTeamLeadPermissions } from "../../config/roles";

import InternalModal from "../../commons/InternalModal";

export default class TeamSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      teamLeadCanAccessLeadershipData: true,
      canSubmit: false,
    };
  }

  updateTeamInfo = (event) => {
    event.preventDefault();

    const { name, teamLeadCanAccessLeadershipData } = this.state;
    const { team } = this.props;

    let teamData = null;
    let permissionData = null;

    if (name.length > 1) {
      teamData = { name };
    }

    if (teamLeadCanAccessLeadershipData !== '') {
      permissionData = {
        team_lead_can_access_leadership_data: teamLeadCanAccessLeadershipData
      };
    }

    const requests = [];

    if (teamData !== null) {
      requests.push({
        url: `/teams/${team.uuid}.json`,
        method: "patch",
        data: teamData
      });
    }

    if (permissionData !== null) {
      requests.push({
        url: `/teams/${team.uuid}/permissions`,
        method: "patch",
        data: permissionData
      });
    }

    Promise.all(requests.map(req => api.request({ ...req })))
      .then(res => {
        toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
        const response = res[0] || res;
        console.log(response);
        if (response.data && response.data.redirectTo) {
          setTimeout(() => {
            window.location.assign(response.data.redirectTo);
          }, 400);
        }
      })
      .catch(err => {
        const error = err[0] || err;
        if (error.response && error.response.status === 422) {
          toast.error(<b>{I18n.t("common.errors.invalid_data")}</b>);
        } else if (error.response && error.response.data && error.response.data.message) {
          toast.error(<b>{error.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(error);
      });
  };

  resetTeam = (event) => {
    event.preventDefault();

    const { team } = this.props;

    api.post(`/teams/${team.uuid}/reset`)
      .then(res => {
        toast.success(<b>{I18n.t("common.reseted_successfully")}</b>);
        if (res.data && res.data.redirectTo) {
          setTimeout(() => {
            window.location.assign(res.data.redirectTo);
          }, 400);
        }
      })
      .catch(err => {
        const error = err[0] || err;
        if (error.response && error.response.status === 422) {
          toast.error(<b>{I18n.t("common.errors.invalid_data")}</b>);
        } else if (error.response && error.response.data && error.response.data.message) {
          toast.error(<b>{error.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(error);
      });
  };

  handleChange = (event, checkbox = false) => {
    const { value, checked, name } = event.target;
    
    if (!checkbox) {
      event.preventDefault();
    }

    let attributes = {
      [name]: checkbox ? checked : value
    };
    
    if (!this.state.canSubmit) {
      attributes["canSubmit"] = true;
    }

    this.setState(attributes);
  };

  renderTeamLeadPermissionSection() {
    const { team, loggedUser } = this.props;

    const showSection = RolesThatCanChangeTeamLeadPermissions
      .includes(loggedUser.role_id);

    return showSection ? (
      <Row className="flex-column mb-4">
        <Col className="mb-2 subtitle">{I18n.t("team.team_lead_permission")}</Col>
        <Col className="d-flex align-items-center">
          <small
            className="flex-grow-1"
            dangerouslySetInnerHTML={{
              __html: I18n.t("team.disclaimers.team_lead_permission.content", { interpolation: { escapeValue: false }})
            }}
          />
          <Form.Check
            id="teamLeadCanAccessLeadershipData"
            name="teamLeadCanAccessLeadershipData"
            type="checkbox"
            className="custom-switch custom-switch-sm switch"
          >
            <Form.Check.Input
              name="teamLeadCanAccessLeadershipData"
              className="custom-switch-input"
              type="checkbox"
              defaultChecked={team.team_lead_can_access_leadership_data}
              onChange={(event) => this.handleChange(event, true)}
            />
            <Form.Check.Label className="custom-switch-btn" htmlFor="teamLeadCanAccessLeadershipData" />
            <div className="custom-switch-content-checked">
              {I18n.t("common.active")}
            </div>
            <div className="custom-switch-content-unchecked">
              {I18n.t("common.inactive")}
            </div>
          </Form.Check>
        </Col>
      </Row>
    ) : null;
  }

  renderResetTeamSection() {
    const { loggedUser } = this.props;

    const showSection = loggedUser.role_id == "program_advisor";

    return showSection ? (
      <Row className="flex-column mb-4">
        <Col className="mb-2 subtitle">{I18n.t("team.reset_team")}</Col>
        <Col className="d-flex align-items-center">
          <small
            className="flex-grow-1"
            dangerouslySetInnerHTML={{
              __html: I18n.t("team.disclaimers.reset_team.content", { interpolation: { escapeValue: false }})
            }}
          />
          <Button
            variant="danger"
            className="d-flex align-items-center"
            onClick={this.resetTeam}
          >
            {I18n.t("common.actions.reset")} 
          </Button>
        </Col>
      </Row>
    ) : null;
  }

  renderForm() {
    const { team, onCancel } = this.props;

    return (
      <Fragment>
        <h4 className="mb-4">{I18n.t("team.settings.title")}</h4>
        <Form>
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="name">
                <Form.Control
                  size="lg"
                  name="name"
                  placeholder={I18n.t("team.team_name")}
                  defaultValue={team.name}
                  onChange={(event) => this.handleChange(event)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        {this.renderTeamLeadPermissionSection()}
        {this.renderResetTeamSection()}
        <Col className="d-flex edit-actions mt-5">
          <Row className="pl-3">
            <Button
              variant="secondary"
              className="d-flex align-items-center mr-3"
              onClick={() => onCancel && onCancel()}
            >
              {I18n.t("common.cancel")} 
            </Button>

            <Button
              disabled={!this.state.canSubmit}
              variant="primary"
              type="submit"
              onClick={this.updateTeamInfo}
            >
              {I18n.t("common.save")} 
            </Button>
          </Row>
        </Col>
      </Fragment>
    );
  }

  render() {
    const { show, onCancel } = this.props;

    return (
      <InternalModal
        type="team-settings"
        isModalOpen={show}
        hideModal={() => onCancel && onCancel()}
        component={this.renderForm()}
      />
    );
  }
}
