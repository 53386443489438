import { Editor, Element, Transforms } from "slate";
import _ from "lodash";

const EditorService = {

  MARKS_OPTS: ["bold", "italic", "underline", "strikethrough", "code", "color"],
  LIST_OPTS: ["numbered-list", "bulleted-list"],
  TEXT_ALIGN_OPTS: ["left", "center", "right", "justify"],

  isMarkActive(editor, format) {
    try {
      const marks = Editor.marks(editor);
      return marks ? marks[format] === true || !_.isEmpty(marks[format]) : false;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  toggleMark(editor, format) {
    if (EditorService.isMarkActive(editor, format)) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  },

  getMarkColor(editor) {
    try {
      const marks = Editor.marks(editor);
      return marks ? marks["color"] : undefined;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  colorMark(editor, value) {
    if (EditorService.isMarkActive(editor, "color")) {
      Editor.removeMark(editor, "color");
    }
    Editor.addMark(editor, "color", value);
  },

  isBlockActive(editor, format, blockType = "type") {
    try {
      const { selection } = editor;
      if (!selection) return false;

      const [match] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: node =>
            !Editor.isEditor(node) &&
            Element.isElement(node) &&
            node[blockType] === format
        })
      );

      return !!match;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  toggleBlock(editor, format, blockType = "type") {
    const isActive = EditorService.isBlockActive(editor, format, blockType);
    const isList = EditorService.LIST_OPTS.includes(format);

    Transforms.unwrapNodes(editor, {
      match: node =>
        !Editor.isEditor(node) &&
        Element.isElement(node) &&
        EditorService.LIST_OPTS.includes(node.type) &&
        !EditorService.TEXT_ALIGN_OPTS.includes(format),
      split: true
    });

    let newProperties;

    if (EditorService.TEXT_ALIGN_OPTS.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format
      };
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format
      };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  },

  clearFormatting(editor) {
    EditorService.MARKS_OPTS.forEach(mark => {
      if (EditorService.isMarkActive(editor, mark)) {
        Editor.removeMark(editor, mark);
      }
    });
  }
};

export default EditorService;
