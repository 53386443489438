import React from "react";
import { Button } from "react-bootstrap";
import I18n from "i18n-js";

import TeamStageIcon from "../../assets/images/onboarding/team-stage.svg";
import KpisIcon from "../../assets/images/onboarding/kpi.svg";
import LeadershipIcon from "../../assets/images/onboarding/leadership.svg";
import BackArrow from "../../assets/images/onboarding/back-arrow.svg";

import useStore from "../../store";

import { submitData } from "../services/submitData";

import Question from "./Question";

const Form = ({ team }) => {

  const { step, answers, nextStep, previousStep } = useStore(state => state.onboarding);

  const showTitle = (step) => {
    if (step >= 1 && step <= 8) {
      return (
        <div className="section-title">
          <img src={TeamStageIcon} className="section-icon" />
          <p>{I18n.t("onboarding.team_stage")}</p>
        </div>
      );
    } else if (step >= 9 && step <= 13) {
      return (
        <div className="section-title">
          <img src={KpisIcon} className="section-icon" />
          <p>{I18n.t("onboarding.kpis")}</p>
        </div>
      );
    } else if (step >= 14 && step <= 15) {
      return (
        <div className="section-title">
          <img src={LeadershipIcon} className="section-icon" />
          <p>{I18n.t("onboarding.leadership")}</p>
        </div>
      );
    }
  }

  const showNextBtn = (step) => {
    if (step >= 0 && step <= 9) return null;
    return (
      <Button
        className="next-btn"
        onClick={step == 15 ? finish : next}
      >
        {I18n.t("onboarding.next")}
      </Button>
    );
  }

  const next = (event) => {
    if (event) { event.preventDefault(); }
    nextStep();
  };

  const back = (event) => {
    event.preventDefault();
    previousStep();
  };

  const finish = () => {
    submitData({ answers, team });
  };

  return (
    <div className="onboarding-form">
      {showTitle(step)}

      <Question />

      <div className="section-buttons">
        <div className="previous-btn" onClick={back}>
          <img src={BackArrow} alt="back arrow icon" />
          {I18n.t("onboarding.previous")}
        </div>
        {showNextBtn(step)}
      </div>
    </div>
  );
}

export default Form;