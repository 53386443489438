import { useRef } from "react";

const safeDocument = typeof document !== "undefined" ? document : {};

const useScrollBlock = () => {
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    body.style.position = "relative";
    body.style.overflow = "hidden";

    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  }

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    body.style.position = "unset";
    body.style.overflow = "unset";
    body.style.paddingRight = "unset";

    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
}

export default useScrollBlock;
