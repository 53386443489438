import myHealthIcon from "../assets/icons/my-health.svg";
import teamHealthIcon from "../assets/icons/team-health.svg";
import leadershipHealthIcon from "../assets/icons/leadership-health.svg";

import collaborationIcon from "../assets/images/onboarding/collaboration.svg";
import communicationIcon from "../assets/images/onboarding/communication.svg";
import engagementIcon from "../assets/images/onboarding/engagement.svg";
import adaptabilityIcon from "../assets/images/onboarding/adaptability.svg";
import resourcesIcon from "../assets/images/onboarding/resources.svg";
import clarityOfGoalsIcon from "../assets/images/onboarding/clarity-of-goals.svg";
import trustEnvironmentIcon from "../assets/images/trust-environment.svg";
import managementPracticeIcon from "../assets/images/management-practice.svg";

export const generateRandomScore = () => Math.random();

export const getDashboardData = (userHealth, teamHealth = null, leadershipHealth = null) => ({
  my_health: {
    icon: myHealthIcon,
    title: I18n.t("dashboard.my_health"),
    updateLink: teamHealth ? null : "/onboardings/new",
    kpis: [
      {
        title: I18n.t("dashboard.adaptability"),
        score: userHealth ? userHealth.adaptability : 0,
        icon: adaptabilityIcon
      },
      {
        title: I18n.t("dashboard.collaboration"),
        score: userHealth ? userHealth.collaboration : 0,
        icon: collaborationIcon
      },
      {
        title: I18n.t("dashboard.communication"),
        score: userHealth ? userHealth.communication : 0,
        icon: communicationIcon
      },
      {
        title: I18n.t("dashboard.engagement"),
        score: userHealth ? userHealth.engagement : 0,
        icon: engagementIcon
      }
    ]
  },
  team_health: {
    icon: teamHealthIcon,
    title: I18n.t("dashboard.team_health"),
    kpis: [
      {
        title: I18n.t("dashboard.adaptability"),
        score: teamHealth ? teamHealth.adaptability : null,
        icon: adaptabilityIcon
      },
      {
        title: I18n.t("dashboard.collaboration"),
        score: teamHealth ? teamHealth.collaboration : null,
        icon: collaborationIcon
      },
      {
        title: I18n.t("dashboard.communication"),
        score: teamHealth ? teamHealth.communication : null,
        icon: communicationIcon
      },
      {
        title: I18n.t("dashboard.engagement"),
        score: teamHealth ? teamHealth.engagement : null,
        icon: engagementIcon
      }
    ]
  },
  leadership_health: {
    icon: leadershipHealthIcon,
    title: I18n.t("dashboard.leadership_health"),
    kpis: [
      {
        title: I18n.t("dashboard.resources"),
        score: leadershipHealth ? leadershipHealth.resources : null,
        icon: resourcesIcon
      },
      {
        title: I18n.t("dashboard.clarity_of_goals"),
        score: leadershipHealth ? leadershipHealth.clarity_of_goals : null,
        icon: clarityOfGoalsIcon
      },
      {
        title: I18n.t("dashboard.trust_environment"),
        score: leadershipHealth ? leadershipHealth.trust_environment : null,
        icon: trustEnvironmentIcon
      },
      {
        title: I18n.t("dashboard.management_practice"),
        score: leadershipHealth ? leadershipHealth.management_practice : null,
        icon: managementPracticeIcon
      }
    ]
  }
});