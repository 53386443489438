import React, { Fragment } from "react";
import toast from "react-hot-toast";

import api from "../../api";

import googleLogo from "../../assets/images/integrations/google.png";
import microsoftLogo from "../../assets/images/integrations/microsoft.png";
import slackLogo from "../../assets/images/integrations/slack.svg";
import _ from "lodash";

const IntegrationIcons = {
  google: googleLogo,
  slack: slackLogo,
  ms_graph: microsoftLogo
};

const AvailableProviders = ["google", "slack", "ms_graph"];

const IntegrationItem = ({ user, team }) => {

  const disconnectUserIntegration = (provider) => {
    api.delete(`/users/${user.uuid}/${provider}?team_uuid=${team.uuid}`)
      .then(res => {
        console.log(res)
        window.location.reload();
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(err);
      })
  };

  const getDisconnectedProviders = () => {
    const active = team.integrations.map(i => i.provider);
    return AvailableProviders.filter(p => !_.includes(active, p));
  }

  const renderDisconnectedProvider = (provider) => (
    <div key={provider} className="integration">
      <img
        className={provider}
        src={IntegrationIcons[provider]}
        alt="integration icon"
      />
      <div className="divider" />
      <form
        action={`/users/auth/${provider}?team_uuid=${team.uuid}`}
        method="post"
      >
        <button className="integration-btn" type="submit">
          {I18n.t("settings.pages.integrations.connect")}
        </button>
      </form>
    </div>
  );

  const renderDisconnectedProviders = () => {
    const providers = getDisconnectedProviders();
    return providers.map(renderDisconnectedProvider);
  }

  return (
    <div className="integration-item">
      <div className="title">{team.name}</div>
      {team.integrations.map(integration => (
        <div key={integration.id} className="integration">
          <img
            className={integration.provider}
            src={IntegrationIcons[integration.provider]}
            alt="integration icon"
          />
          {integration.email_address !== null && (
            <Fragment>
              <div className="divider" />
              <div className="email">
                {integration.email_address}
              </div>
            </Fragment>
          )}
          {integration.provider === "slack" && (
            <Fragment>
              <div className="divider" />
              <div className="slack-user-id">
                ID: {integration.slack_user_id}
              </div>
            </Fragment>
          )}
          <div className="divider" />
          <form
            action={`/users/auth/${integration.provider}?team_uuid=${team.uuid}&login_hint=${integration.email_address}`}
            method="post"
          >
            <button className="integration-btn" type="submit">
              {I18n.t("settings.pages.integrations.reconnect")}
            </button>
          </form>
          <div className="divider" />
          <button
            className="integration-btn"
            onClick={() => disconnectUserIntegration(integration.provider)}
          >
            {I18n.t("settings.pages.integrations.disconnect")}
          </button>
        </div>
      ))}
      {renderDisconnectedProviders()}
    </div>
  );
}

export default IntegrationItem;
