import React, { useEffect, useState, useRef, Fragment } from "react";
import { createPortal } from "react-dom";
import { useSlate } from "slate-react";

import EditorService from "../../services/EditorService";

import Button from "./Button";
import ColorPicker from "./ColorPicker";
import Color from "./Color";

import chevron from "../../assets/icons/editor/chevron-down.svg";

const COLOR_PICKER_WIDTH_PX = 200;

const ColorButton = () => {
  const editor = useSlate();

  const initialColor = "#000000";

  const [color, setColor] = useState(initialColor);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorPickerPosition, setColorPickerPosition] = useState({});

  const ref = useRef();
  const colorPickerRef = useRef();

  const markColor = EditorService.getMarkColor(editor);

  useEffect(() => {
    setColor(markColor ? markColor : initialColor);
  }, [markColor]);

  useEffect(() => {
    const handler = (event) => {
      if (showColorPicker &&
          !ref.current.contains(event.target) &&
          !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showColorPicker]);

  const handleClick = (event) => {
    event.preventDefault();

    const rect = ref.current.getBoundingClientRect();
    setColorPickerPosition({
      top: rect.y + 32,
      left: rect.x - (COLOR_PICKER_WIDTH_PX / 2 - rect.width / 2),
    });

    setShowColorPicker(show => !show);
  }

  const handleColorChange = (color) => {
    EditorService.colorMark(editor, color);
  }

  return (
    <div className="color-button">
      <Button ref={ref} onClick={handleClick}>
        <Color color={color} />
        <img className="chevron" src={chevron} alt="open options" />
      </Button>
      {showColorPicker && createPortal(
        <ColorPicker
          ref={colorPickerRef}
          initialColor={color}
          position={colorPickerPosition}
          onChange={handleColorChange}
        />,
        document.body
      )}
    </div>
  )
}

export default ColorButton;
