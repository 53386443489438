import React from "react";

const Error = ({ error }) => (
  <div className="error">
    {error.code    ? (<>{error.code}<br /></>) : null}
    {error.message ? (<>{error.message}<br /></>) : null}
    {error.type    ? (<>{error.type}<br /></>) : null}
    {error.param   ? (<>{error.param}<br /></>) : null}
  </div>
);

export default Error;
