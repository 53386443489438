import React from "react";
import I18n from "i18n-js";

import ActionButton from "../../commons/ActionButton";

import copyIcon from "../../assets/icons/copy.svg";
import clearIcon from "../../assets/icons/clear.svg";

const Header = ({ title, loading, showCopyAction, showClearAction, onCopyClick, onClearClick }) => (
  <div className="editor-header">
    {title}
    <div className="actions">
      {showCopyAction ? (
        <ActionButton
          title={I18n.t("writer.actions.copy")}
          icon={copyIcon}
          loading={loading}
          onClick={onCopyClick}
        />
      ) : null}
      {showClearAction ? (
        <ActionButton
          title={I18n.t("writer.actions.clear")}
          icon={clearIcon}
          onClick={onClearClick}
        />
      ) : null}
    </div>
  </div>
);

export default Header;
