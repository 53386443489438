import React from "react";
import I18n from "i18n-js";

import lockIcon from "../assets/icons/lock.svg";

const ComingSoon = ({ title, features }) => (
  <div className="coming-soon">
    <div className="locked-feature">
      <img className="icon" src={lockIcon} />
      <span>{I18n.t("common.components.coming_soon.locked_feature")}</span>
    </div>
    <h1>{I18n.t("common.components.coming_soon.title")}</h1>
    <p>{title}</p>
    <ul>
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
  </div>
);

export default ComingSoon;
