import React from "react";
import I18n from "i18n-js";
import { ReactSVG } from "react-svg";

import clearIcon from "../assets/icons/clear.svg";
import expandIcon from "../assets/icons/expand.svg";

const MessageHeader = ({ expand, onClearClick, onChatExpandClick }) => (
  <div className="message-header">
    <div className="header-title">
      {I18n.t("chat.title")}
    </div>
    <div className="header-actions">
      <ReactSVG
        className="icon"
        src={clearIcon}
        onClick={onClearClick}
      />
      {expand ? (
        <ReactSVG
          className="icon"
          src={expandIcon}
          onClick={onChatExpandClick}
        />
      ) : null}
    </div>
  </div>
);

export default MessageHeader;
