import React, { forwardRef } from "react";

import undo from "../../assets/icons/editor/undo.svg";
import redo from "../../assets/icons/editor/redo.svg";
import headingOne from "../../assets/icons/editor/heading-one.svg";
import headingTwo from "../../assets/icons/editor/heading-two.svg";
import alignLeft from "../../assets/icons/editor/align-left.svg";
import alignCenter from "../../assets/icons/editor/align-center.svg";
import alignRight from "../../assets/icons/editor/align-right.svg";
import alignJustify from "../../assets/icons/editor/align-justify.svg";
import bold from "../../assets/icons/editor/bold.svg";
import italic from "../../assets/icons/editor/italic.svg";
import underline from "../../assets/icons/editor/underline.svg";
import strikethrough from "../../assets/icons/editor/strikethrough.svg";
import code from "../../assets/icons/editor/code.svg";
import clearFormatting from "../../assets/icons/editor/clear-formatting.svg";
import quotes from "../../assets/icons/editor/quotes.svg";
import bulletedList from "../../assets/icons/editor/bulleted-list.svg";
import numberedList from "../../assets/icons/editor/numbered-list.svg";

const EditorIcons = {
  "undo": undo,
  "redo": redo,
  "heading-one": headingOne,
  "heading-two": headingTwo,
  "align-left": alignLeft,
  "align-center": alignCenter,
  "align-right": alignRight,
  "align-justify": alignJustify,
  "bold": bold,
  "italic": italic,
  "underline": underline,
  "strikethrough": strikethrough,
  "code": code,
  "clear-formatting": clearFormatting,
  "quotes": quotes,
  "bulleted-list": bulletedList,
  "numbered-list": numberedList,
};

const Icon = forwardRef(({ icon, className, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={`${className} editor-icon`}
  >
    <img src={EditorIcons[icon]} alt={icon} />
  </span>
));

export default Icon;
