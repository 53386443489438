import React, { useState, useEffect } from "react";
import I18n from "i18n-js";

import Selector from "./Selector";

const PdpSelector = ({ selectedTeam, teams, isTeamLeadOrAbove, personal }) => {
  const team = selectedTeam || teams[0];
  const locale = I18n.locale;

  const [coachList, setCoachList] = useState([]);
  const [coachProList, setCoachProList] = useState([]);

  useEffect(() => {
    const coachList = teams.filter(team => !team.pro).map(team => ({
      title: team.name,
      url: getTeamPdpUrl(team),
    }));

    const coachProList = teams.filter(team => team.pro).map(team => ({
      title: team.name,
      url: getTeamPdpUrl(team),
    }));

    [coachList, coachProList].forEach(list => {
      list.push({
        title: I18n.t("pdp.selector.add_new_team"),
        url: `/plans?locale=${locale}`,
        className: "add-new-team"
      });
    });
    
    if (isTeamLeadOrAbove) {
      [coachList, coachProList].forEach(list => {
          list.push({
            title: I18n.t("pdp.selector.manage_teams"),
            url: `/teams?locale=${locale}`,
            className: "manage-teams"
          });
      });
    }

    setCoachList(coachList);
    setCoachProList(coachProList);
  }, [teams]);

  const getTeamPdpUrl = (team) => `/teams/${team.uuid}/pdp?locale=${locale}`;

  return (
    <Selector
      className="pdp-selector"
      title={personal ? I18n.t("pdp.selector.personal_pdp") : team.name}
      items={[
        {
          title: I18n.t("pdp.selector.personal_pdp"),
          url: `/pdp/personal?locale=${locale}`
        },
        {
          title: I18n.t("pdp.selector.team_coach"),
          submenu: coachList
        },
        {
          title: I18n.t("pdp.selector.team_coach_pro"),
          submenu: coachProList
        }
      ]}
    />
  );
}

export default PdpSelector;
