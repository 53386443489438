import React from "react";
import I18n from "i18n-js";

import Note from "./Note";

const Notes = ({ notes, note, searchTerm }) => (
  <div className="notes">
    <div className="title">{I18n.t("notebook.notes")}</div>
    <div className="note-list">
      {notes.map(n => (
        <Note
          key={n.id}
          note={n}
          selected={note.id === n.id}
          searchTerm={searchTerm}
        />
      ))}
    </div>
  </div>
);

export default Notes;
