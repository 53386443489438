import React from "react";
import I18n from "i18n-js";

import Page from "./components/Page";
import IntegrationsForm from "./components/IntegrationsForm";

const Integrations = ({ user, integrations }) => {
  return (
    <Page
      className="integrations-page"
      title={I18n.t("settings.pages.integrations.title")}
      subtitle={I18n.t("settings.pages.integrations.subtitle")}
      user={user}
    >
      <IntegrationsForm user={user} integrations={integrations} />
    </Page>
  );
}

export default Integrations;
