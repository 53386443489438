import { Image } from "konva";

import { loadImage } from "../../services/image";

import endIcon from "../../../assets/icons/pdp/plan-end.svg";

const indicatorWidth = 39;
const indicatorHeight = 24;

const EndIndicator = async ({ x, y }) => {
  try {
    const icon = await loadImage(endIcon);

    const image = new Image({
      x: x - 10,
      y: y - 5,
      width: indicatorWidth,
      height: indicatorHeight,
      image: icon,
      perfectDrawEnabled: true,
      listening: false,
    });

    return image;
  } catch (error) {
    console.error("Error loading icon: ", error);
  }
};

export default EndIndicator;
