import React, { forwardRef } from "react";

import MenuItem from "./MenuItem";

const Dropdown = forwardRef(({ show, submenu, depthLevel = 1 }, ref) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul ref={ref} className={`dropdown ${dropdownClass} ${show ? "show" : ""}`}>
      {submenu && submenu.map((submenu, index) => (
        <MenuItem key={index} item={submenu} depthLevel={depthLevel} />
      ))}
    </ul>
  );
});

export default Dropdown;
