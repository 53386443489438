import React from "react";

import { parseUrl } from "../../services/url";

const Element = ({ attributes, children, element }) => {

  const style = { textAlign: element.align };

  const splitHeader = () => {
    const rows = children;
    
    if (!element.header || !rows || !rows.length || rows.length === 1) {
      return { header: null, rows };
    }

    return { header: rows[0], rows: rows.slice(1) };
  }

  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "table":
      const { header, rows } = splitHeader();
      return (
        <table>
          {element.header ? (
            <thead {...attributes}>{header}</thead>
          ) : null}
          <tbody {...attributes}>{rows}</tbody>
        </table>
      );
    case "table-row":
      return <tr {...attributes}>{children}</tr>;
    case "table-cell":
      return element.header ? (
        <th style={style} {...attributes}>{children}</th>
      ) : (
        <td style={style} {...attributes}>{children}</td>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "link":
      const url = parseUrl(element.url);
      return (
        <a style={style} {...attributes} href={url.href} target="_blank">
          {children}
        </a>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
}

export default Element;
