import React, { useState, useRef, useEffect } from "react";
import I18n from "i18n-js";

import Dropdown from "../../commons/Dropdown";

import dropdownIcon from "../../assets/icons/dropdown-arrow.svg";

const NotebookSelector = ({ selectedTeam, teams, personal, isNewPage }) => {
  const team = selectedTeam || teams[0];
  const locale = I18n.locale;

  const [showDropdown, setShowDropdown] = useState(false);
  const [coachList, setCoachList] = useState([]);
  const [coachProList, setCoachProList] = useState([]);

  const ref = useRef();
  
  useEffect(() => {
    const handler = (event) => {
      if (showDropdown && ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showDropdown]);

  useEffect(() => {
    const coachList = teams.filter(team => !team.pro).map(team => ({
      title: team.name,
      url: getTeamNotebookUrl(team),
    }));

    const coachProList = teams.filter(team => team.pro).map(team => ({
      title: team.name,
      url: getTeamNotebookUrl(team),
    }));

    setCoachList(coachList);
    setCoachProList(coachProList);
  }, [teams]);

  const getTeamNotebookUrl = (team) => isNewPage ?
    `/notebook/new?team_uuid= ${team.uuid}&locale=${locale}` :
    `/teams/${team.uuid}/notebook?locale=${locale}`;

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  }

  return (
    <div className="notebook-selector show" ref={ref}>
      <button
        className="button"
        aria-haspopup="true"
        aria-expanded={showDropdown ? "true" : "false"}
        onClick={toggleDropdown}
      >
        <img className="icon" src={dropdownIcon} alt="dropdown icon" />
        {personal
          ? I18n.t("notebook.selector.notebook", {
              team: I18n.t("notebook.selector.personal_notebook")
            })
          : I18n.t("notebook.selector.notebook", { team: team.name })
        }
      </button>
      <Dropdown
        show={showDropdown}
        submenu={[
          {
            title: I18n.t("notebook.selector.personal_notebook"),
            url: isNewPage ?
              `/notebook/new?personal=true&locale=${locale}` :
              `/notebook/personal?locale=${locale}`
          },
          {
            title: I18n.t("notebook.selector.team_coach"),
            submenu: coachList
          },
          {
            title: I18n.t("notebook.selector.team_coach_pro"),
            submenu: coachProList
          }
        ]}
      />
    </div>
  );
}

export default NotebookSelector;
