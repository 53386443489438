import React from "react";
import I18n from "i18n-js";

import useStore from "../../store";

const WelcomeMessage = ({ firstName }) => {

  const { nextStep } = useStore(state => state.teamStage);

  const next = (event) => {
    event.preventDefault();
    nextStep();
  };

  return (
    <div className="welcome-section">
      <h3>{I18n.t("team_stage.welcome")}, {`${firstName}`}!</h3>
      <p>{I18n.t("team_stage.welcome_message")}</p>
      <span style={{ display: "block" }}>{I18n.t("team_stage.see_you")}</span>
      <button className="begin-btn" onClick={next}>
        {I18n.t("team_stage.begin")}
      </button>
    </div>
  );
}

export default WelcomeMessage;