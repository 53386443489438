import React, { Component, Fragment, createRef } from "react";
import { Button, Row, Col, Form, Alert, Collapse } from "react-bootstrap";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../api";

import {
  NotCoreLogicRoles,
  RolesChipGroupItems
} from "../../config/roles";

import {
  ChangeMemberRolePermission,
  ChangeMemberStatusPermission
} from "../../services/permission";

import InternalModal from "../../commons/InternalModal";
import ChipGroup from "../../commons/ChipGroup";

import googleLogo from "../../assets/images/integrations/google.png";
import microsoftLogo from "../../assets/images/integrations/microsoft.png";
import slackLogo from "../../assets/images/integrations/slack.svg";

const IntegrationIcons = {
  google: googleLogo,
  slack: slackLogo,
  ms_graph: microsoftLogo
};

export default class UserEdit extends Component {

  statusRef = createRef();

  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      role: "",
      status: "",
      canSubmit: false,
      showTeamLeadWarning: false,
      showStatusWarning: false,
    };
  }

  updateUserInfo = (event) => {
    event.preventDefault();

    const { first_name, last_name, role, status } = this.state;
    const { currentTeam, selectedUser } = this.props;

    let userData = null;
    let roleData = null;
    let statusData = null;

    if (first_name.length > 1 || last_name.length > 1) {
      userData = {
        "team_uuid": currentTeam.uuid
      };
    }

    if (first_name.length > 1) {
      userData["first_name"] = first_name;
    }

    if (last_name.length > 1) {
      userData["last_name"] = last_name;
    }

    if (role.length > 1) {
      roleData = { role: role };
    }

    if (status !== '') {
      statusData = { active: status };
    }

    let requests = [];

    if (userData !== null) {
      requests.push({
        url: `/users/${selectedUser.uuid}.json`,
        method: "put",
        data: userData
      });
    }

    if (roleData !== null) {
      requests.push({
        url: `/teams/${currentTeam.uuid}/users/${selectedUser.uuid}/role`,
        method: "patch",
        data: roleData
      });
    }

    if (statusData !== null) {
      requests.push({
        url: `/teams/${currentTeam.uuid}/users/${selectedUser.uuid}/status`,
        method: "patch",
        data: statusData
      });
    }

    Promise.all(requests.map(req => api.request({ ...req })))
      .then(res => {
        toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
        const response = res[0] || res;
        console.log(response);
        if (response.data && response.data.redirectTo) {
          setTimeout(() => {
            window.location.assign(response.data.redirectTo);
          }, 400);
        }
      })
      .catch(err => {
        const error = err[0] || err;
        if (error.response && error.response.status === 422 &&
            error.response.data &&
            error.response.data.message !== I18n.t("common.errors.invalid_role")) {
          toast.error(<b>{I18n.t("common.errors.invalid_data")}</b>);
        } else if (error.response && error.response.data && error.response.data.message) {
          toast.error(<b>{error.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(error);
      });
  };

  handleChange = (event, checkbox = false) => {
    const { value, checked, name } = event.target;
    
    if (!checkbox) {
      if (event.preventDefault) event.preventDefault();
    }

    let attributes = {
      [name]: checkbox ? checked : value
    };
    
    if (!this.state.canSubmit) {
      attributes["canSubmit"] = true;
    }
    
    if (name === "role" && value === "team_lead") {
      attributes["showTeamLeadWarning"] = true;
      attributes["canSubmit"] = false;
    }

    if (name === "role" && value !== "team_lead") {
      attributes["showTeamLeadWarning"] = false;
      attributes["canSubmit"] = true;
    }

    if (name === "status" && checked === false) {
      attributes["showStatusWarning"] = true;
      attributes["canSubmit"] = false;
    }

    this.setState(attributes);
  };

  onTeamLeadWarningClose(cancel) {
    let attributes = {
      showTeamLeadWarning: false,
      canSubmit: false,
    };

    if (!cancel) {
      attributes["role"] = "team_lead";
      attributes["canSubmit"] = true;
    }

    this.setState(attributes);
  }

  onStatusWarningClose(cancel) {
    let attributes = {
      showStatusWarning: false,
      canSubmit: false,
      status: true
    };

    if (this.statusRef.current) {
      this.statusRef.current.checked = cancel;
    }

    if (!cancel) {
      attributes["status"] = false;
      attributes["canSubmit"] = true;
    }

    this.setState(attributes);
  }

  onEditCancel() {
    const { hideUserEdit } = this.props;

    this.onTeamLeadWarningClose(true);
    this.onStatusWarningClose(true);
    hideUserEdit(true);
  }

  resetUserIntegrations = (event, provider) => {
    event.preventDefault();

    const { selectedUser } = this.props;

    const config = { headers: { "Content-Type": "application/json" } };

    axios
      .delete(
        `/users/${selectedUser.uuid}/${provider}.json`,
        config
      )
      .then((response) => {
        const { messages, redirectTo, status } = response.data;

        console.log(response)

        if (status === 422) {
          {
            Object.entries(messages).map(
              ([key, value]) => (errors[key] = value)
            );
          }
          setErrorMessages(errors);
        } else {
          window.location.assign(redirectTo);
        }
      });
  };

  renderSocialOauth(social_oauths) {
    const { role_id } = this.props.selectedUser;

    const showIntegrationsSection = !NotCoreLogicRoles.includes(role_id);

    if (showIntegrationsSection && social_oauths && social_oauths.length) {
      return (
        <Fragment>
          <Col className="mb-2 subtitle">{I18n.t("user.integrations")}</Col>
          {social_oauths.map(integration => (
            <div key={integration.id} className="integration">
              <img
                className={integration.provider}
                src={IntegrationIcons[integration.provider]}
                alt="integration icon"
              />
              {integration.email_address !== null && (
                <Fragment>
                  <div className="divider" />
                  <div className="email">
                    {integration.email_address}
                  </div>
                </Fragment>
              )}
              {integration.provider === "slack" && (
                <Fragment>
                  <div className="divider" />
                  <div className="slack-user-id">
                    ID: {integration.slack_user_id}
                  </div>
                </Fragment>
              )}
              <div className="divider" />
              <div
                className="integration-btn"
                onClick={(event) => this.resetUserIntegrations(event, integration.provider)}
              >
                {I18n.t("user.reset_provider")}
              </div>
            </div>
          ))}
        </Fragment>
      );
    }
  }

  renderStatusSection() {
    const { selectedUser, loggedUser } = this.props;

    return (
      <Fragment>
        <Row className="flex-column mb-4">
          <Col className="mb-2 subtitle">{I18n.t("user.status")}</Col>
          <Col className="d-flex align-items-center">
            <small
              className="flex-grow-1"
              dangerouslySetInnerHTML={{
                __html: I18n.t("user.disclaimers.update_status.content", { interpolation: { escapeValue: false }})
              }}
            />
            <Form.Check
              id="status"
              name="status"
              type="checkbox"
              className="custom-switch custom-switch-sm switch"
            >
              <Form.Check.Input
                ref={this.statusRef}
                name="status"
                className="custom-switch-input"
                type="checkbox"
                defaultChecked={selectedUser.active}
                disabled={!ChangeMemberStatusPermission.canChangeUserStatus(loggedUser, selectedUser)}
                onChange={(event) => this.handleChange(event, true)}
              />
              <Form.Check.Label className="custom-switch-btn" htmlFor="status" />
              <div className="custom-switch-content-checked">
                {I18n.t("common.active")}
              </div>
              <div className="custom-switch-content-unchecked">
                {I18n.t("common.inactive")}
              </div>
            </Form.Check>
          </Col>
        </Row>
        <Row>
          {this.renderStatusWarning()}
        </Row>
      </Fragment>
    );
  }

  renderStatusWarning() {
    return (
      <Alert
        variant="danger"
        transition={Collapse}
        show={this.state.showStatusWarning}
        className="col-12"
      >
        <Alert.Heading>{I18n.t("user.warnings.inactivate_user.title")}</Alert.Heading>
        <p
          dangerouslySetInnerHTML={{
            __html: I18n.t("user.warnings.inactivate_user.content", { interpolation: { escapeValue: false }})
          }}
        />
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-secondary"
            onClick={() => this.onStatusWarningClose(true)}
          >
            {I18n.t("user.cancel")}
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => this.onStatusWarningClose(false)}
          >
            {I18n.t("user.continue")}
          </Button>
        </div>
      </Alert>
    );
  }

  renderTeamLeadWarning() {
    return (
      <Alert
        variant="danger"
        transition={Collapse}
        show={this.state.showTeamLeadWarning}
      >
        <Alert.Heading>{I18n.t("user.warnings.update_team_leader.title")}</Alert.Heading>
        <p
          dangerouslySetInnerHTML={{
            __html: I18n.t("user.warnings.update_team_leader.content", { interpolation: { escapeValue: false }})
          }}
        />
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-secondary"
            onClick={() => this.onTeamLeadWarningClose(true)}
          >
            {I18n.t("user.cancel")}
          </Button>
          <Button
            variant="outline-danger"
            onClick={() => this.onTeamLeadWarningClose(false)}
          >
            {I18n.t("user.continue")}
          </Button>
        </div>
      </Alert>
    );
  }

  renderForm() {
    const { selectedUser, loggedUser } = this.props;
    const { first_name, last_name, email, role, social_oauths } = selectedUser;

    const roles = RolesChipGroupItems().map(value => ({
      ...value,
      selected: value.label === role
    }));

    return (
      <Fragment>
        <h4 className="mb-4">{I18n.t("user.edit_team_member")}</h4>
        <Form>
          <p className="d-flex flex-column">
            {I18n.t('user.role')}
            <small
              dangerouslySetInnerHTML={{
                __html: I18n.t("user.disclaimers.update_role.content", { interpolation: { escapeValue: false }})
              }}
            />
          </p>
          <Row className="mb-2">
            <Col>
              <ChipGroup
                values={roles}
                editable={ChangeMemberRolePermission.canChangeUserRole(loggedUser, selectedUser)}
                disabledValues={ChangeMemberRolePermission.disabledRolesForUser(loggedUser)}
                onChipSelected={(value) =>
                  this.handleChange({ target: { name: "role", value: value }})
                }
              />
            </Col>
          </Row>
          <Row>
            {this.renderTeamLeadWarning()}
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="formBasicFirstName">
                <Form.Control
                  size="lg"
                  name="first_name"
                  placeholder="First Name"
                  defaultValue={first_name}
                  onChange={(event) => this.handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formBasicLastName">
                <Form.Control
                  size="lg"
                  name="last_name"
                  placeholder="Last Name"
                  defaultValue={last_name}
                  onChange={(event) => this.handleChange(event)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  size="lg"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  defaultValue={email}
                  onChange={(event) => this.handleChange(event)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        {this.renderStatusSection()}
        <Row className="flex-column mb-4">
          {this.renderSocialOauth(social_oauths)}
        </Row>
        <Col className="d-flex edit-actions">
          <Row className="pl-3">
            <Button
              variant="secondary"
              className="d-flex align-items-center mr-3"
              onClick={() => this.onEditCancel()}
            >
              {I18n.t("user.cancel")} 
            </Button>

            <Button
              disabled={!this.state.canSubmit}
              variant="primary"
              type="submit"
              onClick={(event) => this.updateUserInfo(event)}
            >
              {I18n.t("user.save")} 
            </Button>
          </Row>
        </Col>
      </Fragment>
    );
  }

  render() {
    const { isModalOpen } = this.props;

    return (
      <InternalModal
        type="user-edit"
        isModalOpen={isModalOpen}
        hideModal={() => this.onEditCancel()}
        component={this.renderForm()}
      />
    );
  }
}
