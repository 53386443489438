import React from "react";

const FormSection = ({ title, description, children }) => {
  return (
    <div className="form-section">
      {title ? <div className="title">{title}</div> : null}
      {description ? <div className="description">{description}</div> : null}
      <div className="children">{children}</div>
    </div>
  );
}

export default FormSection;
