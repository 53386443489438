import React, { Component } from "react";
import _, { uniqueId } from "lodash";

import Chip from "./Chip";

export default class ChipGroup extends Component {

  constructor(props) {
    super(props);

    const values = props.values.map(value => ({
      id: uniqueId(),
      ...value
    }));

    this.state = {
      values
    };
  }

  onChipSelected = (value) => {
    const { onChipSelected } = this.props;
    const { values } = this.state;

    const newValues = values.map(v => ({
      ...v,
      selected: v.id === value.id
    }));

    this.setState({ values: newValues });

    onChipSelected && onChipSelected(value.value);
  }

  renderChip = (index, value, clickable, cursor) => (
    <Chip
      key={index}
      label={value.label}
      selected={value.selected}
      clickable={clickable}
      onClick={clickable ? () => this.onChipSelected(value) : null}
      style={{ cursor }}
    />
  );

  render() {
    const { editable, disabledValues } = this.props;
    const { values } = this.state;

    return (
      <div className="chip-group">
        {values.map((value, index) => {
          const disabled = _.includes(disabledValues, value.value);

          let cursor = disabled ? "not-allowed" : "pointer";
          cursor = !editable || value.selected ? "default" : cursor;
          
          const clickable = !disabled && editable;

          return this.renderChip(index, value, clickable, cursor);
        })}
      </div>
    );
  }
};

ChipGroup.defaultProps = {
  values: [],
  disabledValues: [],
  editable: true,
};