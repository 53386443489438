import React from "react";

import Timeline from "./Timeline";

const ObjectiveTimeline = ({ objective }) => {

  return (
    <div className="objective-timeline">
      <span className="section-title">Timeline</span>
      <Timeline
        objective={objective}
        start={objective?.started_at}
        end={objective?.completed_at || objective?.target_date}
        tasks={objective?.tasks}
      />
    </div>
  );
}

export default ObjectiveTimeline;
