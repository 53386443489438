import React, { useEffect, useState, useRef } from "react";
import { ReactSVG } from "react-svg";
import _ from "lodash";

import dropdownIcon from "../assets/icons/dropdown-arrow.svg";

const SelectInput = ({
  className,
  id,
  label,
  placeholder,
  options,
  initialValue,
  error,
  disabled,
  slim,
  onValueSelected
}) => {
  const finalId = id || _.uniqueId();
  const initialOption = options.find(option => option.value === initialValue);

  const [value, setValue] = useState(initialOption);
  const [showItems, setShowItems] = useState(false);

  const ref = useRef();
  const buttonRef = useRef();
  const dropdownRef = useRef();

  useEffect(() => {
    setValue(initialOption);
  }, [initialValue]);

  useEffect(() => {
    onValueSelected && onValueSelected(value.value);
  }, [value]);

  useEffect(() => {
    const handler = (event) => {
      if (showItems &&
          ref.current &&
          !ref.current.contains(event.target)) {
        setShowItems(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showItems, setShowItems]);

  useEffect(() => {
    const dropdownHeight = dropdownRef.current.clientHeight + 65;
    const windowHeight = window.innerHeight;
    const dropdownTop = buttonRef.current.getBoundingClientRect().top;

    if (windowHeight - dropdownTop < dropdownHeight) {
      dropdownRef.current.style.bottom = "100%";
      dropdownRef.current.style.top = "auto";
    } else {
      dropdownRef.current.style.bottom = "auto";
      dropdownRef.current.style.top = "100%";
    }

    dropdownRef.current.style.opacity = showItems ? "1" : "0";
  }, [showItems]);

  const handleInputClick = (event) => {
    if (disabled) return;
    event.preventDefault();
    setShowItems(show => !show);
  }

  const handleItemClick = (option) => {
    if (disabled) return;
    setValue(option);
    setShowItems(false);
  }

  return (
    <div ref={ref} className={`${className} select-input`}>
      {label ? <label htmlFor={finalId}>{label}</label> : null}
      <div
        ref={buttonRef}
        id={finalId}
        className={`input ${error ? "input-error" : ""} ${showItems ? "open" : ""} ${disabled ? "disabled" : ""}`}
        aria-haspopup="true"
        aria-expanded={showItems ? "true" : "false"}
        onClick={handleInputClick}
      >
        <div className={`title ${value ? "" : "placeholder"}`}>
          {value ? value.label : placeholder}
        </div>
        <ReactSVG className="icon" src={dropdownIcon} />
      </div>
      <ul ref={dropdownRef} className={`dropdown-items ${showItems ? "open" : ""}`}>
        {options.map((option, index) => {
          const selected = value && value.value === option.value;
          return (  
            <li
              key={index}
              className={`item ${selected ? "selected" : ""}`}
              onClick={() => handleItemClick(option, selected)}
            >
              <span className="title">{option.label}</span>
            </li>
          );
        })}
      </ul>
      {!slim ? (
        <div className="error">
          {error}
        </div>
      ) : null}
    </div>
  );
}

export default SelectInput;
