import React, { useEffect, useRef, useState } from "react";

import Slider from "./Slider";
import ChatMessage from "./ChatMessage";

const Chat = ({ user }) => {
  const [sliderOpen, setSliderOpen] = useState(false);
  
  const supportButtonRef = useRef(null);

  const backdropClass = sliderOpen ? "backdrop open" : "backdrop";

  useEffect(() => {
    if (supportButtonRef.current === null) {
      supportButtonRef.current = document.getElementById("launcher-frame");
    }

    if (sliderOpen) {
      document.body.style.overflowY = "hidden";
      if (supportButtonRef.current) {
        supportButtonRef.current.style.visibility = "hidden";
      }
    }

    return () => {
      document.body.style.overflowY = "scroll";
      if (supportButtonRef.current) {
        supportButtonRef.current.style.visibility = "visible";
      }
    }
  }, [sliderOpen]);

  const handleSliderToggled = () => {
    setSliderOpen(!sliderOpen);
  }

  return (
    <div className="slider-container">
      <div className={backdropClass}></div>
      <Slider isOpen={sliderOpen} onToggled={handleSliderToggled}>
        <ChatMessage user={user} />
      </Slider>
    </div>
  );
}

export default Chat;
