import React from "react";
import ProgressBar from "react-customizable-progressbar";
import I18n from "i18n-js";

import { assignCardVariables } from "../../commons/scoreCardHandling";

import Card from "./Card";

const scores = {
  forming: 0,
  storming: 0.5,
  norming: 0.75,
  performing: 1.0
};

const IndividualTeamStage = ({ stage }) => {

  const score = scores[stage];
  
  const { cssColor: color } = assignCardVariables("Team Stage", score);

  const getStageTitle = () => I18n.t(`dashboard.${stage}`);

  const getNormalizedScore = () => score * 100;

  const renderScoreProgressBar = () => {
    return (
      <ProgressBar
        radius={75}
        progress={getNormalizedScore()}
        cut={180}
        rotate={180}
        strokeWidth={7}
        strokeColor={color}
        transition=".3s ease"
        trackStrokeWidth={7}
        trackTransition=".0s"
        pointerRadius={6} 
        pointerStrokeWidth={3}
        pointerFillColor="#ffffff"
        pointerStrokeColor={color}
        initialAnimation
        initialAnimationDelay={10}
        className="stage-score-progress-bar"
      />
    );
  };

  const renderStage = () => {
    return <div className="stage-title">{getStageTitle()}</div>;
  };

  return (
    <div className="individual-team-stage-section">
      <Card
        title={I18n.t('dashboard.team_stage')}
        content={
          <div className="section-content">
            {I18n.t("dashboard.currently_in")}
            {renderScoreProgressBar()}
            {renderStage()}
            <span className="link" onClick={() => {}}>
              {I18n.t("dashboard.ask_vicky_improvement")}
            </span>
          </div>
        }
      />
    </div>
  );
}

export default IndividualTeamStage;