import React, { useState } from "react";
import { Button, Alert, Collapse } from "react-bootstrap";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../../api";

import InternalModal from "../../../commons/InternalModal";

const DeleteTaskConfirmation = ({ plan, objective, task, show, onCancel, onConfirm }) => {

  const [loading, setLoading] = useState(false);

  const deleteTask = () => {
    setLoading(true);

    api.delete(`/development_plans/${plan?.uuid}/objectives/${objective?.uuid}/tasks/${task?.uuid}`)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          toast.success(<b>{I18n.t("common.deleted_successfully")}</b>);
          window.location.reload();
          //resetState();
          //onSave && onSave();
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const reset = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const handleCancel = () => {
    onCancel && onCancel();
    reset();
  }

  const renderConfirmation = () => {
    return (
      <Alert
        show
        variant="danger"
        transition={Collapse}
        className="mb-0"
      >
        <Alert.Heading>
          {I18n.t("pdp.confirmations.delete_task.title")}
        </Alert.Heading>
        <br/>
        <p>{I18n.t("pdp.confirmations.delete_task.content")}</p>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-secondary"
            disabled={loading}
            onClick={handleCancel}
          >
            {I18n.t("common.cancel")}
          </Button>
          <Button
            variant="outline-danger"
            disabled={loading}
            onClick={deleteTask}
          >
            {I18n.t("common.continue")}
          </Button>
        </div>
      </Alert>
    );
  }

  return (
    <InternalModal
      type="delete-task-confirmation"
      size="md"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderConfirmation()}
      showModalBody={false}
    />
  );
}

export default DeleteTaskConfirmation;
