import React, { Component } from "react";
import { Button, Alert, Collapse } from "react-bootstrap";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../api";

import InternalModal from "../../commons/InternalModal";

export default class LeaveTeamConfirmation extends Component {

  leaveTeam = (event) => {
    event.preventDefault();

    const { team } = this.props;

    api.post(`/teams/${team.uuid}/leave`)
      .then(res => {
        toast.success(<b>{I18n.t("common.leave_successfully")}</b>);
        const response = res[0] || res;
        console.log(response);
        if (response.data && response.data.redirectTo) {
          setTimeout(() => {
            window.location.assign(response.data.redirectTo);
          }, 400);
        }
      })
      .catch(err => {
        const error = err[0] || err;
        if (error.response && error.response.status === 422 &&
            error.response.data &&
            error.response.data.message !== I18n.t("common.errors.invalid_role")) {
          toast.error(<b>{I18n.t("common.errors.invalid_data")}</b>);
        } else if (error.response && error.response.data && error.response.data.message) {
          toast.error(<b>{error.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(error);
      });
  }

  renderConfirmation() {
    const { onCancel } = this.props;
    return (
      <Alert
        show
        variant="danger"
        transition={Collapse}
        className="mb-0"
      >
        <Alert.Heading>{I18n.t("team.confirmations.leave_team.title")}</Alert.Heading>
        <br/>
        <p>{I18n.t("team.confirmations.leave_team.content")}</p>
        <p>{I18n.t("team.confirmations.leave_team.disclaimer")}</p>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-secondary"
            onClick={() => onCancel && onCancel()}
          >
            {I18n.t("common.cancel")}
          </Button>
          <Button
            variant="outline-danger"
            onClick={this.leaveTeam}
          >
            {I18n.t("common.continue")}
          </Button>
        </div>
      </Alert>
    );
  }

  render() {
    const { show, onCancel } = this.props;

    return (
      <InternalModal
        type="leave-team-confirmation"
        isModalOpen={show}
        hideModal={() => onCancel && onCancel()}
        component={this.renderConfirmation()}
        showModalBody={false}
      />
    );
  }
}
