import React from "react";

import Nav from "./Nav";

const Card = ({ title, subtitle, content, user }) => (
  <div className="card">
    <Nav user={user} />
    <div className="v-separator"/>
    <div className="content">
      <div className="header">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <div className="separator" />
      </div>
      {content}
    </div>
  </div>
);

export default Card;
