import React from "react";

const Placeholder = ({ attributes, children }) => (
  <div {...attributes} className="editor-placeholder">
    <p>
      {children}
    </p>
  </div>
);

export default Placeholder;
