import React from "react";
import I18n from "i18n-js";

const StatusIndicator = ({ status }) => {
  return (
    <div className={`status-indicator ${status}`}>
      {I18n.t(`pdp.statuses.${status}`)}
    </div>
  );
}

export default StatusIndicator;
