import { useEffect } from "react";

import useStore from "../store";

const useRoutingState = () => {
  const { setPreviousRoute } = useStore(state => state.routing);

  useEffect(() => {
    let route = window.location.pathname
    route = route.substring(route.lastIndexOf("/"));
    setPreviousRoute(route);
    console.log(`Saved previous route: ${route}`);
  }, []);
}

export default useRoutingState;
