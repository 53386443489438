import React, { Component } from "react";
import I18n from "i18n-js";
import _ from "lodash";

import { NotCoreLogicRoles } from "../../config/roles";

export default class UserStatus extends Component {
  statusLogic = () => {
    const user = this.props.user;

    const newlyInvited = user.invitation && !Boolean(user.invitation.accepted);
      
    const active = _.includes(NotCoreLogicRoles, user.role_id) ||
      (user.onboarded && user.social_oauths);
    
    const notOnboarded = !user.onboarded;
    const onboarded = user.onboarded && !user.social_oauths;
    const inactive = !user.active;

    if (newlyInvited) {
      return `${I18n.t("user.invitation_sent")}`;
    } else if (inactive) {
      return `${I18n.t("user.inactive")}`;
    } else if (active) {
      return `${I18n.t("user.active")}`;
    } else if (notOnboarded) {
      return `${I18n.t("user.not_onboarded")}`;
    } else if (onboarded) {
      return `${I18n.t("user.onboarded")}`;
    }
  };

  render() {
    return (
      <td className="font-weight-normal align-middle">{this.statusLogic()}</td>
    );
  }
}
