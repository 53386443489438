import React, { useEffect, useRef } from "react";
import { Layer, Line, Stage, Rect } from "konva";
import { animated, easings, useSpring } from "@react-spring/web";

import Timestep from "./Timestep";
import EndIndicator from "./EndIndicator";

const lineY = 30;
const minHeight = 750;
const minSpacing = 20;
const maxTimestepHeight = 64;
const debug = false;

const Timeline = ({ objective, start, end, tasks }) => {

  const containerRef = useRef();
  const stageRef = useRef();
  const layerRef = useRef();
  const nextTimestepY = useRef(0);
  const timesteps = useRef([]);

  const [anim, api] = useSpring(() => ({
    delay: 300,
    from: { opacity: 0 },
    to: [{ opacity: 1, config: { duration: 800, easing: easings.linear }}],
  }));

  useEffect(() => {
    reset();
    api.start({
      delay: 300,
      from: { opacity: 0 },
      to: [{ opacity: 1, config: { duration: 800, easing: easings.linear }}],
    });
  }, [tasks, api]);

  useEffect(() => {
    const timestepsCount = 2 + (tasks?.length || 0);
    if (debug) { console.log("Timesteps Count:", timestepsCount); }
    
    let height = 0;
    height = (timestepsCount - 1) * (maxTimestepHeight + minSpacing) + maxTimestepHeight;
    height = Math.max(height, minHeight);
    if (debug) { console.log("Height:", height); }

    const stage = new Stage({
      container: containerRef.current,
      width: 1000,
      height: height
    });
    stageRef.current = stage;

    const layer = new Layer();
    layerRef.current = layer;
    stage.add(layer);

    renderStart();
    tasks?.forEach(task => renderTimestep(task));
    renderEnd();

    const timestepsHeight = timesteps.current.reduce((total, t) => total + t.height(), 0);
    const remainingHeight = height - timestepsHeight;
    let spacing = remainingHeight / (timestepsCount - 1);
    spacing = Math.max(minSpacing, spacing);
    if (debug) {
      console.log("Timesteps Height:", timestepsHeight, "Remaining Height:", remainingHeight);
      console.log("Spacing:", spacing);
    }
    updateTimesteps(spacing);

    const lastTimestep = timesteps.current.slice(-1)[0];
    const lineHeight = lastTimestep.y() + (lastTimestep.height() / 2) - 1;

    const line = new Line({
      points: [20, lineY, 20, lineHeight],
      stroke: "#E7E9E2",
      strokeWidth: 4,
      lineCap: "round",
      lineJoin: "round",
      listening: false,
    });
    layer.add(line);
    line.moveToBottom();

    layer.draw();

    return () => {
      destroyStage();
    };
  }, [start, end, tasks]);

  const reset = () => {
    api.set({ opacity: 0 });
    destroyStage();
    resetTimestepsRenderingState();
  };
  
  const destroyStage = () => {
    if (stageRef.current) {
      stageRef.current.destroy();
    }
  }

  const resetTimestepsRenderingState = () => {
    nextTimestepY.current = 0;
    timesteps.current = [];
  };

  const handleTimestepClick = (task) => {
    const params = new URLSearchParams(window.location.search);
    params.set("objective", objective?.index);
    params.set("task", task?.index);
    window.location.search = params;
  }

  const renderTimestep = (task, indicator = null) => {
    const y = nextTimestepY.current;

    const timestep = new Timestep({
      x: 0,
      y: y,
      task: task,
      indicatorClass: indicator,
      fontFamily: "Poppins",
      clickable: task?.clickable,
      onClick: () => handleTimestepClick(task)
    });

    nextTimestepY.current = y + timestep.height() + minSpacing;

    timesteps.current.push(timestep);
    layerRef.current.add(timestep);
  };

  const updateTimesteps = (spacing) => {
    let nextY = 0;
    timesteps.current.forEach(timestep => {
      if (debug) { console.log("Timestep Y: ", nextY) }

      timestep.y(nextY);
      nextY = nextY + timestep.height() + spacing;

      if (debug) {
        renderTimestepDebug(timestep);
        renderSpacingDebug(nextY, spacing);
      }
    });
  }

  const renderStart = () => {
    const task = {
      title: "Objective Start",
      started_at: start,
      status: start ? "completed" : "not_started",
      clickable: false
    };
    renderTimestep(task);
  };

  const renderEnd = () => {
    const task = {
      title: "Objective End",
      completed_at: end,
      status: "completed",
      clickable: false
    };

    renderTimestep(task, EndIndicator);
  };

  const renderTimestepDebug = (timestep) => {
    const rect = new Rect({
      x: 41,
      y: timestep.y(),
      width: 250,
      height: timestep.height(),
      fill: "green",
    });
    layerRef.current.add(rect);
    rect.moveToBottom();
  }

  const renderSpacingDebug = (nextY, spacing) => {
    const rect = new Rect({
      x: 41,
      y: nextY - spacing,
      width: 150,
      height: spacing,
      fill: "gray",
    });
    layerRef.current.add(rect);
    rect.moveToBottom();
  }

  return (
    <animated.div className="timeline" style={anim}>
      <div ref={containerRef} />
    </animated.div>
  );
};

export default Timeline;
