import React from "react";
import I18n from "i18n-js";

import Card from "./Card";

import BackArrowIcon from "../../assets/images/arrow_forward_24px.svg";

const Page = ({ className, title, subtitle, children, user }) => {
  return (
    <div className={`${className} settings page`}>
      <div className="page-title">
        <a href="/">
          <img src={BackArrowIcon} alt="back" />
        </a>
        <span>{I18n.t("settings.title")}</span>
      </div>
      <Card
        title={title}
        subtitle={subtitle}
        content={children}
        user={user}
      />
    </div>
  );
}

export default Page;
