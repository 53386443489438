import React, { Fragment } from "react";
import { Toaster } from "react-hot-toast";
import I18n from "i18n-js";

import Page from "./components/Page";
import PersonalInfoForm from "./components/PersonalInfoForm";

const Personal = ({ user }) => {
  return (
    <Fragment>
      <Page
        className="personal-page"
        title={I18n.t("settings.pages.personal_info.title")}
        subtitle={I18n.t("settings.pages.personal_info.subtitle")}
        user={user}
      >
        <PersonalInfoForm user={user} />
        <div className="separator" />
        <div className="page-caption">
          {I18n.t("settings.pages.personal_info.caption")}
        </div>
      </Page>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

export default Personal;
