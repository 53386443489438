import React, { Fragment, useState } from "react";
import I18n from "i18n-js";
import _ from "lodash";

import SimpleGaugeGraph from "./SimpleGaugeGraph";

import InternalModal from "../../commons/InternalModal";

const TeamMembersHealth = ({ healths }) => {

  const [showModal, setShowModal] = useState(false);

  const users = healths.filter(user => !_.isEmpty(user) && user.first_name !== null);

  const handleOpenModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const renderOpenButton = () => (
    <div className="button" onClick={handleOpenModal}>
      {I18n.t("dashboard.team_member_details")}
    </div>
  );

  const renderHeader = () => (
    <div className="header">
      <div className="health-col">
        {I18n.t("dashboard.member_name")}
      </div>
      <div className="health-col">
        {I18n.t("dashboard.tm_adaptability")}
      </div>
      <div className="health-col">
        {I18n.t("dashboard.tm_collaboration")}
      </div>
      <div className="health-col">
        {I18n.t("dashboard.tm_communication")}
      </div>
      <div className="health-col">
        {I18n.t("dashboard.tm_engagement")}
      </div>
    </div>
  );

  const renderHealths = () => {
    return users.map((user, index) => {
      return (
        <div key={index} className="health-row">
          <div className="member-health">
            <div className="health-col">
              <div className="member-name">
                {`${user.first_name} ${user.last_name}`}
              </div>
            </div>
            <div className="health-col">
              <SimpleGaugeGraph
                title={I18n.t("dashboard.adaptability")}
                score={user.adaptability}
              />
            </div>
            <div className="health-col">
              <SimpleGaugeGraph
                title={I18n.t("dashboard.collaboration")}
                score={user.collaboration}
              />
            </div>
            <div className="health-col">
              <SimpleGaugeGraph
                title={I18n.t("dashboard.communication")}
                score={user.communication}
              />
            </div>
            <div className="health-col">
              <SimpleGaugeGraph
                title={I18n.t("dashboard.engagement")}
                score={user.engagement}
              />
            </div>
          </div>
        </div>
      );
    });
  }

  const renderCloseButton = () => (
    <div className="health-row">
      <div className="button" onClick={handleCloseModal}>
        {I18n.t("dashboard.close")}
      </div>
    </div>
  );

  const renderTable = () => (
    <div className="healths">
      {renderHeader()}
      {renderHealths()}
      {renderCloseButton()}
    </div>
  );

  const renderModal = () => (
    <InternalModal
      type="team-members-health"
      isModalOpen={showModal}
      hideModal={handleCloseModal}
      showModalBody={false}
      component={renderTable()}
    />
  );

  return (
    <Fragment>
      <div className="team-members-health-section">
        {renderOpenButton()}
      </div>
      {renderModal()}
    </Fragment>
  );
}

export default TeamMembersHealth;
