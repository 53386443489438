const initialSliderValue = 5;

const onboardingState = (set, get) => ({
  step: 0,
  questions: [],
  answers: [],

  nextStep: () => set(state => ({ onboarding: {
    ...state.onboarding,
    step: state.onboarding.step + 1
  }})),
  previousStep: () => set(state => ({ onboarding: {
    ...state.onboarding,
    step: state.onboarding.step - 1
  }})),

  setQuestions: (questions) => set(state => ({ onboarding: {
    ...state.onboarding,
    questions,
    answers: initAnswers(questions),
  }})),
  currentQuestion: () => {
    const state = get().onboarding;
    return state.questions.find(q => q.index === state.step);
  },

  setAnswer: (questionIndex, answer) => set(state => ({ onboarding: {
    ...state.onboarding,
    answers: state.onboarding.answers
      .map((a, index) => questionIndex === index ? { ...a, ...answer } : a)
  }})),
  currentAnswer: () => {
    const state = get().onboarding;
    return state.answers.find(a => a.index === state.step);
  },
});

const initAnswers = (questions) =>
  questions.map(q => {
    const answer = {
      index: q.index,
      type: q.type,
      name: q.name
    };

    if (q.type === "slider") {
      answer.scores = {};
      q.options.map(option => {
        answer.scores[option.title] = initialSliderValue;
      });
    }

    return answer;
  });

export default onboardingState;