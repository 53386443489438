import React from "react";

import Avatar from "../commons/Avatar";

import SaveNoteButton from "./components/SaveNoteButton";

import MessageRender from "./MessageRender";

const MessageItem = ({ id, content, role, user }) => {

  const renderAvatar = () => {
    if (role === "assistant") {
      return <Avatar />;
    }

    if (role === "user") {
      return <Avatar userName={user.name} />;
    }
  };

  const renderSaveNoteButton = () => {
    if (role === "assistant" && id) {
      return <SaveNoteButton messageId={id} />
    }
  };

  return (
    <div className={`message-item ${role}`}>
      {renderAvatar()}
      <div className="item-content">
        <MessageRender>
          {content}
        </MessageRender>
        {renderSaveNoteButton()}
      </div>
    </div>
  );
};

export default MessageItem;
