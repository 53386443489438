import React from "react";
import moment from "moment";

import Indicator from "./Indicator";

const formatDate = (date) => moment.utc(date).local().format("L");

const SimpleTimestep = ({ title, date }) => {

  const dateText = date ? formatDate(date) : "Not started";

  return (
    <div className="timestep simple">
      <div className="indicator-wrapper">
        <Indicator task={{ status: date ? "completed" : "not_started" }} />
      </div>
      <div className="card">
        <div className="info">
          <div className="basic">
            <span className="title">{title}</span>
            <span className="date">{dateText}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleTimestep;
