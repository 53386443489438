import React, { forwardRef } from "react";

const Button = forwardRef((props, ref) => {
  const { className, active, ...otherProps } = props;

  return (
    <span
      {...otherProps}
      ref={ref}
      className={`${className} editor-button ${active ? "active" : ""}`}
    />
  );
});

export default Button;
