import React, { Fragment } from "react";
import I18n from "i18n-js";
import _ from "lodash";

import chevronIcon from "../../assets/icons/chevron-right-green.svg";

const getNavItems = (user) => [
  {
    title: I18n.t("settings.navigation.profile_settings"),
    items: [
      {
        title: I18n.t("settings.pages.personal_info.navigation"),
        href: `/settings/personal?locale=${I18n.locale}`,
        active: true
      },
      {
        title: I18n.t("settings.pages.email_notifications.navigation"),
        href: `/settings/notifications?locale=${I18n.locale}`,
        active: false
      },
      {
        title: I18n.t("settings.pages.language.navigation"),
        href: `/settings/language?locale=${I18n.locale}`,
        active: true
      },
    ]
  },
  {
    title: I18n.t("settings.navigation.account_settings"),
    items: [
      {
        title: I18n.t("settings.pages.subscription.navigation"),
        href: "/users/portal",
        target: "_blank",
        active: _.isString(user.payment_account_id) && user.payment_account_id !== ""
      },
      {
        title: I18n.t("settings.pages.integrations.navigation"),
        href: `/settings/integrations?locale=${I18n.locale}`,
        active: true
      },
      {
        title: I18n.t("settings.pages.add_new_team.navigation"),
        href: `/plans?locale=${I18n.locale}`,
        active: true
      },
    ]
  }
];

const Nav = ({ user }) => {
  
  const pathname = location.pathname;
  const currentPath = pathname.slice(pathname.indexOf("/"));

  const navItems = getNavItems(user);
  const filteredNavItems = navItems.filter(nav =>
    nav.items.some(item => item.active)
  );

  return (
    <nav className="navigation">
      {filteredNavItems.map((nav, index) => {
        return (
          <Fragment key={index}>
            <span>{nav.title}</span>
            <ul>
              {nav.items.map((item, index) => {
                const selected = currentPath === item.href;
                return item.active ? (
                  <li key={index}>
                    <a
                      href={item.href}
                      target={item.target}
                      className={selected ? "selected" : null}
                    >
                      {item.title}
                      {selected ? (
                        <img className="icon" src={chevronIcon} alt="chevron" />
                      ) : null}
                    </a>
                  </li>
                ) : null;
              })}
            </ul>
          </Fragment>
        );
      })}
    </nav>
  );
}

export default Nav;
