import React, { Component, Fragment } from "react";
import toast, { Toaster } from "react-hot-toast";
import I18n from "i18n-js";

import api from "../api";

import { showErrorStyles } from "../commons/formActions";

import logo from "../assets/icons/logo/hey-vicky-white.svg";

export default class Init extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      team_lead_email: "",
      human_resources_email: "",
      errors: {
        name: "",
        team_lead_email: "",
        human_resources_email: ""
      },
      loading: false
    };
  }

  onSubmit = (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    const { team } = this.props;
    const { name, team_lead_email, human_resources_email } = this.state;

    const data = {
      name,
      team_lead_email,
      human_resources_email
    };

    api.put(`/teams/${team.uuid}/setup`, data)
      .then(res => {
        console.log(res);
        if (res.data && res.data.redirectTo) {
          window.location.assign(res.data.redirectTo);
        }
      })
      .catch(err => {
        if (err.response.data && err.response.data.messages) {
          const messages = err.response.data.messages;
          let errors = {};

          Object.entries(messages)
            .forEach(([key, value]) => (errors[key] = value));

          this.setErrorMessages(errors);

        } else if (err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        }

        this.setState({ loading: false });

        console.log(err);
      });
  }

  onInputChange = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: ""
      }
    });
  };

  setErrorMessages = (errors) => {
    this.setState({ errors: { ...this.state.errors, ...errors }});
  };

  showTeamForm = () => {
    const { loading, errors } = this.state;
    const { team } = this.props;

    return (
      <form
        className="team-form"
        onSubmit={this.onSubmit}
      >
        <h2>{I18n.t("onboarding.team_name_title")}</h2>
        <div className="form-group name">
          <input
            className={showErrorStyles(errors.name)}
            name="name"
            type="name"
            placeholder={I18n.t("onboarding.team_name")}
            defaultValue={team.name}
            disabled={loading}
            required
            onChange={this.onInputChange}
          />
          {errors.name.length > 0 && (
            <span className="error-msg">{errors.name}</span>
          )}
        </div>

        <h2>{I18n.t("onboarding.team_lead_title")}</h2>
        <div className="form-group name">
          <input
            className={showErrorStyles(errors.team_lead_email)}
            name="team_lead_email"
            type="email"
            placeholder={I18n.t("onboarding.team_lead_email")}
            disabled={loading}
            required
            onChange={this.onInputChange}
          />
          {errors.team_lead_email.length > 0 && (
            <span className="error-msg">{errors.team_lead_email}</span>
          )}
        </div>

        <h2>{I18n.t("onboarding.human_resources_title")}</h2>
        <div className="form-group name">
          <input
            className={showErrorStyles(errors.human_resources_email)}
            name="human_resources_email"
            type="email"
            placeholder={I18n.t("onboarding.human_resources_email")}
            disabled={loading}
            onChange={this.onInputChange}
          />
          {errors.human_resources_email.length > 0 && (
            <span className="error-msg">{errors.human_resources_email}</span>
          )}
        </div>
        <input
          className="btn btn-primary btn-block submit-btn"
          type="submit"
          value={I18n.t("common.continue")}
          disabled={loading}
        />
      </form>
    );
  }

  render() {
    return (
      <Fragment>
        <div className="registration-section team-init">
          <div className="background-section">
            <img className="app-logo" src={logo} />
            <div className="background-content">
              <h1>
                {I18n.t('onboarding.ready_msg')}
              </h1>
            </div>
          </div>
          <div className="form-section">
            <div className="registration-form">
              {this.showTeamForm()}
            </div>
          </div>
        </div>
        <Toaster
          position="top-right"
          reverseOrder
        />
      </Fragment>
    );
  }
}
