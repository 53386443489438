import React, { Component, Fragment, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { Toaster } from "react-hot-toast";
import I18n from "i18n-js";

import { getDashboardData } from "../services/dashboard";

import { FullAccessRoles, NotCoreLogicRoles } from "../config/roles";

import useRoutingState from "../hooks/useRoutingState";

import Card from "./components/Card";
import DashboardSelector from "./components/DashboardSelector";
import HealthSection from "./components/HealthSection";
import OverallHealth from "./components/OverallHealth";
import TeamStage from "./components/TeamStage";
import TeamHealth from "./components/TeamHealth";
import SelfReportedTeamMetrics from "./components/SelfReportedTeamMetrics";
import LeadershipHealth from "./components/LeadershipHealth";

import Blur from "../commons/Blur";
import ChatMessage from "../Chat/ChatMessage";
import EditTeamButton from "./components/EditTeamButton";
import AddMembersButton from "./components/AddMembersButton";

const Dashboard = (props) => {
  useRoutingState();

  const {
    teamHealthOverall,
    teamStage,
    confidenceLevel,
    teamHealth,
    teamMembersHealths,
    role,
    userHealth,
    trustLevel,
    managementPractice,
    team,
    user,
    teams,
    isTeamLeadOrAbove
  } = props;

  const showTopChat = useMediaQuery("screen and (max-width: 1487px)");

  const [chatExpanded, setChatExpanded] = useState(false);

  const dashboard = getDashboardData(userHealth, teamHealth);

  const canAccessTeamMemberDetails = () => {
    if (role === "team_lead" &&
        !team.team_lead_can_access_leadership_data) {
      return false;
    }

    return FullAccessRoles.includes(role);
  }

  const canAccessLeadershipMetrics = () => {
    return FullAccessRoles.includes(role);
  }

  const canAccessMyHealth = () => {
    return !NotCoreLogicRoles.includes(role);
  }

  const canAccessTeamPage = () => {
    return FullAccessRoles.includes(role);
  }

  const getDashboardCardsClassNames = () => {
    return chatExpanded
      ? "dashboard-cards chat-expanded"
      : "dashboard-cards";
  }

  const handleChatExpandClick = () => {
    setChatExpanded(!chatExpanded);
  }

  const renderDashboardSelector = () => (
    <div className="dashboard-row selector">
      <DashboardSelector
        user={user}
        teams={teams}
        selectedTeam={team}
        isTeamLeadOrAbove={isTeamLeadOrAbove}
      />
      {canAccessTeamPage() ? (
        <div className="action-buttons">
          <AddMembersButton loggedUser={user} team={team} />
          <EditTeamButton team={team} />
        </div>
      ) : null}
    </div>
  );

  const renderFirstRow = () => {
    if (chatExpanded) return null;
    return (
      <div className="dashboard-row">
        <OverallHealth score={teamHealthOverall} />
        <TeamHealth
          {...teamHealth}
          showMemberDetails={canAccessTeamMemberDetails()}
          teamMembersHealths={teamMembersHealths}
        />
      </div>
    );
  }
 
  const renderSecondRow = () => {
    if (chatExpanded) return null;
    return (
      <div className="dashboard-row">
        <TeamStage {...teamStage} />
        {canAccessMyHealth() ? (
          <HealthSection {...dashboard.my_health} />
        ) : canAccessLeadershipMetrics() ? (
          <LeadershipHealth
            trustEnvironment={trustLevel}
            managementPractice={managementPractice}
            expand
          />
        ) : (
          <SelfReportedTeamMetrics
            team={team}
            confidenceLevel={confidenceLevel}
            resources={teamHealth.resources}
            clarityOfGoals={teamHealth.clarity_goals_roles}
          />
        )}
      </div>
    );
  }

  const renderThirdRow = () => {
    if (chatExpanded) return null;
    return (
      <div className="dashboard-row">
        {canAccessMyHealth() ? (
          <Fragment>
            <SelfReportedTeamMetrics
              team={team}
              confidenceLevel={confidenceLevel}
              resources={teamHealth.resources}
              clarityOfGoals={teamHealth.clarity_goals_roles}
            />
            {canAccessLeadershipMetrics() ? (
              <LeadershipHealth
                trustEnvironment={trustLevel}
                managementPractice={managementPractice}
              />
            ) : null}
          </Fragment>
        ) : canAccessLeadershipMetrics() ? (
          <SelfReportedTeamMetrics
            team={team}
            confidenceLevel={confidenceLevel}
            resources={teamHealth.resources}
            clarityOfGoals={teamHealth.clarity_goals_roles}
          />
        ) : null}
      </div>
    );
  }

  const renderSidePanelWhenChatExpanded = () => {
    if (!chatExpanded) return null;
    return (
      <div className="dashboard-row">
        {canAccessMyHealth() ? (
          <HealthSection {...dashboard.my_health} />
        ) : (
          <TeamHealth
            {...teamHealth}
            showMemberDetails={canAccessTeamMemberDetails()}
            teamMembersHealths={teamMembersHealths}
          />
        )}
      </div>
    );
  }

  const renderChatSection = (top = false) => {
    let className = top ? "dashboard-top-chat" : "dashboard-chat";
    className = chatExpanded ? className + " chat-expanded" : className;
    return (
      <div className={className}>
        <ChatMessage
          user={user}
          team={team}
          expand={!top}  
          onChatExpandClick={handleChatExpandClick}
        />
      </div>
    );
  }

  const renderDashboardNotReadyWarning = () => (
    <div className="dashboard">
      <div className="container-fluid">
        {renderDashboardSelector()}
        <div className="dashboard-warning">
          <Card content={I18n.t("dashboard.dashboard_not_ready")} />
        </div>
      </div>
    </div>
  );

  if (!team.can_show_dashboard) {
    return renderDashboardNotReadyWarning();
  }
    
  return (
    <Fragment>
      <div className="wrapper">
        <div className={chatExpanded ? "dashboard chat-expanded" : "dashboard"}>
          <div className="container-fluid">
            {renderDashboardSelector()}
            {showTopChat ? renderChatSection(true) : null}
            <div className={chatExpanded ? "dashboard-content chat-expanded" : "dashboard-content"}>
              <div className={getDashboardCardsClassNames()}>
                {renderFirstRow()}
                {renderSecondRow()}
                {renderThirdRow()}
                {renderSidePanelWhenChatExpanded()}
              </div>
              {!showTopChat ? renderChatSection() : null}
            </div>
          </div>
        </div>
        <Blur />
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class DashboardComponent extends Component {
  render() {
    return <Dashboard {...this.props} />;
  }
}