import React from "react";
import ProgressBar from "react-customizable-progressbar";

import { assignCardVariables } from "../../commons/scoreCardHandling";

const getNormalizedScore = (score) => {
  const finalScore = (score * 100).toFixed(0);
  return Math.max(Math.min(0, finalScore), finalScore);
}

const SimpleGaugeGraph = ({ title, score }) => {

  const { cssColor: color } = assignCardVariables(title, score);
  
  const normalizedScore = getNormalizedScore(score);

  const renderScoreProgressBar = () => {
    return (
      <ProgressBar
        radius={34}
        progress={normalizedScore}
        cut={180}
        rotate={180}
        strokeWidth={7}
        strokeColor={color}
        transition=".3s ease"
        trackStrokeWidth={7}
        trackTransition=".0s"
        pointerRadius={6} 
        pointerStrokeWidth={3}
        pointerFillColor="#ffffff"
        pointerStrokeColor={color}
        initialAnimation
        initialAnimationDelay={10}
        className="gauge-graph-score-progress-bar"
      />
    );
  };

  const renderGraphDetails = () => (
    <div className="details">
      <div className="final-score">
        <div className="score">{normalizedScore}</div>
        <div className="score-percent">%</div>
      </div>
    </div>
  );

  return (
    <div className="simple-gauge-graph">
      {renderScoreProgressBar()}
      {renderGraphDetails()}
    </div>
  );
}

export default SimpleGaugeGraph;
