import React from "react";
import Progress from "react-svg-progress";
import I18n from "i18n-js";

import searchIcon from "../../assets/icons/search.svg";

const SearchField = ({ loading, value, onChange }) => {

  const handleOnChange = (event) => {
    event.preventDefault();
    onChange && onChange(event.target.value);
  }

  return (
    <div className="search-field">
      <input
        type="search"
        placeholder={I18n.t("notebook.inputs.search.placeholder")}
        value={value}
        onChange={handleOnChange}
      />
      {loading ? (
        <Progress color="#2D6159" size="17px" />
      ) : (
        <img className="icon" src={searchIcon} alt="Search" />
      )}
    </div>
  );
}

export default SearchField;
