import I18n from "i18n-js";

/**
 * This object represents all roles present in the application
 * and their order in the team members table.
 */
export const RolesOrder = {
  company_admin: 1,
  human_resources: 2,
  program_advisor: 3,
  team_lead: 4,
  user: 5,
};

/**
 * These roles do not need to onboarding, and do not participate in Core Logic.
 * This array is needed to show correctly user status.
 */
export const NotCoreLogicRoles = [
  "company_admin",
  "human_resources",
  "program_advisor",
];

/**
 * These roles have full access to dashboard.
 * Including view team leadership specific metrics and data.
 */
export const FullAccessRoles = [
  "company_admin",
  "human_resources",
  "program_advisor",
  "team_lead",
];

/**
 * Users with these roles cannot have their roles changed.
 * You must first change another user to some of these roles.
 */
export const RolesThatCannotBeChanged = [
  "company_admin",
  "program_advisor",
  "team_lead",
];

export const RolesThatCanChangeUserStatus = [
  "company_admin",
  "program_advisor",
  "human_resources",
  "team_lead"
];

export const RolesThatCanChangeTeamLeadPermissions = [
  "company_admin",
  "program_advisor",
];

export const RolesThatCanLeaveTeam = [
  "human_resources",
  "user",
];

export const RolesChipGroupItems = () => [
  {
    label: I18n.t("user.company_admin"),
    value: "company_admin"
  },
  {
    label: I18n.t("user.program_advisor"),
    value: "program_advisor"
  },
  {
    label: I18n.t("user.human_resources"),
    value: "human_resources"
  },
  {
    label: I18n.t("user.team_leader"),
    value: "team_lead"
  },
  {
    label: I18n.t("user.team_member"),
    value: "user",
    selected: true
  }
];