import React,{ useState, forwardRef, useEffect } from "react";

const Textarea = forwardRef((props, ref) => {
  const {
    onChange,
    placeholder,
    className,
    showClear,
    disable,
    children,
    rows,
    maxHeight,
    value,
    defaultValue,
    transparent,
    onClear,
    onSubmit,
    ...rest
  } = props;

  const [content, setContent] = useState(value);
  const [height, setHeight] = useState('auto');

  let classNames = "textarea";
  if (transparent) classNames += " transparent";

  useEffect(() => {
    setContent(value);
  }, [value]);

  const handleChange = (event) => {
    setHeight('auto');
    setHeight(`${event.target.scrollHeight}px`);
    setContent(event.target.value);
    onChange && onChange(event.target.value);
  }

  const handleClear = () => {
    onChange && onChange("");
    onClear && onClear();
  }

  const handleKeyPress = (event) => {
    if (event.shiftKey && event.key === "Enter") {
      setContent(content + "\n");
      event.preventDefault();
    }

    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit && onSubmit(content);
    }
  }

  return (
    <div className={`textarea-container ${className}`}>
      <div className="inner">
        <textarea
          ref={ref}
          rows={rows || "1"}
          style={{ height }}
          value={content}
          placeholder={placeholder}
          className={classNames}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          {...rest}
        />
      </div>
      {Boolean(showClear) ? (
        <Button
          className="clear"
          type="icon"
          icon="cancel"
          onClick={handleClear}
        />
      ) : null}
    </div>
  );
});

export default Textarea;
