import React, { Component, Fragment, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { Toaster } from "react-hot-toast";

import { getDashboardData } from "../services/dashboard";

import useRoutingState from "../hooks/useRoutingState";

import DashboardSelector from "./components/DashboardSelector";
import HealthSection from "./components/HealthSection";
import OverallHealth from "./components/OverallHealth";
import TeamHealth from "./components/TeamHealth";
import IndividualTeamStage from "./components/IndividualTeamStage";
import SelfReportedTeamMetrics from "./components/SelfReportedTeamMetrics";
import LeadershipHealth from "./components/LeadershipHealth";

import Blur from "../commons/Blur";
import ChatMessage from "../Chat/ChatMessage";

const Personal = (props) => {
  useRoutingState();
  
  const {
    userHealth,
    stage,
    user,
    teams,
    isTeamLeadOrAbove
  } = props;

  const showTopChat = useMediaQuery("screen and (max-width: 1487px)");

  const [chatExpanded, setChatExpanded] = useState(false);

  const dashboard = getDashboardData(userHealth);

  const getDashboardCardsClassNames = () => {
    return chatExpanded
      ? "dashboard-cards chat-expanded"
      : "dashboard-cards";
  }

  const handleChatExpandClick = () => {
    setChatExpanded(!chatExpanded);
  }

  const renderTeamHealthSection = () => {
    if (chatExpanded) return null;
    return (
      <div className="dashboard-row">
        <OverallHealth ofuscate />
        <TeamHealth ofuscate />
      </div>
    );
  }

  const renderMyHealthSection = () => (
    <div className="dashboard-row">
      {chatExpanded ? null : <IndividualTeamStage stage={stage} />}
      <HealthSection {...dashboard.my_health} />
    </div>
  );

  const renderLeadershipHealthSection = () => {
    if (chatExpanded) return null;
    return (
      <div className="dashboard-row">
        <SelfReportedTeamMetrics ofuscate />
        <LeadershipHealth ofuscate />
      </div>
    );
  }

  const renderChatSection = (top = false) => {
    let className = top ? "dashboard-top-chat" : "dashboard-chat";
    className = chatExpanded ? className + " chat-expanded" : className;
    return (
      <div className={className}>
        <ChatMessage
          user={user}
          expand={!top}
          onChatExpandClick={handleChatExpandClick}
        />
      </div>
    );
  }
    
  return (
    <Fragment>
      <div className="wrapper">
        <div className="dashboard">
          <div className="container-fluid">
            <div className="dashboard-row selector">
              <DashboardSelector
                user={user}
                teams={teams}
                isTeamLeadOrAbove={isTeamLeadOrAbove}
                personal
              />
            </div>
            {showTopChat ? renderChatSection(true) : null}
            <div className={chatExpanded ? "dashboard-content chat-expanded" : "dashboard-content"}>
              <div className={getDashboardCardsClassNames()}>
                {renderTeamHealthSection()}
                {renderMyHealthSection()}
                {renderLeadershipHealthSection()}
              </div>
              {!showTopChat ? renderChatSection() : null}
            </div>
          </div>
        </div>
        <Blur />
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class PersonalComponent extends Component {
  render() {
    return <Personal {...this.props} />;
  }
}
