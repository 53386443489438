import React from "react";
import { useSlate } from "slate-react";

import EditorService from "../../services/EditorService";

import Button from "./Button";
import Icon from "./Icon";

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();

  const isMarkActive = () => EditorService.isMarkActive(editor, format);
  const toggleMark = () => EditorService.toggleMark(editor, format);

  const handleClick = (event) => {
    event.preventDefault();
    toggleMark();
  }

  return (
    <Button
      active={isMarkActive()}
      onClick={handleClick}
    >
      <Icon icon={icon} />
    </Button>
  );
};

export default MarkButton;
