import React, { useCallback } from "react";
import { ReactSVG } from "react-svg";
import _ from "lodash";

import logo from "../assets/icons/logo/vicky.svg";

const Avatar = (props) => {
  const { src, altText, className, size, circle, userName } = props;
  
  const shouldRenderInitials = !_.isEmpty(userName);

  let classNames = "avatar";
  if (circle) classNames += " circle";
  if (shouldRenderInitials) classNames += " initials";
  classNames += size === "mini" ? " mini" : "";
  classNames += ` ${className}`;


  const getInitials = useCallback(() => {
    if (!shouldRenderInitials) return null;
    return userName
      .split(" ")
      .map(name => name[0].toUpperCase())
      .join("");
  }, [userName]);

  const renderAvatar = () => {
    if (!shouldRenderInitials) {
      if (_.isEmpty(src)) {
        return (
          <ReactSVG
            src={logo}
            alt={altText || "Vicky logo"}
            className="logo"
          />
        );
      }
      
      return <img src={src} alt={altText || "User avatar"} />;
    }
    
    return <span>{getInitials()}</span>;
  }

  return (
    <div className={classNames}>
      {renderAvatar()}
    </div>
  );
};

export default Avatar;
