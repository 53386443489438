import React from "react";
import I18n from "i18n-js";

import editIcon from "../../assets/icons/edit.svg";

const EditTeamButton = ({ team }) => {
  return (
    <a className="edit-team-button" href={`/teams/${team.uuid}/users`}>
      <span className="text">
        {I18n.t("dashboard.actions.edit_team")}
      </span>
      <img
        className="icon"
        src={editIcon}
        alt={I18n.t("dashboard.actions.edit_team")}
      />
    </a>
  );
}

export default EditTeamButton;
