import React from "react";

const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY
const STRIPE_WEBSITE_TABLE_ID = process.env.STRIPE_WEBSITE_TABLE_ID

const PlansWebsite = () => (
  <div className="plans-page">
    <stripe-pricing-table
      pricing-table-id={STRIPE_WEBSITE_TABLE_ID}
      publishable-key={STRIPE_PUBLIC_KEY}
    >
    </stripe-pricing-table>
  </div>
);

export default PlansWebsite;
