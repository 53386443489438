import React from "react";

import { getHumanDateTime } from "../../services/date";

import useStore from "../store";

const OutputHistory = () => {
  const { selectHistoryEntry, outputHistory } = useStore();

  return (
    <div className="output-history">
      <label>{I18n.t("writer.editor.output.versions.label")}</label>
      <ul>
        {outputHistory.map(entry => (
          <li key={entry.id} className={entry.selected ? "selected" : ""}>
            <button onClick={() => selectHistoryEntry(entry)}>
              {getHumanDateTime(entry.timestamp)}
              <span className="language">{entry.language}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OutputHistory;