import React from "react";
import { useSlate } from "slate-react";

import EditorService from "../../services/EditorService";

import Button from "./Button";
import Icon from "./Icon";

const ClearFormattingButton = () => {
  const editor = useSlate();

  const clearFormatting = () => EditorService.clearFormatting(editor);

  const handleClick = (event) => {
    event.preventDefault();
    clearFormatting();
  }

  return (
    <Button onClick={handleClick}>
      <Icon icon="clear-formatting" />
    </Button>
  );
}

export default ClearFormattingButton;
