import React from "react";
import ProgressBar from "react-customizable-progressbar";

import { generateRandomScore } from "../../services/dashboard";
import { assignCardVariables } from "../../commons/scoreCardHandling";

import UpdateButton from "./UpdateButton";

const getNormalizedScore = (score) => {
  const finalScore = (score * 100).toFixed(0);
  return Math.max(Math.min(0, finalScore), finalScore);
}

const GaugeGraph = ({ title, score, icon, updateLink, ofuscate, ofuscateTitle }) => {

  const finalScore = ofuscate ? generateRandomScore() : score;

  const { cssColor: color, status, message } = assignCardVariables(title, finalScore);

  const normalizedScore = getNormalizedScore(finalScore);

  const getClassName = (className) => {
    return (className === "title" && ofuscateTitle) || ofuscate
      ? `${className} blur` : className;
  }

  const renderScoreProgressBar = () => {
    return (
      <ProgressBar
        radius={75}
        progress={ofuscate ? 0 : normalizedScore}
        cut={180}
        rotate={180}
        strokeWidth={7}
        strokeColor={color}
        transition=".3s ease"
        trackStrokeWidth={7}
        trackTransition=".0s"
        pointerRadius={6} 
        pointerStrokeWidth={3}
        pointerFillColor={ofuscate ? "rgba(0, 0, 0, 0)" : "#ffffff"}
        pointerStrokeColor={ofuscate ? "rgba(0, 0, 0, 0)" : color}
        initialAnimation
        initialAnimationDelay={10}
        className="gauge-graph-score-progress-bar"
      />
    );
  };

  const renderGraphDetails = () => (
    <div className="details">
      <img src={icon} className="icon" />
      <div className={getClassName("title")}>{status}</div>
      <div className={getClassName("final-score")}>
        <div className="score">{normalizedScore}</div>
        <div className="score-percent">%</div>
      </div>
      <div className={getClassName("message")}>{message}</div>
      {!ofuscate && updateLink ? (
        <div className="update-link">
          <UpdateButton href={updateLink} />
        </div>
      ) : null}
    </div>
  );

  return (
    <div className="gauge-graph">
      {renderScoreProgressBar()}
      {renderGraphDetails()}
    </div>
  );
}

export default GaugeGraph;
