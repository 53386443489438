import React, { Component, Fragment, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDebouncedCallback } from "use-debounce";
import * as clipboard from "clipboard-polyfill";
import _ from "lodash";
import I18n from "i18n-js";

import api from "../api";

import useRoutingState from "../hooks/useRoutingState";

import RadioGroup from "../commons/RadioGroup";
import SingleValueInput from "../commons/SingleValueInput";
import MultiValueInput from "../commons/MultiValueInput";
import Input from "../commons/Input";
import Editor from "../commons/Editor";

import Header from "./components/Header";
import ResetStateButton from "./components/ResetStateButton";
import ClearSelectionsButton from "./components/ClearSelectionsButton";
import OptimizeButton from "./components/OptimizeButton";
import WordCount from "./components/WordCount";
import OutputHistory from "./components/OutputHistory";

import useStore from "./store";

const getInputOptions = () => {
  const formality = mapToLabelValue({
    "Informal": I18n.t("writer.inputs.formality.options.informal"),
    "Neutral Formality": I18n.t("writer.inputs.formality.options.neutral_formality"),
    "Formal": I18n.t("writer.inputs.formality.options.formal")
  });

  const useCase = mapToLabelValue({
    "Business": I18n.t("writer.inputs.use_case.options.business"),
    "Casual": I18n.t("writer.inputs.use_case.options.casual")
  });

  const tone = _.orderBy(mapToLabelValue({
    "Assertive": I18n.t("writer.inputs.tones.options.assertive"),
    "Optimistic": I18n.t("writer.inputs.tones.options.optimistic"),
    "Encouraging": I18n.t("writer.inputs.tones.options.encouraging"),
    "Curious": I18n.t("writer.inputs.tones.options.curious"),
    "Worried": I18n.t("writer.inputs.tones.options.worried"),
    "Cooperative": I18n.t("writer.inputs.tones.options.cooperative"),
    "Persuasive": I18n.t("writer.inputs.tones.options.persuasive"),
    "Amused": I18n.t("writer.inputs.tones.options.amused"),
    "Enthusiastic": I18n.t("writer.inputs.tones.options.enthusiastic"),
    "Regretful": I18n.t("writer.inputs.tones.options.regretful"),
    "Surprised": I18n.t("writer.inputs.tones.options.surprised"),
    "Tense": I18n.t("writer.inputs.tones.options.tense"),
    "Somber": I18n.t("writer.inputs.tones.options.somber"),
    "Reprimand": I18n.t("writer.inputs.tones.options.reprimand"),
    "Positive": I18n.t("writer.inputs.tones.options.positive"),
    "Neutral": I18n.t("writer.inputs.tones.options.neutral"),
    "Professional": I18n.t("writer.inputs.tones.options.professional"),
    "Friendly": I18n.t("writer.inputs.tones.options.friendly"),
    "Directive": I18n.t("writer.inputs.tones.options.directive"),
    "Appreciative": I18n.t("writer.inputs.tones.options.appreciative"),
    "Apologetic": I18n.t("writer.inputs.tones.options.apologetic"),
    "Concerned": I18n.t("writer.inputs.tones.options.concerned"),
    "Urgent": I18n.t("writer.inputs.tones.options.urgent"),
    "Confidential": I18n.t("writer.inputs.tones.options.confidential"),
    "Skeptical": I18n.t("writer.inputs.tones.options.skeptical")
  }), ["label"]);

  const language = _.orderBy(mapToLabelValue({
    "afrikaans": I18n.t("writer.inputs.language.options.afrikaans"),
    "albanian": I18n.t("writer.inputs.language.options.albanian"),
    "amharic": I18n.t("writer.inputs.language.options.amharic"),
    "arabic": I18n.t("writer.inputs.language.options.arabic"),
    "armenian": I18n.t("writer.inputs.language.options.armenian"),
    "assamese": I18n.t("writer.inputs.language.options.assamese"),
    "azerbaijani": I18n.t("writer.inputs.language.options.azerbaijani"),
    "basque": I18n.t("writer.inputs.language.options.basque"),
    "belarusian": I18n.t("writer.inputs.language.options.belarusian"),
    "bengali": I18n.t("writer.inputs.language.options.bengali"),
    "bosnian": I18n.t("writer.inputs.language.options.bosnian"),
    "bulgarian": I18n.t("writer.inputs.language.options.bulgarian"),
    "burmese": I18n.t("writer.inputs.language.options.burmese"),
    "cantonese": I18n.t("writer.inputs.language.options.cantonese"),
    "catalan": I18n.t("writer.inputs.language.options.catalan"),
    "chichewa": I18n.t("writer.inputs.language.options.chichewa"),
    "corsican": I18n.t("writer.inputs.language.options.corsican"),
    "croatian": I18n.t("writer.inputs.language.options.croatian"),
    "czech": I18n.t("writer.inputs.language.options.czech"),
    "danish": I18n.t("writer.inputs.language.options.danish"),
    "dutch": I18n.t("writer.inputs.language.options.dutch"),
    "english": I18n.t("writer.inputs.language.options.english"),
    "esperanto": I18n.t("writer.inputs.language.options.esperanto"),
    "estonian": I18n.t("writer.inputs.language.options.estonian"),
    "farsi": I18n.t("writer.inputs.language.options.farsi"),
    "filipino": I18n.t("writer.inputs.language.options.filipino"),
    "finnish": I18n.t("writer.inputs.language.options.finnish"),
    "french": I18n.t("writer.inputs.language.options.french"),
    "frisian": I18n.t("writer.inputs.language.options.frisian"),
    "galician": I18n.t("writer.inputs.language.options.galician"),
    "georgian": I18n.t("writer.inputs.language.options.georgian"),
    "german": I18n.t("writer.inputs.language.options.german"),
    "greek": I18n.t("writer.inputs.language.options.greek"),
    "gujarati": I18n.t("writer.inputs.language.options.gujarati"),
    "haitian_creole": I18n.t("writer.inputs.language.options.haitian_creole"),
    "hausa": I18n.t("writer.inputs.language.options.hausa"),
    "hawaiian": I18n.t("writer.inputs.language.options.hawaiian"),
    "hebrew": I18n.t("writer.inputs.language.options.hebrew"),
    "hindi": I18n.t("writer.inputs.language.options.hindi"),
    "hungarian": I18n.t("writer.inputs.language.options.hungarian"),
    "icelandic": I18n.t("writer.inputs.language.options.icelandic"),
    "igbo": I18n.t("writer.inputs.language.options.igbo"),
    "irish": I18n.t("writer.inputs.language.options.irish"),
    "italian": I18n.t("writer.inputs.language.options.italian"),
    "japanese": I18n.t("writer.inputs.language.options.japanese"),
    "javanese": I18n.t("writer.inputs.language.options.javanese"),
    "kannada": I18n.t("writer.inputs.language.options.kannada"),
    "kazakh": I18n.t("writer.inputs.language.options.kazakh"),
    "khmer": I18n.t("writer.inputs.language.options.khmer"),
    "kirghiz": I18n.t("writer.inputs.language.options.kirghiz"),
    "korean": I18n.t("writer.inputs.language.options.korean"),
    "kurdish": I18n.t("writer.inputs.language.options.kurdish"),
    "lao": I18n.t("writer.inputs.language.options.lao"),
    "latvian": I18n.t("writer.inputs.language.options.latvian"),
    "lithuanian": I18n.t("writer.inputs.language.options.lithuanian"),
    "luxembourgish": I18n.t("writer.inputs.language.options.luxembourgish"),
    "macedonian": I18n.t("writer.inputs.language.options.macedonian"),
    "malagasy": I18n.t("writer.inputs.language.options.malagasy"),
    "malay": I18n.t("writer.inputs.language.options.malay"),
    "malayalam": I18n.t("writer.inputs.language.options.malayalam"),
    "maltese": I18n.t("writer.inputs.language.options.maltese"),
    "maori": I18n.t("writer.inputs.language.options.maori"),
    "marathi": I18n.t("writer.inputs.language.options.marathi"),
    "mongolian": I18n.t("writer.inputs.language.options.mongolian"),
    "myanmar": I18n.t("writer.inputs.language.options.myanmar"),
    "nepali": I18n.t("writer.inputs.language.options.nepali"),
    "norwegian": I18n.t("writer.inputs.language.options.norwegian"),
    "oriya": I18n.t("writer.inputs.language.options.oriya"),
    "pashto": I18n.t("writer.inputs.language.options.pashto"),
    "polish": I18n.t("writer.inputs.language.options.polish"),
    "portuguese_pt": I18n.t("writer.inputs.language.options.portuguese_pt"),
    "portuguese_br": I18n.t("writer.inputs.language.options.portuguese_br"),
    "punjabi": I18n.t("writer.inputs.language.options.punjabi"),
    "romanian": I18n.t("writer.inputs.language.options.romanian"),
    "russian": I18n.t("writer.inputs.language.options.russian"),
    "samoan": I18n.t("writer.inputs.language.options.samoan"),
    "scots_gaelic": I18n.t("writer.inputs.language.options.scots_gaelic"),
    "serbian": I18n.t("writer.inputs.language.options.serbian"),
    "sesotho": I18n.t("writer.inputs.language.options.sesotho"),
    "shona": I18n.t("writer.inputs.language.options.shona"),
    "sinhala": I18n.t("writer.inputs.language.options.sinhala"),
    "slovak": I18n.t("writer.inputs.language.options.slovak"),
    "slovenian": I18n.t("writer.inputs.language.options.slovenian"),
    "somali": I18n.t("writer.inputs.language.options.somali"),
    "spanish": I18n.t("writer.inputs.language.options.spanish"),
    "sundanese": I18n.t("writer.inputs.language.options.sundanese"),
    "swahili": I18n.t("writer.inputs.language.options.swahili"),
    "swedish": I18n.t("writer.inputs.language.options.swedish"),
    "tajik": I18n.t("writer.inputs.language.options.tajik"),
    "tamil": I18n.t("writer.inputs.language.options.tamil"),
    "tatar": I18n.t("writer.inputs.language.options.tatar"),
    "telugu": I18n.t("writer.inputs.language.options.telugu"),
    "thai": I18n.t("writer.inputs.language.options.thai"),
    "turkish": I18n.t("writer.inputs.language.options.turkish"),
    "turkmen": I18n.t("writer.inputs.language.options.turkmen"),
    "ukrainian": I18n.t("writer.inputs.language.options.ukrainian"),
    "urdu": I18n.t("writer.inputs.language.options.urdu"),
    "uzbek": I18n.t("writer.inputs.language.options.uzbek"),
    "vietnamese": I18n.t("writer.inputs.language.options.vietnamese"),
    "welsh": I18n.t("writer.inputs.language.options.welsh"),
    "wu": I18n.t("writer.inputs.language.options.wu"),
    "xhosa": I18n.t("writer.inputs.language.options.xhosa"),
    "yiddish": I18n.t("writer.inputs.language.options.yiddish"),
    "yoruba": I18n.t("writer.inputs.language.options.yoruba"),
    "zulu": I18n.t("writer.inputs.language.options.zulu")
  }), ["label"]);

  return { formality, useCase, tone, language };
};

const mapToLabelValue = (options) => {
  return Object.entries(options).map(([key, value]) => ({
    label: value,
    value: key
  }));
};

const MAX_WORDS = 2000;

const GeniusWriter = () => {
  useRoutingState();

  const options = getInputOptions();

  const {
    formality,
    setFormality,
    useCase,
    setUseCase,
    tones,
    addTone,
    removeTone,
    resetTones,
    wordCount,
    setWordCount,
    language,
    setLanguage,
    resetLanguage,
    inputContent,
    setInputContent,
    outputContent,
    setOutputContent,
    addHistoryEntry,
    resetOutputContent
  } = useStore();

  const [formalityError, setFormalityError] = useState("");
  const [useCaseError, setUseCaseError] = useState("");
  const [tonesError, setTonesError] = useState("");
  const [wordCountError, setWordCountError] = useState("");

  const [loading, setLoading] = useState(false);
  const [inputCopyLoading, setInputCopyLoading] = useState(false);
  const [outputCopyLoading, setOutputCopyLoading] = useState(false);
  const [translateLoading, setTranslateLoading] = useState(false);

  const [counter, setCounter] = useState(0);

  const outputEditor = useRef();

  const addHistoryEntryDebounced = useDebouncedCallback((entry) => {
    addHistoryEntry(entry);
  }, 2000);

  const optimize = () => {
    setLoading(true);

    let data = {
      formality,
      use_case: useCase,
      tones: tones.map(tone => tone.value),
      text: inputContent
    };

    if (wordCount !== "") {
      data = { ...data, word_count: wordCount };
    }
    
    api.post("/writer", data)
      .then(res => {
        console.log(res);

        const output = res.data.output;
        resetOutputContent();
        handleOutputChange(output);
        resetLanguage();

        setTimeout(() => {
          setLoading(false);
          scrollToOutput();
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setLoading(false);
        console.error(err);
      });
  }

  const convert = async (editor, format = "html") => {
    let content = "";

    if (editor === "input") {
      content = inputContent;
    } else {
      content = outputContent;
    }

    try {
      const data = { content, format };
      const res = await api.post("/writer/converter", data);
      console.log(res);
      return res.data.output;

    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(<b>{err.response.data.message}</b>);
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }

      console.error(err);
    }
  }

  const translate = (language) => {
    if (_.isEmpty(outputContent)) { return; }
    setTranslateLoading(true);
    
    const data = {
      language,
      text: outputContent
    };
    
    api.post("/writer/translate", data)
      .then(res => {
        console.log(res);

        const output = res.data.output;
        handleOutputChange(output);

        setTimeout(() => {
          setTranslateLoading(false);
          scrollToOutput();
        }, 500);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        setTranslateLoading(false);
        console.error(err);
      });
  }

  const scrollToOutput = () => {
    if (outputEditor.current) {
      outputEditor.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  const handleInputChange = (value) => {
    setInputContent(JSON.stringify(value));
  }

  const handleInputWordCountChange = (count) => {
    setCounter(count);
  }

  const handleOutputChange = (value) => {
    const content = JSON.stringify(value);
    setOutputContent(content);
    addHistoryEntryDebounced(content);
  }

  const handleFormalitySelected = (value) => {
    setFormality(value.value);
    setFormalityError("");
  }

  const handleUseCaseSelected = (value) => {
    setUseCase(value.value);
    setUseCaseError("");
  }

  const handleOptimizeClick = (event) => {
    event.preventDefault();

    let error;

    if (!formality) {
      setFormalityError(I18n.t("writer.inputs.formality.errors.invalid"));
      error = true;
    }

    if (!useCase) {
      setUseCaseError(I18n.t("writer.inputs.use_case.errors.invalid"));
      error = true;
    }

    if (tones.length == 0) {
      setTonesError(I18n.t("writer.inputs.tones.errors.invalid"));
      error = true;
    }

    if (wordCount) {
      try {
        parseInt(wordCount);
      } catch (e) {
        setWordCountError(I18n.t("writer.inputs.word_count.errors.invalid"))
        error = true;
      }
    }

    if (!inputContent) {
      error = true;
    }

    if (error) { return; }

    optimize();
  }

  const handleToneSelected = (tone) => {
    addTone(tone);
    setTonesError("");
  }

  const handleToneRemoved = (index) => removeTone(index);

  const handleTonesReset = () => {
    resetTones();
    setTonesError("");
  }

  const handleWordCountChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setWordCount(value);
    setWordCountError("");
  }

  const handleLanguageSelected = (language) => {
    setLanguage(language);
    translate(language.value);
  }

  const handleCopyClick = async (editor) => {
    if (editor === "input") {
      setInputCopyLoading(true);
    } else {
      setOutputCopyLoading(true);
    }

    const html = await convert(editor, "html");
    const plain = await convert(editor, "plain");
    
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([html], { type: "text/html" }),
      "text/plain": new Blob([plain], { type: "text/plain" })
    });

    clipboard.write([clipboardItem])
      .then(() => {
        toast.success(<b>{I18n.t("writer.messages.copied_to_clipboard")}</b>);
      })
      .catch(error => {
        toast.error(<b>{I18n.t("writer.errors.copy_to_clipboard")}</b>);
        console.error(error);
      })
      .finally(() => {
        if (editor === "input") {
          setInputCopyLoading(false);
        } else {
          setOutputCopyLoading(false);
        }
      });
  }

  const handleClearClick = () => {
    resetOutputContent();
    resetLanguage();
  }

  const renderPageTitle = () => (
    <div className="genius-writer-row page-title">
      <span className="title">{I18n.t("writer.title")}</span>
      <ResetStateButton />
    </div>
  );

  const renderInputEditor = () => (
    <div className="input-editor">
      <div className="side-panel">
        <div className="input">
          <RadioGroup
            label={I18n.t("writer.inputs.formality.label")}
            items={options.formality}
            initialValue={formality}
            error={formalityError}
            onItemSelected={handleFormalitySelected}
          />
        </div>
        <div className="separator" />
        <div className="input">
          <RadioGroup
            label={I18n.t("writer.inputs.use_case.label")}
            items={options.useCase}
            initialValue={useCase}
            error={useCaseError}
            onItemSelected={handleUseCaseSelected}
          />
        </div>
        <div className="separator" />
        <div className="input">
          <MultiValueInput
            label={I18n.t("writer.inputs.tones.label")}
            placeholder={I18n.t("writer.inputs.tones.placeholder")}
            options={options.tone}
            maxValues={2}
            values={tones}
            error={tonesError}
            onValueSelected={handleToneSelected}
            onValueRemoved={handleToneRemoved}
          />
          {tones.length > 0 ? (
            <ClearSelectionsButton onClick={handleTonesReset} />
          ) : null}
        </div>
        {/*
        <div className="separator" />
        <div className="input">
          <Input
            type="number"
            label={I18n.t("writer.inputs.word_count.label")}
            placeholder={I18n.t("writer.inputs.word_count.placeholder")}
            value={wordCount}
            min="1"
            error={wordCountError}
            onChange={handleWordCountChange}
          />
        </div>
        */}
      </div>
      <div className="text-editor">
        <Header
          title={I18n.t("writer.editor.input.title")}
          loading={inputCopyLoading}
        />
        <Editor
          placeholder={I18n.t("writer.editor.input.placeholder")}
          value={inputContent}
          maxWords={MAX_WORDS}
          onChange={handleInputChange}
          onWordCountChange={handleInputWordCountChange}
        />
        <div className="footer">
          <div className="word-counter">
            {I18n.t("writer.editor.input.word_counter", { count: counter, max: MAX_WORDS })}
          </div>
          <OptimizeButton
            loading={loading}
            onClick={handleOptimizeClick}
          />
        </div>
      </div>
    </div>
  );

  const renderOutputEditor = () => (
    <div className="output-editor">
      <div className="text-editor">
        <Header
          title={I18n.t("writer.editor.output.title")}
          loading={outputCopyLoading}
          showCopyAction
          showClearAction
          onCopyClick={() => handleCopyClick("output")}
          onClearClick={() => handleClearClick()}
        />
        <Editor
          ref={outputEditor}
          placeholder=""
          value={outputContent}
          onChange={handleOutputChange}
        />
        <div className="footer">
          {I18n.t("writer.editor.output.disclaimer")}
        </div>
      </div>
      <div className="side-panel">
        <div className="input">
          <WordCount content={outputContent} />
        </div>
        <div className="separator" />
        <div className="input">
          <SingleValueInput
            label={I18n.t("writer.inputs.language.label")}
            placeholder={I18n.t("writer.inputs.language.placeholder")}
            options={options.language}
            loading={translateLoading}
            value={language}
            deselectable={false}
            onValueSelected={handleLanguageSelected}
          />
        </div>
        <div className="separator" />
        <OutputHistory />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="genius-writer">
        <div className="container-fluid">
          {renderPageTitle()}
          <div className="genius-writer-row content">
            {renderInputEditor()}
            {renderOutputEditor()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class GeniusWriterComponent extends Component {
  render() {
    return <GeniusWriter {...this.props} />;
  }
}
