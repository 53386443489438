import React from "react";
import { ReactSVG } from "react-svg";
import I18n from "i18n-js";

import MemberCount from "../../commons/MemberCount";

import { assignCardVariables } from "../../commons/scoreCardHandling";

import EditIcon from "../../assets/images/edit-icon.svg";

const TeamCard = ({ team }) => {
  
  const archiveColor = "grey";
  const defaultColor = "dkblue";

  const score = () => {
    return (team.health * 100).toFixed(1);
  }

  const unarchiveTeam = (event) => {
    event.preventDefault();
  };

  const renderHealth = () => {
    const { icon } = assignCardVariables("Overall Health", team.health, "overall_health");

    return (
      <div className="d-flex flex-row justify-content-between team-health">
        <div className="d-flex flex-column">
          <div className="final-score">
            {score()} <span>%</span>
          </div>
          <div className="score-message">{I18n.t("dashboard.overall_health")}</div>
        </div>
        <ReactSVG className="score-icon" src={icon} />
      </div>
    );
  }

  const renderFooter = () => {
    if (team.archived) {
      return (
        <span
          className="invisible"
          onClick={unarchiveTeam}
        >
          {I18n.t("team.unarchive")}
        </span>
      );
    } else {
      return (
        <span>
          <img src={EditIcon} className="score-icon" />
          {I18n.t("team.manage")}
        </span>
      );
    }
  }

  const renderCard = () => (
    <div className={team.archived ? "card team-card archived" : "card team-card"}>
      <div className="card-body team-card-body">
        <p className="card-title team-name">{team.name}</p>
        <div className="d-flex align-items-center member-count">
          <MemberCount
            memberCount={team.team_members_count}
            color={team.archived ? archiveColor : defaultColor}
          />
        </div>

        {renderHealth()}
      </div>

      {team.archived ? null : (
        <div className="card-footer team-footer">
          <div className="float-right manage-btn">
            {renderFooter()}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 team-card-column">
      {team.archived ? renderCard() : (
        <a className="card-link" href={`/teams/${team.uuid}/users?locale=${I18n.locale}`}>
          {renderCard()}
        </a>
      )}
    </div>
  );
}

export default TeamCard;