import React, { useEffect, useRef, useState } from "react";

import Dropdown from "../../commons/Dropdown";

import dropdownIcon from "../../assets/icons/dropdown-arrow.svg";

const Selector = ({ className, title, items }) => {

  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (showDropdown && ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showDropdown]);

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  }

  return (
    <div className={`${className} selector show`} ref={ref}>
      <button
        className="button"
        aria-haspopup="true"
        aria-expanded={showDropdown ? "true" : "false"}
        onClick={toggleDropdown}
      >
        <img className="icon" src={dropdownIcon} alt="dropdown icon" />
        <span className="title">{title}</span>
      </button>
      <Dropdown
        show={showDropdown}
        submenu={items}
      />
    </div>
  );
}

export default Selector;
