import React, { useEffect, useState } from "react";
import Progress from "react-svg-progress";
import { useDebounce } from "use-debounce";
import { toast } from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../api";

const WordCount = ({ content }) => {

  const [contentDebounced] = useDebounce(content, 1000);
  
  const [count, setCount] = useState("0");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWordCount();
  }, [contentDebounced]);

  const getWordCount = async () => {
    setLoading(true);

    try {
      const data = { content };
      const res = await api.post("/writer/counter", data);
      console.log(res);

      setTimeout(() => {
        setLoading(false);
        setCount(res.data.count);
      }, 1000);

    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(<b>{err.response.data.message}</b>);
      } else {
        toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
      }
      setLoading(false);
      console.error(err);
    }
  }

  return (
    <div className="word-count">
      <label>{I18n.t("writer.editor.output.word_count.label")}</label>
      <div className="count">
        {loading ? (
          <Progress color="#265F78" size="15px" />
        ) : (
          <span className="counter">{count > 5000 ? "5000+" : count}</span>
        )}
        <span className="title">
          {count === 1
            ? I18n.t("writer.editor.output.word_count.title.singular")
            : I18n.t("writer.editor.output.word_count.title.plural")
          }
        </span>
      </div>
    </div>
  );
}

export default WordCount;
