import { Group, Circle, Image } from "konva";

import { loadImage } from "../../services/image";

import completedIcon from "../../../assets/icons/pdp/completed.svg";

export const indicatorSize = 18;
const smallIndicatorSize = 16;

const Indicator = async ({ x, y, status }) => {

  const radius = ["completed", "in_progress"].includes(status)
    ? (indicatorSize / 2)
    : (smallIndicatorSize / 2);

  if (status === "completed") {
    try {
      const icon = await loadImage(completedIcon);
    
      const group = new Group();
  
      const background = new Circle({
        x: x + radius,
        y: y + radius,
        radius: radius,
        fill: "#FFFFFF",
        listening: false,
      });
      group.add(background);
  
      const completed = new Image({
        x: x - 0.75,
        y: y - 0.75,
        width: indicatorSize + 2,
        height: indicatorSize + 2,
        image: icon,
        listening: false,
      });
      group.add(completed);
  
      return group;
    } catch (error) {
      console.error("Error loading icon: ", error);
    }

  } else if (status === "in_progress") {
    const indicator = new Circle({
      x: x + radius,
      y: y + radius,
      radius: radius - 1,
      fill: "#78C0B6",
      stroke: "#FFFFFF",
      strokeWidth: 1,
    });
    
    return indicator;

  } else {
    const indicator = new Circle({
      x: x + radius + 1,
      y: y + radius,
      radius: radius - 1,
      fill: "#D9D9D9",
      stroke: "#FFFFFF",
      strokeWidth: 1,
    });
    
    return indicator;
  }
};

export default Indicator;
