import api from "../api"
import writerStore from "../GeniusWriter/store";

export const signout = () => {
  api.delete("/users/sign_out", { withCredentials: true })
    .then((response) => {
      console.log(response.data);
      writerStore.getState().reset();
    })
    .catch((error) => {
      console.log("error", error);
    })
    .finally(() => {
      location.reload();
    });
};