import React, { Fragment } from "react";
import I18n from "i18n-js";

import IntegrationItem from "./IntegrationItem";

const IntegrationsForm = ({ user, integrations }) => (
  <div className="integrations-form">
    <label>{I18n.t("user.integrations")}</label>
    {integrations.map(team => (
      <IntegrationItem key={team.uuid} user={user} team={team} />
    ))}
  </div>
);

export default IntegrationsForm;
