import React from "react";
import I18n from "i18n-js";

import { getHumanDate } from "../../services/date";

const Note = ({ note, selected, searchTerm }) => {

  const getNoteUrl = () => {
    let url = note.team && note.team.uuid
      ? `/teams/${note.team.uuid}/notebook/${note.uuid}?locale=${I18n.locale}`
      : `/notebook/personal/${note.uuid}?locale=${I18n.locale}`;

    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    return url;
  }

  return (
    <a
      className={selected ? "note selected" : "note"}
      href={getNoteUrl()}
    >
      <div className="title">{note.title}</div>
      <div className="timestamp">{getHumanDate(note.created_at)}</div>
    </a>
  );
}

export default Note;
