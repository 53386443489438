import React, { Component, Fragment } from "react";
import countryList from "react-select-country-list";
import I18n from "i18n-js";

import logo from "../../assets/icons/logo/hey-vicky-white.svg";
import googleIcon from "../../assets/icons/google-icon.svg";
import microsoftIcon from "../../assets/icons/microsoft-icon.svg";

import TermsConditions from "./components/TermsConditions";

import { handleSubmit, showErrorStyles } from "../../commons/formActions";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export default class New extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      city: "",
      state: "",
      country: "",
      errors: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        city: "",
        state: "",
      },
      isModalOpen: false,
    };
  }

  handleInputChange = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    let errors = this.state.errors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : `${I18n.t('onboarding.email_not_valid')}`
        break;
      case "password":
        errors.password =
          value.length < 8
            ? `${I18n.t('onboarding.pass_length')}`
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  showPlaceholder = i18n_key => {
    if (i18n_key == `${I18n.t('onboarding.password')}`)
      return `${I18n.t('onboarding.choose_password')}`
    else if (i18n_key == `${I18n.t('onboarding.email')}`)
      return `${I18n.t('onboarding.email_address')}`
    else
      return `${i18n_key}`
  }

  handleSelectedCountry = (event) => {
    this.setState({ country: event.target.value });
  };

  showFormFields(errors, fields) {
    return Object.entries(fields).map(([key, value], index) => {
      const i18n_keys = [`${I18n.t('onboarding.first_name')}`, `${I18n.t('onboarding.last_name')}`, `${I18n.t('onboarding.email')}`, `${I18n.t('onboarding.password')}`, `${I18n.t('onboarding.city')}`, `${I18n.t('onboarding.state')}`]
      let i18n_key = i18n_keys[index]
      return (
        <div className={`form-group ${key}`}>
          <input
            className={showErrorStyles(errors[key])}
            name={`${key}`}
            type={`${key}`}
            placeholder={this.showPlaceholder(`${i18n_key}`)}
            onChange={this.handleInputChange}
          />
          {errors[key].length > 0 && (
            <span className="error-msg">{errors[key]}</span>
          )}
        </div>
      );
    });
  }

  setErrorMessages = (errors) => {
    this.setState({ errors });
  };

  showRegistrationForm(formFields) {
    const { country, errors, isModalOpen, ...fields } = formFields;
    const listOfCountries = countryList().getData();

    return (
      <form
        onSubmit={(event) =>
          handleSubmit(
            event,
            ["users", "user"],
            this.state,
            this.setErrorMessages
          )
        }
      >
        {this.showFormFields(errors, fields)}

        <div className="form-group country">
          <select
            className="form-control"
            onChange={this.handleSelectedCountry}
          >
            {listOfCountries.map((country) => (
              <option>{`${country.label}`}</option>
            ))}
          </select>
        </div>
        <input
          className="btn btn-primary btn-block submit-btn"
          type="submit"
          value={I18n.t('onboarding.create_account')}
        />
      </form>
    );
  }

  showTerms = () => {
    this.setState({ isModalOpen: true });
  };

  hideTerms = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <Fragment>
        <div className="registration-section">
          <div className="background-section">
            <img className="app-logo" src={logo} />
            <div className="background-content">
              <h1>
                {I18n.t('onboarding.ready_msg')}
              </h1>
            </div>
          </div>
          <div className="form-section">
            <div className="registration-form">
              <div className="registration-content">
                <h2>{I18n.t('onboarding.started')}</h2>
                <div className="registration-content__message">
                  {I18n.t('onboarding.first_instruction')}
                </div>
              </div>
              {this.showRegistrationForm(this.state)}
              <div className="terms-conditions">
                {I18n.t('onboarding.policies_one')}
                <span onClick={this.showTerms}>{I18n.t('onboarding.terms_and_conditions')}</span>
                {I18n.t('onboarding.and')}
                <span onClick={this.showTerms}>{I18n.t('onboarding.privacy_policy')}</span>. {I18n.t('onboarding.policies_two')}
              </div>
              <div className="social-authentication">
                <div className="line" />
                <div className="text">{I18n.t("sign_up.sign_up_with_social")}</div>
                <div className="line" />
              </div>
              <div className="social-buttons">
                <form action="/users/auth/google_signin" method="post">
                  <button type="submit" className="social-btn">
                    <img src={googleIcon} className="logo" alt="Google logo" />
                  </button>
                </form>
                <form action="/users/auth/ms_signin" method="post">
                  <button type="submit" className="social-btn">
                    <img src={microsoftIcon} className="logo" alt="Microsoft logo" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <TermsConditions
          isOpen={this.state.isModalOpen}
          hideTerms={this.hideTerms}
        />
      </Fragment>
    );
  }
}
