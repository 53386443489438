import React, { Component } from "react";
import I18n from "i18n-js";

import TeamStageIcon from "../../assets/images/onboarding/team-stage.svg";
import KpisIcon from "../../assets/images/onboarding/kpi.svg";
import LeadershipIcon from "../../assets/images/onboarding/leadership.svg";
import IntegrationIcon from "../../assets/images/onboarding/integration.svg";

const getSections = () => ({
  team_stage: {
    id: "team-stage",
    icon: TeamStageIcon,
    title: I18n.t("onboarding.team_stage"),
    stepCount: 8
  },
  kpis: {
    id: "kpis",
    icon: KpisIcon,
    title: I18n.t('onboarding.kpis'),
    stepCount: 5
  },
  leadership:  {
    id: "leadership",
    icon: LeadershipIcon,
    title: I18n.t("onboarding.leadership"),
    stepCount: 2
  },
  integrations:  {
    id: "integrations",
    icon: IntegrationIcon,
    title: I18n.t("onboarding.integrations"),
    stepCount: 1
  }
});

export default class Status extends Component {
  changeSectionStyle = (step, section) => {
    switch (section) {
      case "team-stage":
        return this.handleSectionClass(step, 1, 8, 9);
      case "kpis":
        return this.handleSectionClass(step, 9, 13, 14);
      case "leadership":
        return this.handleSectionClass(step, 14, 15, 16);
      case "integrations":
        return this.handleSectionClass(step, 16, 16, 16);
      default:
        return "inactive-section";
    }
  };

  handleSectionClass = (step, activeStart, activeEnd, finishedStep) => {
    if (step >= activeStart && step <= activeEnd) {
      return "active-section";
    } else if (step >= finishedStep) {
      return "finished-section";
    } else {
      return "inactive-section";
    }
  };

  showStatus = (step) => {                                                                                    
    return Object.entries(getSections()).map(([_, value], index) => {
      const sectionStyle = this.changeSectionStyle(step, value.id);

      return (
        <div
          key={index}
          className={`status-item ${sectionStyle}`}
        >
          <div className="status-title">
            <img
              className="icon"
              src={value.icon}
              alt="icon"
            />
            <h6>{value.title}</h6>
          </div>
          <div className="status-count">
            <h6>{value.stepCount}</h6>
          </div>
        </div>
      );
    });
  };

  render() {
    const step = this.props.currentStep;

    return <div className="status-section">{this.showStatus(step)}</div>;
  }
}
