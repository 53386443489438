import axios from "axios";
import I18n from "i18n-js";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use(config => {
  config.params = { ...config.params, locale: I18n.locale || "en" };
  return config;
});

export default api;
