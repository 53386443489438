import React, { useState } from "react";
import Progress from "react-svg-progress";
import toast from "react-hot-toast";
import I18n from "i18n-js";

import api from "../../api";

import notebookIcon from "../../assets/icons/notebook.svg";

const SaveNoteButton = ({ messageId }) => {

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const saveToNotebook = () => {
    setLoading(true);
    setDisabled(true);
    
    api.post("/notes", { chat_message_id: messageId })
      .then(res => {
        console.log(res);
        toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
        setLoading(false);
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(<b>{err.response.data.message}</b>);
        } else {
          toast.error(<b>{I18n.t("common.errors.unexpected_error")}</b>);
        }
        console.error(err);
        setLoading(false);
        setDisabled(false)
      });
  }

  const handleOnClick = (event) => {
    event.preventDefault();
    saveToNotebook();
  }

  return (
    <button
      className={`save-note-button ${loading ? "loading" : ""}`}
      disabled={disabled}
      onClick={handleOnClick}
    >
      <span className="text">{I18n.t("chat.save_to_notebook")}</span>
      {loading ? (
        <Progress color="#265F78" size="15px" />
      ) : (
        <img className="icon" src={notebookIcon} alt="Notebook" />
      )}
    </button>
  );
}

export default SaveNoteButton;
