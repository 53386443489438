import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { useMeasure } from "react-use";
import { useDebounce } from "use-debounce";
import _ from "lodash";
import I18n from "i18n-js";

import api from "../api";

import Loading from "../commons/CardSlider/Loading";

import chevronLeft from "../assets/icons/chat/chevron-left.svg";
import chevronRight from "../assets/icons/chat/chevron-right.svg";

const MAX_SUGESTIONS = 5;
const SUGESTION_MIN_WIDTH_PX = 150;

const Sugestions = ({ loading, onSugestionClick }) => {

  const [ref, { width }] = useMeasure();
  
  const [showCount, setShowCount] = useState(2);
  const [sugestionWidth, setSugestionWidth] = useState(SUGESTION_MIN_WIDTH_PX);

  const [initialLoading, setInitialLoading] = useState(true);
  const [initialLoadingDebounced] = useDebounce(initialLoading, 500);

  const [sugestions, setSugestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // (width - margins)
    const normalizedWidth = (width - 48);

    const count = Math.max(0, Math.floor(normalizedWidth / SUGESTION_MIN_WIDTH_PX));
    setShowCount(count);

    const sugestionWidth = Math.max(SUGESTION_MIN_WIDTH_PX, normalizedWidth / sugestions.length);
    setSugestionWidth(sugestionWidth);
  }, [width, sugestions]);

  useEffect(() => {
    async function fetchSugestions() {
      setInitialLoading(true);
      const sugestions = await getSugestionsFromApi();
      setSugestions(_.take(sugestions, MAX_SUGESTIONS));
      setInitialLoading(false);
    }

    fetchSugestions();
  }, []);

  const getSugestionsFromApi = async () => {
    const res = await api.get(`/chat/sugestions?locale=${I18n.locale}`);
    console.log(res);

    if (res.data && res.data.status === "success") {
      return res.data.data;
    }
  }

  const handleSugestionClick = (event, sugestion) => {
    if (loading) return;

    event.preventDefault();
    onSugestionClick && onSugestionClick(sugestion);
  }

  const redirectToGeniusWriter = (event) => {
    event.preventDefault();
    window.location.assign(`/writer?locale=${I18n.locale}`);
  }

  const getCards = () => {
    const cards = sugestions.map(sugestion => ({
      command: sugestion.command,
      sugestion: sugestion,
      onClick: handleSugestionClick
    }));

    cards.push({
      command: I18n.t("chat.sugestions.genius_writer.command"),
      sugestion: null,
      onClick: redirectToGeniusWriter
    })

    return cards;
  }

  const cards = getCards();

  const handleNext = () => {
    const index = currentIndex + 1;
    if (hasNext()) {
      setCurrentIndex(index);
    }
  };

  const handlePrev = () => {
    const index = currentIndex - 1;
    if (hasPrevious()) {
      setCurrentIndex(index);
    }
  };

  const hasPrevious = () => {
    const index = currentIndex - 1;
    return index >= 0;
  }

  const hasNext = () => {
    const index = currentIndex + 1;
    return (index + showCount) <= cards.length;
  }

  const showControls = () => showCount < cards.length;

  return (
    <div className="sugestions-section" ref={ref}>
      {initialLoadingDebounced ? (
        <Loading count={showCount} />
      ) : (
        <div className="sugestions">
          {cards.slice(currentIndex, currentIndex + showCount).map((card, index) => (
            <div
              key={index}
              className={`sugestion ${loading ? "disabled" : ""} ${sugestionWidth > 171 ? "wide" : ""}`}
              onClick={event => card.onClick(event, card.sugestion)}
            >
              <span>
                {card.command}
              </span>
            </div>
          ))}
          {showControls() ? (
            <div className="controls">
              <button
                className="control left"
                disabled={!hasPrevious()}
                onClick={handlePrev}
              >
                <ReactSVG className="icon" src={chevronLeft} />
              </button>
              <button
                className="control right"
                disabled={!hasNext()}
                onClick={handleNext}
              >
                <ReactSVG className="icon" src={chevronRight} />
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default Sugestions;
