import React from "react";
import I18n from "i18n-js";

import Card from "./Card";

const TeamStage = ({ forming, storming, norming, performing }) => {

  const stages = { forming, storming, norming, performing };

  const calculateWidth = (score) => {
    return (score * 194).toFixed(0) + "px";
  }

  const renderStages = () => {
    return Object.entries(stages).map(([key, value], index) => (
      <div key={index} className="stage">
        <div className="stage-content">
          <div className="stage-title">
            {I18n.t(`dashboard.${key}`)}
          </div>
          <div className="stage-score">
            {Math.floor(value * 100)}%
          </div>
        </div>
        <div className="stage-progress">
          <div
            className={`${key}-progress`}
            style={{ width: calculateWidth(value) }}
          />
        </div>
      </div>
    ));
  }
    
  return (
    <div className="team-stage-section">
      <Card
        title={I18n.t('dashboard.team_stage')}
        content={
          <div className="section-content">
            {renderStages()}
          </div>
        }
      />
    </div>
  );
}

export default TeamStage;
