import React from "react";

import HealthSection from "./HealthSection";
import TeamMembersHealth from "./TeamMembersHealth";

import teamHealthIcon from "../../assets/icons/team-health.svg";

import collaborationIcon from "../../assets/images/onboarding/collaboration.svg";
import communicationIcon from "../../assets/images/onboarding/communication.svg";
import engagementIcon from "../../assets/images/onboarding/engagement.svg";
import adaptabilityIcon from "../../assets/images/onboarding/adaptability.svg";

const getKPIs = (collaboration, communication, engagement, adaptability) => [
  {
    title: I18n.t("dashboard.adaptability"),
    score: adaptability,
    icon: adaptabilityIcon
  },
  {
    title: I18n.t("dashboard.collaboration"),
    score: collaboration,
    icon: collaborationIcon
  },
  {
    title: I18n.t("dashboard.communication"),
    score: communication,
    icon: communicationIcon
  },
  {
    title: I18n.t("dashboard.engagement"),
    score: engagement,
    icon: engagementIcon
  }
];

const TeamHealth = (props) => {
  const {
    collaboration,
    communication,
    engagement,
    adaptability,
    showMemberDetails,
    teamMembersHealths,
    ofuscate
  } = props;

  return (
    <div className="team-health-section">
      <HealthSection
        icon={teamHealthIcon}
        title={I18n.t("dashboard.team_health")}
        kpis={getKPIs(collaboration, communication, engagement, adaptability)}
        ofuscate={ofuscate}
      />
      {showMemberDetails ? (
        <TeamMembersHealth healths={teamMembersHealths} />
      ) : null}
    </div>
  );
}

export default TeamHealth;
