import React from "react";

import LoadingIndicator from "../../Chat/components/Loading";

const Loading = ({ count }) => {
  return (
    <div className="sugestions">
      {Array.from(Array(count)).map((_, index) => (
        <div key={index} className="sugestion loading">
          <LoadingIndicator />
        </div>
      ))}
    </div>
  )
}

export default Loading;
