import React, { Fragment } from "react";

import Card from "./Card";
import GaugeGraph from "./GaugeGraph";

const HealthSection = ({ icon, title, kpis, updateLink, ofuscate }) => (
  <div className="health-section">
    <Card
      icon={icon}
      title={title}
      updateLink={updateLink}
      ofuscate={ofuscate}
      content={
        <div className="scores">
          {kpis.map((kpi, index) => (
            <GaugeGraph key={index} {...kpi} ofuscate={ofuscate} />
          ))}
        </div>
      }
    />
  </div>
);

export default HealthSection;
