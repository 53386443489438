import { create } from "zustand";
import { devtools } from "zustand/middleware";

import routingSlice from "./slices/routing";
import onboardingSlice from "./slices/onboarding";
import teamStageSlice from "./slices/team-stage";

const useStore = create()(
  devtools(
    (set, get) => ({
      routing: routingSlice(set, get),
      onboarding: onboardingSlice(set, get),
      teamStage: teamStageSlice(set, get)
    })
  )
);

if (global.window && global.window.Cypress) {
  const initialState = global.window.initialState;
  if (initialState) {
    useStore.setState(initialState);
  }
  window.store = useStore;
}

export default useStore;