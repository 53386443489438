import React, { useMemo } from "react";
import _ from "lodash";

const RadioButton = ({ id, label, value, checked, onChange }) => {
  const finalId = useMemo(() => id || _.uniqueId(), [id]);

  const handleOnChange = (event) => {
    const value = event.target.value;
    onChange && onChange(value);
  }

  return (
    <label className="radio-button">
      <input
        type="radio"
        id={finalId}
        value={value}
        checked={checked}
        onChange={handleOnChange}
      />
      <span>{label}</span>
    </label>
  );
}

export default RadioButton;
