import _ from "lodash";

import { RolesThatCannotBeChanged } from "../config/roles";

export const Permissions = {
  canChangeMemberRole: (role) => {
    return _.includes(["team_lead", "human_resources", "program_advisor", "company_admin"], role);
  },
  
  canChangeTeamLeadRole: (role) => {
    return _.includes(["human_resources", "program_advisor", "company_admin"], role);
  },
  
  canChangeHumanResourcesRole: (role) => {
    return _.includes(["program_advisor", "company_admin"], role);
  },
  
  canChangeProgramAdvisorRole: (role) => {
    return role === "company_admin"
  },
  
  canChangeCompanyAdminRole: (role) => {
    return role === "company_admin"
  },

  canChangeMemberStatus: (role) => {
    return _.includes(["team_lead", "human_resources", "program_advisor", "company_admin"], role);
  },
  
  canChangeTeamLeadStatus: (role) => {
    return _.includes(["human_resources", "program_advisor", "company_admin"], role);
  },
  
  canChangeHumanResourcesStatus: (role) => {
    return _.includes(["program_advisor", "company_admin"], role);
  },
  
  canChangeProgramAdvisorStatus: (role) => {
    return role === "company_admin"
  },
  
  canChangeCompanyAdminStatus: (role) => {
    return role === "company_admin"
  },
}

export const AddMemberPermission = {
  disabledRolesForUser: (loggedUser) => {
    const { role_id: loggedUserRole } = loggedUser;
  
    switch (loggedUserRole) {
      case "company_admin":
        return [];
      case "program_advisor":
        return ["company_admin", "program_advisor"];
      case "human_resources":
        return ["company_admin", "program_advisor", "human_resources", "team_lead"];
      case "team_lead":
        return ["company_admin", "program_advisor", "team_lead"];
      default:
        return ["company_admin", "program_advisor", "human_resources", "team_lead", "user"];
    }
  }
};

export const ChangeMemberRolePermission = {
  canChangeUserRole: (loggedUser, user) => {
    const { role_id: loggedUserRole } = loggedUser;
    const { role_id: userRole } = user;
  
    // Team members cannot change other user roles
    if (loggedUserRole === "user") return false;
  
    // Users with full access roles cannot have their roles changed.
    // You must first change another user to some of these roles.
    if (_.includes(RolesThatCannotBeChanged, userRole)) return false;
  
    switch (userRole) {
      case "user":
        return Permissions.canChangeMemberRole(loggedUserRole);
      case "team_lead":
        return Permissions.canChangeTeamLeadRole(loggedUserRole);
      case "human_resources":
        return Permissions.canChangeHumanResourcesRole(loggedUserRole);
      case "program_advisor":
        return Permissions.canChangeProgramAdvisorRole(loggedUserRole);
      case "company_admin":
        return Permissions.canChangeCompanyAdminRole(loggedUserRole);
      default:
        return false;
    }
  },

  disabledRolesForUser: (loggedUser) => {
    const { role_id: loggedUserRole } = loggedUser;
  
    switch (loggedUserRole) {
      case "company_admin":
        return [];
      case "program_advisor":
        return ["company_admin", "program_advisor"];
      case "human_resources":
        return ["company_admin", "program_advisor", "human_resources"];
      case "team_lead":
        return ["company_admin", "program_advisor", "human_resources", "team_lead"];
      default:
        return ["company_admin", "program_advisor", "human_resources", "team_lead", "user"];
    }
  }
};

export const ChangeMemberStatusPermission = {
  canChangeUserStatus: (loggedUser, user) => {
    const { role_id: loggedUserRole } = loggedUser;
    const { role_id: userRole } = user;
  
    // Team members cannot change other user status
    if (loggedUserRole === "user") return false;
  
    // Users with full access roles cannot have their status changed.
    // You must first change another user to some of these roles.
    if (_.includes(RolesThatCannotBeChanged, userRole)) return false;
  
    switch (userRole) {
      case "user":
        return Permissions.canChangeMemberStatus(loggedUserRole);
      case "team_lead":
        return Permissions.canChangeTeamLeadStatus(loggedUserRole);
      case "human_resources":
        return Permissions.canChangeHumanResourcesStatus(loggedUserRole);
      case "program_advisor":
        return Permissions.canChangeProgramAdvisorStatus(loggedUserRole);
      case "company_admin":
        return Permissions.canChangeCompanyAdminStatus(loggedUserRole);
      default:
        return false;
    }
  },
};