import React, { Fragment, useEffect, useRef } from "react";

const Shine = ({ trigger, onAnimationEnd }) => {

  const backgroundRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const onAnimationEnd = (event) => {
      if (event?.animationName === "shine") {
        onAnimationEnd && onAnimationEnd();
      }

      if (event?.animationName === "shine-ending" && backgroundRef.current && containerRef.current) {
        backgroundRef.current.style.display = "none";
        containerRef.current.style.display = "none";
      }
    };

    containerRef.current?.addEventListener("animationend", onAnimationEnd);
    return () => containerRef.current?.removeEventListener("animationend", onAnimationEnd);
  }, []);

  useEffect(() => {
    if (backgroundRef.current && containerRef.current) {
      backgroundRef.current.style.display = "block";
      containerRef.current.style.display = "block";
    }
  }, [trigger]);

  return (
    <Fragment>
      <div ref={backgroundRef} className="shine-background" />
      <div ref={containerRef} className="shine-container" />
    </Fragment>
  );
}

export default Shine;
