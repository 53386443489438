import React from "react";
import { ReactSVG } from "react-svg";

import completedIcon from "../../../assets/icons/pdp/completed.svg";

const Task = ({ task, selected, onSelect }) => {
  
  const handleSelect = (event) => {
    event.preventDefault();
    onSelect && onSelect(task);
  }

  return (
    <div
      className={`task ${selected ? "selected" : ""}`}
      title={task.title}
      onClick={handleSelect}
    >
      {task.status === "completed" ? (
        <ReactSVG className="icon" src={completedIcon} />
      ) : null}
      <span className="title">{task.title}</span>
    </div>
  );
}

export default Task;
