import React, { Fragment, useState } from "react";
import I18n from "i18n-js";

import useStore from "../store";

import ResetStateConfirmation from "./ResetStateConfirmation";

import resetIcon from "../../assets/icons/delete-forever.svg";

const ResetStateButton = () => {
  const { reset } = useStore();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const onClick = () => {
    setShowConfirmation(true);
  }

  const onConfirmationConfirm = () => {
    reset();
    window.location.reload();
  }

  const onConfirmationCancel = () => {
    setShowConfirmation(false);
  }
  
  return (
    <Fragment>
      <button className="reset-state-btn" onClick={onClick}>
        <span className="text">
          {I18n.t("writer.actions.reset")}
        </span>
        <img
          className="icon"
          src={resetIcon}
          alt={I18n.t("writer.actions.reset")}
        />
      </button>
      <ResetStateConfirmation
        show={showConfirmation}
        onConfirm={onConfirmationConfirm}
        onCancel={onConfirmationCancel}
      />
    </Fragment>
  );
}

export default ResetStateButton;
