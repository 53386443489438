import React, { Component } from "react";
import { Modal } from "react-bootstrap";

export default class InternalModal extends Component {
  render() {
    const { type, size, isModalOpen, hideModal, showModalBody } = this.props;

    return (
      <Modal
        className={`${type}-modal`}
        size={size || "lg"}
        show={isModalOpen}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
        contentClassName={`${type}-content`}
      >
        {showModalBody === undefined || showModalBody ? (
          <Modal.Body className={`${type}-body`}>
            {this.props.component}
          </Modal.Body>
        ) : (
          this.props.component
        )}
      </Modal>
    );
  }
}
