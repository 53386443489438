import React, { useEffect, useRef } from "react";

const ScrollView = (props) => {
  const { children, className, messages, error, loading, ...rest } = props;

  const scrollRef = useRef(null);

  const scrollToBottom = () => {
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight, { behavior: "smooth" });
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages, error, loading]);

  return (
    <div
      ref={scrollRef}
      className={`scroll-view ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ScrollView;