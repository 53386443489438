import React from "react";

import needWorkIcon from "../../assets/images/dashboard/needs-work.svg";
import fairIcon from "../../assets/images/dashboard/fair.svg";
import goodIcon from "../../assets/images/dashboard/good.svg";
import excellentIcon from "../../assets/images/dashboard/excellent.svg";

import Card from "./Card";
import GaugeGraph from "./GaugeGraph";

const OverallHealth = ({ score, ofuscate }) => {

  const getIcon = () => {
    if (ofuscate) return needWorkIcon;

    if (score < 0.41) {
      return needWorkIcon;
    } else if (score >= 0.41 && score < 0.61) {
      return fairIcon;
    } else if (score >= 0.61 && score < 0.75) {
      return goodIcon;
    } else if (score >= 0.75) {
      return excellentIcon;
    }
  }

  return (
    <div className="overall-health">
      <Card
        title={I18n.t("dashboard.overall_health")}
        ofuscate={ofuscate}
        content={
          <GaugeGraph
            title={I18n.t("dashboard.overall_health")}
            icon={getIcon()}
            score={score}
            ofuscate={ofuscate}
            ofuscateTitle={ofuscate}
          />
        }
      />
    </div>
  );
}

export default OverallHealth;
