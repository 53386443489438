import React from "react";
import I18n from "i18n-js";

import HealthSection from "./HealthSection";

import leadershipHealthIcon from "../../assets/icons/leadership-health.svg";

import trustEnvironmentIcon from "../../assets/images/trust-environment.svg";
import managementPracticeIcon from "../../assets/images/management-practice.svg";

const getKPIs = (trustEnvironment, managementPractice) => [
  {
    title: I18n.t("dashboard.trust_environment"),
    score: trustEnvironment,
    icon: trustEnvironmentIcon
  },
  {
    title: I18n.t("dashboard.management_practice"),
    score: managementPractice,
    icon: managementPracticeIcon
  }
];

const LeadershipHealth = ({ trustEnvironment, managementPractice, ofuscate, expand }) => {
  const classNames = expand ? "leadership-health-section expand" : "leadership-health-section";
  return (
    <div className={classNames}>
      <HealthSection
        icon={leadershipHealthIcon}
        title={I18n.t("dashboard.leadership_health")}
        kpis={getKPIs(trustEnvironment, managementPractice)}
        ofuscate={ofuscate}
      />
    </div>
  );
}

export default LeadershipHealth;
