import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { useSlate } from "slate-react";

import EditorService from "../../services/EditorService";
import useScrollBlock from "../../hooks/useScrollBlock";

import Button from "./Button";
import Icon from "./Icon";

import chevron from "../../assets/icons/editor/chevron-down.svg";

const Alignment = {
  left: "align-left",
  center: "align-center",
  right: "align-right",
  justify: "align-justify",
}

const ALIGNMENT_OPTIONS_WIDTH_PX = 128;

const AlignmentButton = () => {
  const editor = useSlate();
  const [blockScroll, allowScroll] = useScrollBlock();

  const initialAlignment = "left";

  const [alignment, setAlignment] = useState(initialAlignment);
  const [showOptions, setShowOptions] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState({});

  const ref = useRef();
  const optionsRef = useRef();
  
  const left = EditorService.isBlockActive(editor, "left", "align");
  const center = EditorService.isBlockActive(editor, "center", "align");
  const right = EditorService.isBlockActive(editor, "right", "align");
  const justify = EditorService.isBlockActive(editor, "justify", "align");
  
  useEffect(() => {  
    const align = left
      ? "left"
      : center
        ? "center"
        : right
          ? "right"
          : justify
            ? "justify"
            : initialAlignment;

    setAlignment(align);
  }, [left, center, right, justify]);

  useEffect(() => {
    showOptions && blockScroll();
    return () => allowScroll();
  }, [showOptions]);

  useEffect(() => {
    const handler = (event) => {
      if (showOptions &&
          !ref.current.contains(event.target) &&
          !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    }
  }, [showOptions]);

  const handleClick = (event) => {
    event.preventDefault();

    const rect = ref.current.getBoundingClientRect();
    setOptionsPosition({
      top: rect.y + 32,
      left: rect.x - (ALIGNMENT_OPTIONS_WIDTH_PX / 2 - rect.width / 2),
    });

    setShowOptions(show => !show);
  }

  const handleOptionClick = (event, option) => {
    event.preventDefault();
    EditorService.toggleBlock(editor, option, "align");
    setShowOptions(false);
  }

  return (
    <div ref={ref} className="alignment-button">
      <Button active={showOptions} onClick={handleClick}>
        <Icon icon={Alignment[alignment]} />
        <img className="chevron" src={chevron} alt="open options" />
      </Button>
      {showOptions && createPortal(
        <div
          ref={optionsRef}
          className="alignment-options"
          style={{ ...optionsPosition }}
        >
          {Object.entries(Alignment).map(([option, icon]) => (
            <Button
              key={option}
              active={alignment === option}
              onClick={event => handleOptionClick(event, option)}
            >
              <Icon icon={icon} />
            </Button>
          ))}
        </div>,
        document.body
      )}
    </div>
  )
}

export default AlignmentButton;
