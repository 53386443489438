import React from "react";
import I18n from "i18n-js";

const ClearSelectionsButton = ({ onClick }) => (
  <button
    className="clear-selections-btn"
    onClick={onClick}
  >
    {I18n.t("writer.actions.clear_selections")}
  </button>
);

export default ClearSelectionsButton;
