import React from "react";
import I18n from "i18n-js";

import cancelIcon from "../../assets/icons/edit-cancel.svg";

const CancelButton = () => (
  <a className="cancel-btn" href={`/notebook?locale=${I18n.locale}`}>
    <span className="text">
      {I18n.t("notebook.actions.cancel")}
    </span>
    <img
      className="icon"
      src={cancelIcon}
      alt={I18n.t("notebook.actions.cancel")}
    />
  </a>
);

export default CancelButton;
