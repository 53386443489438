import React from "react";
import I18n from "i18n-js";

import GoodIcon from "../assets/images/dashboard/good.svg";

const AllSet = ({ team }) => {

  const redirectToHomePage = () => {
    window.location = `/?team_uuid=${team.uuid}`;
  }

  return (
    <div className="allset-section">
      <img
        className="icon"
        src={GoodIcon}
        alt="all set icon"
      />
      <h2 className="title">{I18n.t("common.all_set")}</h2>
      <button
        className="dashboard-btn"
        onClick={() => redirectToHomePage()}
      >
        {I18n.t("common.go_dashboard")}
      </button>
    </div>
  );
}

export default AllSet;
