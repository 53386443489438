import React from "react";
import _ from "lodash";

const FormItem = ({ label, value, placeholder, editMode, editable, onChange }) => {
  const id = _.uniqueId();

  const handleOnChange = (event) => {
    const value = event.target.value;
    onChange && onChange(value);
  }

  return (
    <div className="form-item">
      <label htmlFor={id}>{label}</label>
      {editable ? (
        <input
          type="text"
          id={id}
          name={label}
          placeholder={placeholder || label}
          value={value}
          disabled={!Boolean(editMode)}
          onChange={handleOnChange}
        />
      ) : (
        <span id={id}>{value}</span>
      )}
    </div>
  );
}

export default FormItem;
