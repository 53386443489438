import React from "react";

const Blur = () => (
  <svg id="svg-filter" className="blur-filter">
    <filter id="svg-blur">
      <feGaussianBlur in="SourceGraphic" stdDeviation="5"></feGaussianBlur>
    </filter>
  </svg>
);

export default Blur;