import React, { memo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { Prism as SyntaxHighligther } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";

const MessageRender = memo((props) => {
  return (
    <ReactMarkdown
      className="z-ui-markdown"
      children={props.children}
      remarkPlugins={[remarkGfm, remarkBreaks]}
      components={{
        code({ node, inline, className, children, ...rest}) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighligther
              {...rest}
              children={children}
              style={oneLight}
              language={match[1]}
              PreTag="div"
            />
          ) : (
            <code {...props} className="code-line">
              {children}
            </code>
          )
        }
      }}
    />
  );
});

export default MessageRender;
