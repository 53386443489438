import api from "../api";

export function handleSubmit(event, endpoints, props, setErrorMessages) {
  event.preventDefault();

  api.post(`/${endpoints[0]}`, { [endpoints[1]]: props }).then((response) => {
    let errors = props.errors;

    const { messages, redirectTo, status } = response.data;

    if (status === 422) {
      Object.entries(messages).map(([key, value]) => (errors[key] = value));
      setErrorMessages(errors);
    } else {
      window.location.assign(redirectTo);
    }
  });
}

export function showErrorStyles(error) {
  if (error === "") {
    return "form-control";
  } else {
    return "form-control error-field";
  }
}
