import React from "react";
import I18n from "i18n-js";

import addIcon from "../../assets/icons/add.svg";

const NewTeamButton = () => (
  <a className="new-team-button" href={`/plans?locale=${I18n.locale}`}>
    <span className="text">
      {I18n.t("team.add_new_team")}
    </span>
    <img
      className="icon"
      src={addIcon}
      alt={I18n.t("team.add_new_team")}
    />
  </a>
);

export default NewTeamButton;
