const teamStageState = (set, get) => ({
  step: 0,
  questions: [],
  answers: [],

  nextStep: () => set(state => ({ teamStage: {
    ...state.teamStage,
    step: state.teamStage.step + 1
  }})),
  previousStep: () => set(state => ({ teamStage: {
    ...state.teamStage,
    step: state.teamStage.step - 1
  }})),

  setQuestions: (questions) => set(state => ({ teamStage: {
    ...state.teamStage,
    questions,
    answers: initAnswers(questions),
  }})),
  currentQuestion: () => {
    const state = get().teamStage;
    return state.questions.find(q => q.index === state.step);
  },

  setAnswer: (questionIndex, answer) => set(state => ({ teamStage: {
    ...state.teamStage,
    answers: state.teamStage.answers
      .map((a, index) => questionIndex === index ? { ...a, ...answer } : a)
  }})),
  currentAnswer: () => {
    const state = get().teamStage;
    return state.answers.find(a => a.index === state.step);
  },
});

const initAnswers = (questions) =>
  questions.map(q => ({
    index: q.index,
    type: q.type,
    name: q.name
  }));

export default teamStageState;