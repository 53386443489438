import React from "react";
import I18n from "i18n-js";

import optimizeIcon from "../../assets/icons/optimize.svg";

const OptimizeButton = ({ loading, onClick }) => (
  <button className="optimize-btn" disabled={loading} onClick={onClick}>
    <span className="text">
      { loading
          ? I18n.t("writer.actions.working")
          : I18n.t("writer.actions.optimize")
      }
    </span>
    <img
      className="icon"
      src={optimizeIcon}
      alt={I18n.t("writer.actions.optimize")}
    />
  </button>
);

export default OptimizeButton;
