import React, { forwardRef, useEffect, useState } from "react";
import Colorful from "@uiw/react-color-colorful";
import { useDebouncedCallback } from "use-debounce";

import useScrollBlock from "../../hooks/useScrollBlock";

const ColorPicker = forwardRef(({ initialColor, position, onChange }, ref) => {

  const [blockScroll, allowScroll] = useScrollBlock();
  
  const [color, setColor] = useState(initialColor);
  
  const onChangeDebounced = useDebouncedCallback(() => {
    onChange && onChange(color);
  }, 200);

  useEffect(() => {
    blockScroll();
    return ()=> allowScroll();
  }, []);

  useEffect(() => {
    onChangeDebounced();
  }, [color]);

  return (
    <div ref={ref} className="color-picker" style={{ ...position }}>
      <Colorful
        color={color}
        disableAlpha
        onChange={(color) => setColor(color.hex)}
      />
    </div>
  );
});

export default ColorPicker;
