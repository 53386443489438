import React, { useEffect, useState } from "react";
import _ from "lodash";

import Chip from "./Chip";

const ChipGroup = ({
  className,
  id,
  label,
  options,
  value,
  error,
  disabled,
  slim,
  multiSelect,
  onValueChange
}) => {
  const finalId = id || _.uniqueId();
  const finalValue = value?.length
    ? value.map(v => options.find(option => option.value === v))
    : [];

  const changeValue = (newValue) => {
    //setValue(newValue);

    const values = newValue.map(v => v.value);
    onValueChange && onValueChange(values);
  }

  const isSelected = (option) => {
    return finalValue.find(v => v?.value === option.value);
  }

  const handleChipClick = (option) => {
    if (disabled) return;

    let newValue = [option];

    if (multiSelect) {
      if (isSelected(option)) {
        newValue = finalValue.filter(v => v?.value !== option.value);
      } else {
        newValue = [...finalValue, option];
      }
    }

    changeValue(newValue);
  }

  const renderChip = (index, option, selected) => (
    <Chip
      key={index}
      label={option.label}
      value={option.value}
      selected={selected}
      disabled={disabled}
      onClick={handleChipClick}
    />
  );

  return (
    <div className={`${className} chip-group`}>
      {label ? <label htmlFor={finalId}>{label}</label> : null}
      <div
        id={finalId}
        className="options"
      >
        {options.map((option, index) => {
          const selected = isSelected(option);
          return renderChip(index, option, selected);
        })}
      </div>
      {!slim ? (
        <div className="error">
          {error}
        </div>
      ) : null}
    </div>
  );
};

export default ChipGroup;
