import React, { Fragment, useEffect, useRef } from "react";
import { Slider as RCSlider, Handles, Rail } from "react-compound-slider";

const Slider = ({ name, value, minValue, maxValue, onChange }) => {
  const finalMinValue = minValue || 0;
  const finalMaxValue = maxValue || 10;

  const minValueThumbRef = useRef(null);
  const maxValueThumbRef = useRef(null);

  useEffect(() => {
    if (minValueThumbRef.current && maxValueThumbRef.current) {
      if (value === `${finalMinValue}`) {
        minValueThumbRef.current.style = "visibility: hidden;";
      } else {
        minValueThumbRef.current.style = "visibility: visible;";
      }

      if (value === `${finalMaxValue}`) {
        maxValueThumbRef.current.style = "visibility: hidden;";
      } else {
        maxValueThumbRef.current.style = "visibility: visible;";
      }
    }
  }, [value]);

  const handleOnChange = (values) => {
    onChange && onChange(name, values[0]);
  };

  return (
    <RCSlider
      className="slider"
      mode={1}
      step={1}
      domain={[finalMinValue, finalMaxValue]}
      values={[value]}
      onChange={handleOnChange}
    >
      <Rail>
        {({ getRailProps }) => (
          <Fragment>
            <div className="rail-wrapper" {...getRailProps()} />
            <div className="rail" />
            <span className="min-value-thumb">{finalMinValue}</span>
            <span className="max-value-thumb">{finalMaxValue}</span>
          </Fragment>
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle, index) => (
              <Fragment key={index}>
                <div
                  className="handler-wrapper"
                  style={{ left: `${handle.percent}%` }}
                  {...getHandleProps(handle.id)}
                />
                <div
                  className="handler"
                  role="slider"
                  aria-valuemin={finalMinValue}
                  aria-valuemax={finalMaxValue}
                  aria-valuenow={value}
                  style={{ left: `${handle.percent}%` }}
                >
                  {handle.value}
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </Handles>
    </RCSlider>
  );
}

export default Slider;
