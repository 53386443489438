import React from "react";
import { ReactSVG } from "react-svg";
import I18n from "i18n-js";

import teamGreenIcon from "../assets/images/team-green.svg";
import teamTealIcon from "../assets/images/team-teal.svg";
import teamDkBlueIcon from "../assets/images/team-dk-blue.svg";
import teamBlueIcon from "../assets/images/team-blue.svg";
import teamYellowIcon from "../assets/images/team-yellow.svg";
import teamGreyIcon from "../assets/images/team-grey.svg";

const MemberCount = ({ memberCount, color }) => {

  const count = memberCount > 99 ? "99+" : memberCount;

  const getColorIcon = (color = "dkblue") => {
    let colorMap = {
      green: teamGreenIcon,
      teal: teamTealIcon,
      dkblue: teamDkBlueIcon,
      blue: teamBlueIcon,
      yellow: teamYellowIcon,
      grey: teamGreyIcon,
    };
    return colorMap[color];
  }

  return (
    <div className="member-counter">
      <ReactSVG className="icon" src={getColorIcon(color)} />
      <div>
        {count} {memberCount == 1 ? I18n.t("user.member") : I18n.t("user.members")}
      </div>
    </div>
  );
}

export default MemberCount;
